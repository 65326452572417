<template>
  <HeaderTemplate
    :backgroundImage="null"
    :textColor="'blue-grey darken-4'"
    :logo="require('@/assets/images/logo-dark.png')"
    :hasText="false"
    :navColor="'black'"
  />
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
export default {
  name: "NewsHeaderComponent",
  components: {
    HeaderTemplate,
  },
  data: () => ({}),
};
</script>