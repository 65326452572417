import state from './calculatorState';
import getters from './calculatorGetters';
import mutations from './calculatorMutations';
import actions from './calculatorActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}