<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <v-col cols="12">
          <span class="text-h3 font-weight-regular">{{
            selectedLanguage.id == 1
              ? "Последние добавленные объекты"
              : "Recently Added Properties"
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            text
            class="text-body-1 font-weight-light text-capitalize text-decoration-underline"
            to="/real-estate"
            >{{
              selectedLanguage.id == 1
                ? "Показать больше проектов"
                : "Find More Projects"
            }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-slide-group show-arrows>
            <v-slide-item v-for="item in recentItems.data" :key="item.id">
              <v-card class="mx-2" elevation="0" style="max-width: 300px">
                <AdItemTemplate
                  :logo="item.imageLink"
                  :name="item.name"
                  :location="item.region.name"
                  :price="item.price"
                  :beds="item.bedroomsQuantity"
                  :baths="item.bathroomsQuantity"
                  :to="`/real-estate/${item.id}`"
                  :editShow="token != null"
                  :id="item.id"
                  :codeName="item.code"
                />
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import AdItemTemplate from "../Templates/AdItemTemplate.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HomeRecentItemsComponent",
  components: {
    AdItemTemplate,
  },
  computed: {
    ...mapGetters({
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getRecentItems: "realEstate/GET_RECENT_ITEMS",
      getToken: "auth/GET_TOKEN",
    }),
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    recentItems: {
      get: function () {
        return this.getRecentItems;
      },
    },
    token: {
      get: function () {
        return this.getToken;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getRecentItemsFromApi: "realEstate/GET_RECENT_ITEMS_FROM_API",
    }),
  },
  async created() {
    await this.getRecentItemsFromApi();
  },
};
</script>