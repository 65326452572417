import state from './requestStateState';
import getters from './requestStateGetters';
import mutations from './requestStateMutations';
import actions from './requestStateActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};