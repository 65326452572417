<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="4"
        v-for="(apartItem, index) in apartmentsList"
        :key="index"
      >
        <v-card elevation="0" outlined class="pa-6 rounded-xl">
          <!-- Images -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Images:*</span>
              </v-card>
              <v-card
                elevation="0"
                :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
              >
                <v-row
                  v-if="apartItem.images != null && apartItem.images.length > 0"
                >
                  <v-col cols="12">
                    <v-carousel
                      hide-delimiter-background
                      v-model="apartItem.selectedImage"
                    >
                      <v-carousel-item
                        v-for="(item, i) in apartItem.images"
                        :key="i"
                        :value="i"
                        :src="item.path"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                        class="rounded-lg"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    v-for="(item, i) in apartItem.images"
                    :key="i"
                    @click="selectImage(apartItem, i)"
                    style="cursor: pointer"
                  >
                    <v-img
                      width="128"
                      height="128"
                      :src="item.path"
                      class="rounded-lg text-right"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        x-small
                        color="white ma-2"
                        @click="deleteImage(apartItem, item.id)"
                      >
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-card
                      elevation="0"
                      class="rounded-lg blue white--text d-flex flex-column justify-center align-center pa-6"
                      width="128"
                      height="128"
                    >
                      <v-btn
                        x-small
                        fab
                        elevation="0"
                        color="blue lighten-2"
                        @click="
                          $refs['fileInput_' + index][0]['$refs'][
                            'input'
                          ].click()
                        "
                      >
                        <v-icon color="white">mdi-plus</v-icon>
                      </v-btn>
                      <v-file-input
                        :ref="'fileInput' + '_' + index"
                        v-model="apartItem.insertImages"
                        class="rounded-xl"
                        dense
                        accept="image/png, image/jpeg"
                        small-chips
                        hide-details
                        filled
                        multiple
                        v-show="false"
                        @change="addImages(apartItem)"
                      >
                      </v-file-input>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!-- Images end -->

          <!-- Name -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Name:*</span>
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                v-model="apartItem.name"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Name end -->

          <!-- Description -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Description:*</span>
              </v-card>
              <v-textarea
                dense
                filled
                class="rounded-xl"
                hide-details
                v-model="apartItem.description"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <!-- Description end -->

          <!-- Bathrooms -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Bathrooms:*</span>
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.baths"
                :min="0"
                @wheel.stop.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Bathrooms end -->

          <!-- Beds -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Beds:*</span>
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.beds"
                :min="0"
                @wheel.stop.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Beds end -->

          <!-- Price -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Price:*</span>
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.price"
                :min="0"
                @wheel.stop.prevent
              >
                <template slot="append">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        elevation="0"
                        class="blue white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          >{{
                            currencyTypes.find(
                              (x) => x.id == apartItem.selectedCurrencyType
                            ).icon
                          }}
                        </v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item-group
                        v-model="apartItem.selectedCurrencyType"
                      >
                        <v-list-item
                          v-for="(currencyType, index) in currencyTypes"
                          :key="index"
                          :value="currencyType.id"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ currencyType.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{
                            currencyType.code
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Price end -->

          <!-- Internal Covered Area -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4"
                  >Internal Covered Area:*</span
                >
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.internalCoveredArea"
                append-icon="mdi-social-distance-2-meters"
                :min="0"
                @wheel.stop.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Internal Covered Area end -->

          <!-- Covered Verand Area -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">
                  Covered Verand Area:*</span
                >
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.coveredVerandArea"
                append-icon="mdi-social-distance-2-meters"
                :min="0"
                @wheel.stop.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!--  Covered Verand Area end -->

          <!-- Uncovered Verand Area -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">
                  Uncovered Verand Area:*</span
                >
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.uncoveredVerandArea"
                append-icon="mdi-social-distance-2-meters"
                :min="0"
                @wheel.stop.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!--  Uncovered Verand Area end -->

          <!-- Storeys -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Storeys:*</span>
              </v-card>
              <v-text-field
                dense
                filled
                class="rounded-xl"
                hide-details
                type="number"
                v-model="apartItem.storeys"
                :min="0"
                @wheel.stop.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Storeys end -->

          <!-- Energy Efficiency -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Energy Efficiency:</span>
              </v-card>
              <v-select
                :items="energyEfficiencies"
                v-model="apartItem.selectedEnergyEfficiency"
                item-text="name"
                item-value="id"
                hide-details
                filled
                dense
                class="rounded-xl"
                return-object
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Energy Efficiency end -->

          <!-- Windows Overlooks -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">Windows overlook:</span>
              </v-card>
              <v-select
                :items="windowsOverlooks"
                v-model="apartItem.selectedWindowsOverlooks"
                item-text="name"
                item-value="id"
                hide-details
                filled
                dense
                class="rounded-xl"
                return-object
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Windows Overlooks end -->

          <!-- Conditionings -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4"
                  >Air conditioning / Heating:*</span
                >
              </v-card>
              <v-select
                :items="conditionings"
                v-model="apartItem.selectedConditionings"
                item-text="name"
                item-value="id"
                hide-details
                filled
                dense
                class="rounded-xl"
                multiple
                return-object
                small-chips
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Conditionings end -->

          <!-- Primary/secondary -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-card elevation="0" width="30%">
                <span class="font-weight-regular mr-4">New/Resale:*</span>
              </v-card>
              <v-select
                :items="primaries"
                v-model="apartItem.selectedPrimary"
                item-text="name"
                item-value="id"
                hide-details
                filled
                dense
                class="rounded-xl"
                return-object
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Primary/secondary end -->

          <!-- Add/Remove button -->
          <v-row class="d-flex justify-center align-center">
            <v-col
              cols="12"
              class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            >
              <v-btn
                small
                elevation="0"
                class="red white--text rounded-xl text-capitalize font-weight-regular"
                @click="remove(index)"
                >Delete</v-btn
              >
            </v-col>
          </v-row>
          <!-- Add/Delete button -->
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="4">
        <v-card
          elevation="0"
          outlined
          class="rounded-xl blue white--text d-flex flex-column justify-center align-center pa-6"
          height="100%"
        >
          <v-btn
            fab
            x-large
            elevation="0"
            color="blue lighten-2"
            @click="insert()"
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ApartmentsUpdateComponent",
  props: {
    dialogState: Boolean,
    apartments: Array,
  },
  components: {},
  computed: {
    ...mapGetters({}),
  },
  data: () => ({
    apartmentsList: [],
    defaultItem: {
      id: 0,
      realEstateTypeId: 2,
      baths: 1,
      beds: 1,
      price: 0,
      internalCoveredArea: 0,
      coveredVerandArea: 0,
      uncoveredVerandArea: 0,
      storeys: 0,
      selectedCurrencyType: 2,
      selectedEnergyEfficiency: {
        id: 5,
        name: "Unspecified",
      },
      selectedWindowsOverlooks: { id: 6, name: "Unspecified" },
      selectedConditionings: [
        { id: 1, name: "A/C" },
        { id: 2, name: "Heating" },
      ],
      selectedPrimary: { id: 1, name: "New" },
      images: [],
      selectedImage: null,
      addImagess: [],
      deleteImages: [],
      insertImages: null,
      name: null,
      description: null,
    },
    currencyTypes: [
      {
        id: 2,
        code: "EUR",
        icon: "mdi-currency-eur",
      },
    ],
    energyEfficiencies: [
      {
        id: 1,
        name: "Class A",
      },
      {
        id: 2,
        name: "Class B",
      },
      {
        id: 3,
        name: "Class C",
      },
      {
        id: 4,
        name: "Class D",
      },
      {
        id: 5,
        name: "Unspecified",
      },
    ],
    windowsOverlooks: [
      { id: 1, name: "Sea/river view" },
      { id: 2, name: "Mountain view" },
      { id: 3, name: "City view" },
      { id: 4, name: "Park view" },
      { id: 5, name: "Nature view" },
      { id: 6, name: "Unspecified" },
    ],

    conditionings: [
      { id: 1, name: "A/C" },
      { id: 2, name: "Heating" },
      { id: 3, name: "Underfloor heating" },
      { id: 4, name: "Provision for A/C" },
      { id: 5, name: "Provision for heating systems" },
      { id: 6, name: "Provision for underfloor heating" },
    ],

    primaries: [
      { id: 1, name: "New" },
      { id: 2, name: "Resale" },
    ],
  }),
  methods: {
    ...mapActions({
      commitItemApartments: "realEstate/COMMIT_ITEM_APARTMENTS",
    }),
    insert() {
      this.apartmentsList.push(Object.assign({}, this.defaultItem));
    },
    remove(index) {
      this.apartmentsList.splice(index, 1);
    },
    createImage(file, callback) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageUrl = e.target.result;
        callback(imageUrl);
      };

      reader.readAsDataURL(file);
    },
    selectImage(item, i) {
      item.selectedImage = i;
    },
    deleteImage(item, id) {
      let ind = item.images.findIndex((x) => x.id == id);
      if (ind != -1) {
        item.images.splice(ind, 1);
        if (id != undefined) {
          item.deleteImages.push(id);
        }
      }
    },
    addImages(apartItem) {
      apartItem.insertImages.forEach((element) => {
        this.createImage(element, (imageUrl) => {
          apartItem.images.push({
            path: imageUrl,
          });

          apartItem.addImagess.push({
            src: imageUrl,
            file: element,
          });
        });
      });
    },
  },
  watch: {
    apartmentsList: {
      handler: function () {
        this.commitItemApartments(this.apartmentsList);
      },
      deep: true,
    },
    // dialogState: function () {
    //   this.apartmentsList = [];
    // },
  },
  async created() {
    let tempApartments = [];
    if (this.apartments != null) {
      tempApartments = this.apartments.map((x) => ({
        id: x.id,
        baths: x.bathroomsQuantity,
        beds: x.bedroomsQuantity,
        price: x.price,
        internalCoveredArea: x.internalCoveredArea,
        coveredVerandArea: x.coveredVerandArea,
        uncoveredVerandArea: x.uncoveredVerandArea,
        storeys: x.storeys,
        selectedCurrencyType: x.currencyTypeId,
        selectedEnergyEfficiency: this.energyEfficiencies.find(
          (z) => z.id == x.energyIfficiencyId
        ),
        selectedWindowsOverlooks: this.windowsOverlooks.find(
          (z) => z.id == x.windowsOverlookId
        ),
        selectedConditionings: this.conditionings.filter((z) =>
          x.condigitionings.includes(z.id)
        ),
        selectedPrimary: this.primaries.find((z) => z.id == x.primaryId),
        images: x.images,
        addImagess: [],
        deleteImages: [],
        insertImages: null,
        name: x.name,
        description: x.description,
      }));
    }

    this.apartmentsList = tempApartments;
  },
};
</script>