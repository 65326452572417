<template>
  <div>
    <Header />
    <ReportComponent />
  </div>
</template>
<script>
import Header from "../../components/News/NewsHeaderComponent.vue";
import ReportComponent from "../../components/Report/ReportComponent.vue";
export default {
  name: "ReportView",
  components: {
    Header,
    ReportComponent,
  },
  data: () => ({}),
  methods: {},
  mounted() {
    document.title = "Report - Valstand";
  },
  async created() {},
};
</script>