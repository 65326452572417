<template>
  <div>
    <HeaderTemplate
      :backgroundImage="null"
      :textColor="'blue-grey darken-4'"
      :logo="require('@/assets/images/logo-dark.png')"
      :hasText="false"
      :navColor="'black'"
    />
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="text-h4 font-weight-medium">Update ad</span>
          </v-col>
        </v-row>

        <!-- Category -->
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-h6 font-weight-regular"
                  >Choose a category</span
                >
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="8" lg="8" xl="8" class="text-center">
                <v-card color="grey lighten-4" elevation="0" class="rounded-xl">
                  <v-row>
                    <v-col cols="12" md="6" lg="6" xl="6">
                      <v-list
                        dense
                        color="grey lighten-4"
                        class="px-4"
                        disabled
                      >
                        <v-list-item-group
                          v-model="item.data.dealTypeId"
                          active-class="blue white--text rounded-xl"
                        >
                          <v-list-item
                            v-for="dealType in dealTypes"
                            :key="dealType.id"
                            :value="dealType.id"
                          >
                            {{ dealType.text }}
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-divider vertical />
                    <v-col cols="12" md="6" lg="6" xl="6">
                      <v-list
                        dense
                        color="grey lighten-4"
                        class="px-4"
                        disabled
                      >
                        <v-list-item-group
                          v-model="item.data.realStateTypeId"
                          active-class="blue white--text rounded-xl"
                        >
                          <v-list-item
                            v-for="realEstateType in realEstateTypes"
                            :key="realEstateType.id"
                            :value="realEstateType.id"
                          >
                            {{ realEstateType.text }}
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Category end -->

        <!-- Name -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.name.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Name:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Name end -->

        <!-- Developer -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developer.includes(item.data.realStateTypeId)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Developer:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.developerName"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer end -->

        <!-- Developer Representative -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developerRepresentative.includes(
                item.data.realStateTypeId
              )
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Developer Representative:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.developerRepresentative"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer Representative end -->

        <!-- Developer Email -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developerEmail.includes(
                item.data.realStateTypeId
              )
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Developer Email:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.developerEmail"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer Email end -->

        <!-- Developer Phone -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developerPhone.includes(
                item.data.realStateTypeId
              )
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Developer Phone:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.developerPhone"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer Phone end -->

        <!-- Bathrooms -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.baths.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Bathrooms:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.bathroomsQuantity"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Bathrooms end -->

        <!-- Beds -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.beds.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Beds:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.bedroomsQuantity"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Beds end -->

        <!-- Price -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.price.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Price:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.price"
              :min="0"
              @wheel.stop.prevent
            >
              <template slot="append">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      elevation="0"
                      class="blue white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        >{{
                          currencyTypes.find(
                            (x) => x.id == item.data.currencyTypeId
                          ).icon
                        }}
                      </v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item-group v-model="item.data.currencyTypeId">
                      <v-list-item
                        v-for="(currencyType, index) in currencyTypes"
                        :key="index"
                        :value="currencyType.id"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ currencyType.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                          currencyType.code
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Price end -->

        <!-- Area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.area.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Area:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.square"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Area end -->

        <!-- Internal covered area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.internalCoveredArea.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Internal covered area:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.internalCoveredArea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Internal covered area end -->

        <!-- Covered verand area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.coveredVerandArea.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Covered verand area:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.coveredVerandArea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Covered verand area end -->

        <!-- Uncovered verand area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.uncoveredVerandArea.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Uncovered verand area:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.uncoveredVerandArea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Uncovered verand area end -->

        <!-- Storeys -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.storeys.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Storeys:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.storeys"
              :min="1"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Storeys end -->

        <!-- Included -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.included.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Included:</span>
            </v-card>
            <div style="width: 70%">
              <v-checkbox
                v-for="includedProperty in includedProperties"
                :key="includedProperty.id"
                v-model="includedProperty.selected"
                :label="includedProperty.name"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <!-- Included end -->

        <!-- Energy Efficiency -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.energyEff.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Energy Efficiency:</span>
            </v-card>
            <v-select
              :items="energyEfficiencies"
              v-model="item.data.energyEfficiencyId"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Energy Efficiency end -->

        <!-- Windows Overlooks -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.windowsOverlook.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Windows overlook:</span>
            </v-card>
            <v-select
              :items="windowsOverlooks"
              v-model="item.data.windowsOverlookId"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Windows Overlooks end -->

        <!-- Construction year -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.constructionYear.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Construction year:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.costructionYear"
              @input="item.data.costructionYear = $event !== '' ? $event : null"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Construction year end -->

        <!-- Reconstruction year -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.reconstructionYear.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Reconstruction year/full renovation:</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.reconstructionYear"
              @input="
                item.data.reconstructionYear = $event !== '' ? $event : null
              "
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Reconstruction year end -->

        <!-- Year of delivery -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.yearOfDelivery.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Year of delivery*:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.yearOfDelivery"
              @input="item.data.yearOfDelivery = $event !== '' ? $event : null"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Year of delivery end -->

        <!-- Conditionings -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.conditioning.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Air conditioning / Heating:*</span
              >
            </v-card>
            <v-select
              :items="conditionings"
              v-model="item.data.conditionings"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              multiple
              small-chips
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Conditionings end -->

        <!-- Primary/secondary -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.primary.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">New/Resale:*</span>
            </v-card>
            <v-select
              :items="primaries"
              v-model="item.data.primaryId"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Primary/secondary end -->

        <!-- Images -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Images:*</span>
            </v-card>
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
            >
              <v-row
                v-if="item.data.images != null && item.data.images.length > 0"
              >
                <v-col cols="12">
                  <v-carousel hide-delimiter-background v-model="selectedImage">
                    <v-carousel-item
                      v-for="(item, i) in item.data.images"
                      :key="i"
                      :value="i"
                      :src="item.path"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      class="rounded-lg"
                    ></v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  v-for="(item, i) in item.data.images"
                  :key="i"
                  @click="selectImage(i)"
                  style="cursor: pointer"
                >
                  <v-img
                    width="128"
                    height="128"
                    :src="item.path"
                    class="rounded-lg text-right"
                  >
                    <v-btn
                      elevation="0"
                      fab
                      x-small
                      color="white ma-2"
                      @click="deleteImage(item.id)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-img>
                </v-col>
                <v-col cols="3">
                  <v-card
                    elevation="0"
                    class="rounded-lg blue white--text d-flex flex-column justify-center align-center pa-6"
                    width="128"
                    height="128"
                  >
                    <v-btn
                      x-small
                      fab
                      elevation="0"
                      color="blue lighten-2"
                      @click="$refs.fileInput.$refs.input.click()"
                    >
                      <v-icon color="white">mdi-plus</v-icon>
                    </v-btn>
                    <v-file-input
                      ref="fileInput"
                      v-model="images"
                      class="rounded-xl"
                      dense
                      accept="image/png, image/jpeg"
                      small-chips
                      hide-details
                      filled
                      multiple
                      v-show="false"
                    >
                    </v-file-input>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- Images end -->

        <!-- Countries -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.country.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Country:*</span>
            </v-card>
            <v-select
              :items="countries"
              v-model="selectedCountry"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Countries end -->

        <!-- Cities -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.city.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">City:*</span>
            </v-card>
            <v-select
              :items="cities.filter((x) => x.countryId == selectedCountry.id)"
              v-model="item.data.regionId"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Cities end -->

        <!-- Location -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.location.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Location:*</span>
            </v-card>
            <v-card
              elevation="0"
              class="rounded-lg blue white--text d-flex flex-column justify-center align-center pa-0"
              :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
            >
              <GmapMap
                :center="{
                  lat: item.data.latitude,
                  lng: item.data.longitude,
                }"
                :zoom="14"
                map-type-id="terrain"
                style="width: 100%; height: 40vh"
              >
                <GmapMarker
                  :position="{
                    lat: item.data.latitude,
                    lng: item.data.longitude,
                  }"
                  :draggable="true"
                  @drag="updateLocation"
                />
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
        <!-- Location end -->

        <!-- Distance to sea -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.distanceToSea.includes(
              item.data.realStateTypeId
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Distance to sea:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="item.data.distanceToSea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Distance to sea end -->

        <!-- Description -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.description.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Description:*</span>
            </v-card>
            <v-textarea
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.description"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <!-- Description end -->

         <!-- DescriptionRu -->
         <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.descriptionRu.includes(item.data.realStateTypeId)
          "
        >
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Description(Ru):*</span>
            </v-card>
            <v-textarea
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.descriptionRu"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <!-- DescriptionRu end -->

        <!-- Agent Name -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.agent.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Agent name:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.agentName"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Agent Name end -->

        <!-- Email -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.email.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Email:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.agentEmail"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Email end -->

        <!-- Phone -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.phone.includes(item.data.realStateTypeId)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Phone:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.agentPhone"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Phone end -->

        <!-- Comission -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.comission.includes(
                item.data.realStateTypeId
              )
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Comission:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="item.data.comission"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Comission end -->

        <!-- Multiple items add -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="item.data.realStateTypeId == 5"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-btn
              elevation="0"
              class="orange white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialog = true"
            >
              Update Apartments
            </v-btn>
            <v-btn
              elevation="0"
              class="green white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialogVilla = true"
            >
              Update Villa/Towhnouses
            </v-btn>
            <v-btn
              elevation="0"
              class="grey white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialogCommercials = true"
            >
              Update Commercials
            </v-btn>
            <v-btn
              elevation="0"
              class="teal white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialogPlots = true"
            >
              Update Plots
            </v-btn>
          </v-col>
        </v-row>
        <!-- Multiple items add end -->

        <!-- Aparts dialog -->
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialog = false"> Apply </v-btn>
              <v-toolbar-title>Update apartments</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <ApartmentsUpdateComponent
                :dialogState="dialog"
                :apartments="item.data.apartments"
              />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Aparts dialog end -->

        <!-- Villa dialog -->
        <v-dialog
          v-model="dialogVilla"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialogVilla = false">
                Apply
              </v-btn>
              <v-toolbar-title>Update Villa/Townhouses</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <VillaUpdateComponent
                :dialogState="dialogVilla"
                :villa="item.data.villa"
              />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Villa dialog end -->

        <!-- Commercial dialog -->
        <v-dialog
          v-model="dialogCommercials"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialogCommercials = false">
                Apply
              </v-btn>
              <v-toolbar-title>Update Commercials</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <CommercialsUpdateComponent
                :dialogState="dialogCommercials"
                :commercials="item.data.commercials"
              />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Commercial dialog end -->

        <!-- Plots dialog -->
        <v-dialog
          v-model="dialogPlots"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialogPlots = false">
                Apply
              </v-btn>
              <v-toolbar-title>Update Plots</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <PlotsUpdateComponent
                :dialogState="dialogPlots"
                :plots="item.data.plots"
              />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Plots dialog end -->

        <!-- Post button -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-btn
              elevation="0"
              class="blue white--text rounded-xl text-capitalize font-weight-regular"
              @click="update()"
              >Update ad</v-btn
            >
          </v-col>
        </v-row>
        <!-- Post button -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
import ApartmentsUpdateComponent from "./ApartmentsUpdateComponent.vue";
import VillaUpdateComponent from "./VillaUpdateComponent.vue";
import CommercialsUpdateComponent from "./CommercialsUpdateComponent.vue";
import PlotsUpdateComponent from "./PlotsUpdateComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RealEstateItemUpdateComponent",
  components: {
    HeaderTemplate,
    ApartmentsUpdateComponent,
    VillaUpdateComponent,
    CommercialsUpdateComponent,
    PlotsUpdateComponent,
  },
  computed: {
    ...mapGetters({
      getActionStatus: "realEstate/GET_ACTION_STATUS",
      getItem: "realEstate/GET_ITEM",
      getItemApartments: "realEstate/GET_ITEM_APARTMENTS",
      getItemVilla: "realEstate/GET_ITEM_VILLA",
      getItemCommercials: "realEstate/GET_ITEM_COMMERCIALS",
      getItemPlots: "realEstate/GET_ITEM_PLOTS",
    }),
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    item: {
      get: function () {
        return this.getItem;
      },
    },
    itemApartments: {
      get: function () {
        return this.getItemApartments;
      },
    },
    itemVilla: {
      get: function () {
        return this.getItemVilla;
      },
    },
    itemCommercials: {
      get: function () {
        return this.getItemCommercials;
      },
    },
    itemPlots: {
      get: function () {
        return this.getItemPlots;
      },
    },
  },
  data: () => ({
    propertiesReportType: {
      name: [1, 2, 3, 4, 5],
      developer: [1, 2, 3, 4, 5],
      developerRepresentative: [1, 2, 3, 4, 5],
      developerEmail: [1, 2, 3, 4, 5],
      developerPhone: [1, 2, 3, 4, 5],
      comission: [1, 2, 3, 4, 5],
      baths: [1, 2],
      beds: [1, 2],
      price: [1, 2, 3, 4],
      area: [4, 5],
      internalCoveredArea: [1, 2, 3],
      coveredVerandArea: [1, 2, 3],
      uncoveredVerandArea: [1, 2, 3],
      storeys: [1, 2, 3, 4],
      included: [1, 2, 3, 5],
      energyEff: [1, 2, 3],
      windowsOverlook: [1, 2, 3],
      constructionYear: [1, 2, 3, 5],
      reconstructionYear: [1, 2, 3, 5],
      yearOfDelivery: [1, 2, 3, 5],
      conditioning: [1, 2, 3],
      primary: [1, 2, 3],
      country: [1, 2, 3, 4, 5],
      city: [1, 2, 3, 4, 5],
      location: [1, 2, 3, 4, 5],
      distanceToSea: [1, 2, 3, 4, 5],
      description: [1, 2, 3, 4, 5],
      descriptionRu: [1, 2, 3, 4, 5],
      agent: [1, 2, 3, 4, 5],
      email: [1, 2, 3, 4, 5],
      phone: [1, 2, 3, 4, 5],
    },
    dealTypes: [{ id: 1, text: "Sale" }],
    realEstateTypes: [
      { id: 1, text: "Villa/Townhouse" },
      { id: 2, text: "Apartment" },
      { id: 3, text: "Commercial" },
      { id: 4, text: "Plot" },
      { id: 5, text: "Project", selected: false },
    ],
    currencyTypes: [
      {
        id: 2,
        code: "EUR",
        icon: "mdi-currency-eur",
      },
    ],
    energyEfficiencies: [
      {
        id: 1,
        name: "Class A",
      },
      {
        id: 2,
        name: "Class B",
      },
      {
        id: 3,
        name: "Class C",
      },
      {
        id: 4,
        name: "Class D",
      },
      {
        id: 5,
        name: "Unspecified",
      },
    ],
    windowsOverlooks: [
      { id: 1, name: "Sea/river view" },
      { id: 2, name: "Mountain view" },
      { id: 3, name: "City view" },
      { id: 4, name: "Park view" },
      { id: 5, name: "Nature view" },
      { id: 6, name: "Unspecified" },
    ],
    conditionings: [
      { id: 1, name: "A/C" },
      { id: 2, name: "Heating" },
      { id: 3, name: "Underfloor heating" },
      { id: 4, name: "Provision for A/C" },
      { id: 5, name: "Provision for heating systems" },
      { id: 6, name: "Provision for underfloor heating" },
    ],
    primaries: [
      { id: 1, name: "New" },
      { id: 2, name: "Resale" },
    ],
    location: {
      lat: 34.687014,
      lng: 33.036281,
    },
    countries: [{ id: 1, name: "Cyprus" }],
    selectedCountry: { id: 1, name: "Cyprus" },
    cities: [
      { id: 2, name: "Limassol", countryId: 1 },
      { id: 3, name: "Paraklisi", countryId: 1 },
      { id: 4, name: "Ypsonas", countryId: 1 },
      { id: 5, name: "Episkopi", countryId: 1 },
      { id: 6, name: "Paphos", countryId: 1 },
      { id: 7, name: "Polis", countryId: 1 },
      { id: 8, name: "Latchi", countryId: 1 },
      { id: 9, name: "Larnaca", countryId: 1 },
      { id: 10, name: "Zigi", countryId: 1 },
      { id: 11, name: "Mazotos", countryId: 1 },
      { id: 12, name: "Livadia", countryId: 1 },
      { id: 13, name: "Oroklini", countryId: 1 },
      { id: 14, name: "Pila", countryId: 1 },
      { id: 15, name: "Ayia Napa", countryId: 1 },
      { id: 16, name: "Protaras", countryId: 1 },
      { id: 17, name: "Paralimni", countryId: 1 },
      { id: 18, name: "Nicosia", countryId: 1 },
    ],
    includedProperties: [
      { id: 1, name: "Swimming pool", selected: false },
      { id: 2, name: "Storage", selected: false },
      { id: 3, name: "Parking/Garage", selected: false },
      { id: 4, name: "Gym", selected: false },
      { id: 5, name: "Security 24/7", selected: false },
      { id: 6, name: "Beauty centers", selected: false },
      { id: 7, name: "Food court", selected: false },
      { id: 8, name: "Garden", selected: false },
      { id: 9, name: "Golf field ", selected: false },
    ],
    images: null,
    addImages: [],
    deleteImages: [],
    selectedImage: null,
    dialog: false,
    dialogVilla: false,
    dialogCommercials: false,
    dialogPlots: false,
  }),
  methods: {
    ...mapActions({
      updateItemFromApi: "realEstate/UPDATE_ITEM_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      commitItemApartments: "realEstate/COMMIT_ITEM_APARTMENTS",
      generateSnackbar: "common/GENERATE_SNACKBAR",
    }),
    async update() {
      this.startRequest("updateRealEstate");

      let formData = new FormData();
      formData.append("id", this.item.data.id);
      formData.append("dealTypeId", this.item.data.dealTypeId);
      formData.append("realEstateTypeId", this.item.data.realStateTypeId);

      if (
        this.propertiesReportType.city.includes(this.item.data.realStateTypeId)
      ) {
        formData.append("regionId", this.item.data.regionId);
      }

      if (
        this.propertiesReportType.name.includes(this.item.data.realStateTypeId)
      ) {
        if (this.item.data.name == null || this.item.data.name == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Name field!",
          });
          return;
        }
        formData.append("name", this.item.data.name);
      }

      if (
        this.propertiesReportType.baths.includes(this.item.data.realStateTypeId)
      ) {
        formData.append("bathroomsQuantity", this.item.data.bathroomsQuantity);
      }

      if (
        this.propertiesReportType.beds.includes(this.item.data.realStateTypeId)
      ) {
        formData.append("bedroomsQuantity", this.item.data.bedroomsQuantity);
      }

      if (
        this.propertiesReportType.price.includes(this.item.data.realStateTypeId)
      ) {
        if (this.item.data.price == null || this.item.data.price == 0) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Price field!",
          });
          return;
        }
        formData.append("price", this.item.data.price);
        formData.append("currencyTypeId", this.item.data.currencyTypeId);
      }

      if (
        this.propertiesReportType.area.includes(this.item.data.realStateTypeId)
      ) {
        if (this.item.data.square == null || this.item.data.square == 0) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Area field!",
          });
          return;
        }
        formData.append("square", this.item.data.square);
      }

      if (
        this.propertiesReportType.internalCoveredArea.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append(
          "internalCoveredArea",
          this.item.data.internalCoveredArea
        );
      }

      if (
        this.propertiesReportType.coveredVerandArea.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append("coveredVerandArea", this.item.data.coveredVerandArea);
      }

      if (
        this.propertiesReportType.uncoveredVerandArea.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append(
          "uncoveredVerandArea",
          this.item.data.uncoveredVerandArea
        );
      }

      if (
        this.propertiesReportType.storeys.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append("storeys", this.item.data.storeys);
      }

      if (
        this.propertiesReportType.description.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.description == null ||
          this.item.data.description == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Description field!",
          });
          return;
        }
        formData.append("description", this.item.data.description);
      }

      if (
        this.propertiesReportType.descriptionRu.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.descriptionRu == null ||
          this.item.data.descriptionRu == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Description(Ru) field!",
          });
          return;
        }
        formData.append("descriptionRu", this.item.data.descriptionRu);
      }

      if (
        this.propertiesReportType.energyEff.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append(
          "energyEfficiencyId",
          this.item.data.energyEfficiencyId
        );
      }

      if (
        this.propertiesReportType.windowsOverlook.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append("windowsOverlookId", this.item.data.windowsOverlookId);
      }

      if (
        this.propertiesReportType.primary.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append("primaryId", this.item.data.primaryId);
      }

      if (
        this.propertiesReportType.constructionYear.includes(
          this.item.data.realStateTypeId
        ) &&
        this.item.data.costructionYear != null
      ) {
        formData.append("costructionYear", this.item.data.costructionYear);
      }

      if (
        this.propertiesReportType.reconstructionYear.includes(
          this.item.data.realStateTypeId
        ) &&
        this.item.data.reconstructionYear != null
      ) {
        formData.append(
          "reconstructionYear",
          this.item.data.reconstructionYear
        );
      }

      if (
        this.propertiesReportType.yearOfDelivery.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.yearOfDelivery == null ||
          this.item.data.yearOfDelivery == 0
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Year of Delivery field!",
          });
          return;
        }
        formData.append("yearOfDelivery", this.item.data.yearOfDelivery);
      }

      if (
        this.propertiesReportType.agent.includes(this.item.data.realStateTypeId)
      ) {
        if (
          this.item.data.agentName == null ||
          this.item.data.agentName == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Agent Name field!",
          });
          return;
        }
        formData.append("agentName", this.item.data.agentName);
      }

      if (
        this.propertiesReportType.email.includes(this.item.data.realStateTypeId)
      ) {
        if (
          this.item.data.agentEmail == null ||
          this.item.data.agentEmail == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Agent Email field!",
          });
          return;
        }
        formData.append("agentEmail", this.item.data.agentEmail);
      }

      if (
        this.propertiesReportType.phone.includes(this.item.data.realStateTypeId)
      ) {
        if (
          this.item.data.agentPhone == null ||
          this.item.data.agentPhone == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Agent Phone field!",
          });
          return;
        }
        formData.append("agentPhone", this.item.data.agentPhone);
      }

      if (
        this.propertiesReportType.developer.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.developerName == null ||
          this.item.data.developerName == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer field!",
          });
          return;
        }
        formData.append("developerName", this.item.data.developerName);
      }

      if (
        this.propertiesReportType.developerRepresentative.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.developerRepresentative == null ||
          this.item.data.developerRepresentative == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer Representative field!",
          });
          return;
        }
        formData.append(
          "developerRepresentative",
          this.item.data.developerRepresentative
        );
      }

      if (
        this.propertiesReportType.developerEmail.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.developerEmail == null ||
          this.item.data.developerEmail == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer Email field!",
          });
          return;
        }
        formData.append("developerEmail", this.item.data.developerEmail);
      }

      if (
        this.propertiesReportType.developerPhone.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.developerPhone == null ||
          this.item.data.developerPhone == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer Phone field!",
          });
          return;
        }
        formData.append("developerPhone", this.item.data.developerPhone);
      }
      
      if (
        this.propertiesReportType.comission.includes(
          this.item.data.realStateTypeId
        )
      ) {
        if (
          this.item.data.comission == null ||
          this.item.data.comission == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Comission field!",
          });
          return;
        }
        formData.append("comission", this.item.data.comission);
      }

      if (
        this.propertiesReportType.distanceToSea.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append("distanceToSea", this.item.data.distanceToSea);
      }

      if (
        this.propertiesReportType.location.includes(
          this.item.data.realStateTypeId
        )
      ) {
        formData.append(
          "longitude",
          this.location != null ? this.location.lng.toFixed(10) : null
        );
        formData.append(
          "latitude",
          this.location != null ? this.location.lat.toFixed(10) : null
        );
      }

      if (
        this.propertiesReportType.conditioning.includes(
          this.item.data.realStateTypeId
        )
      ) {
        for (let item of this.item.data.conditionings) {
          formData.append("conditionings", item.id);
        }
      }

      if (
        this.propertiesReportType.included.includes(
          this.item.data.realStateTypeId
        )
      ) {
        for (let item of this.includedProperties.filter((x) => x.selected)) {
          formData.append("includeProperties", item.id);
        }
      }

      if (this.addImages != null) {
        for (let image of this.addImages) {
          formData.append("files", image.file, image.file.name);
        }
      }

      if (this.deleteImages != null) {
        for (let image of this.deleteImages) {
          formData.append("deleteFiles", image);
        }
      }

      await this.addApartments(formData);
      await this.addVilla(formData);
      await this.addCommercials(formData);
      await this.addPlots(formData);

      await this.updateItemFromApi(formData);

      this.finishRequest({
        requestName: "updateRealEstate",
        success: this.actionStatus != null,
        snackbarShow: true,
      });

      if (this.actionStatus != null) {
        var data = this.$router.resolve({
          path: "/real-estate-admin-items",
        });
        document.location.href = data.href;
      }
    },
    async addApartments(formData) {
      let aparts = [];
      if (this.itemApartments != null && this.itemApartments.length > 0) {
        aparts = this.itemApartments.map((element) => ({
          id: element.id,
          bathroomsQuantity: element.baths,
          bedroomsQuantity: element.beds,
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          internalCoveredArea: element.internalCoveredArea,
          coveredVerandArea: element.coveredVerandArea,
          uncoveredVerandArea: element.uncoveredVerandArea,
          storeys: element.storeys,
          energyEfficiencyId: element.selectedEnergyEfficiency.id,
          windowsOverlookId: element.selectedWindowsOverlooks.id,
          conditionings: element.selectedConditionings,
          primaryId: element.selectedPrimary.id,
          addImages: element.addImagess,
          deleteImages: element.deleteImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("apartments[" + i + "].id", aparts[i].id);
          formData.append("apartments[" + i + "].dealTypeId", 1);
          formData.append("apartments[" + i + "].realEstateTypeId", 2);
          formData.append(
            "apartments[" + i + "].bathroomsQuantity",
            aparts[i].bathroomsQuantity
          );
          formData.append(
            "apartments[" + i + "].bedroomsQuantity",
            aparts[i].bedroomsQuantity
          );
          formData.append("apartments[" + i + "].price", aparts[i].price);
          formData.append(
            "apartments[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append(
            "apartments[" + i + "].internalCoveredArea",
            aparts[i].internalCoveredArea
          );
          formData.append(
            "apartments[" + i + "].coveredVerandArea",
            aparts[i].coveredVerandArea
          );
          formData.append(
            "apartments[" + i + "].uncoveredVerandArea",
            aparts[i].uncoveredVerandArea
          );
          formData.append("apartments[" + i + "].storeys", aparts[i].storeys);
          formData.append(
            "apartments[" + i + "].energyEfficiencyId",
            aparts[i].energyEfficiencyId
          );
          formData.append(
            "apartments[" + i + "].windowsOverlookId",
            aparts[i].windowsOverlookId
          );
          for (let j = 0; j < aparts[i].conditionings.length; j++) {
            formData.append(
              "apartments[" + i + "].conditionings[" + j + "]",
              aparts[i].conditionings[j].id
            );
          }
          formData.append(
            "apartments[" + i + "].primaryId",
            aparts[i].primaryId
          );

          if (aparts[i].addImages != null && aparts[i].addImages != undefined) {
            for (let j = 0; j < aparts[i].addImages.length; j++) {
              const base64String = await this.createBase64Image(
                aparts[i].addImages[j].file
              );
              formData.append(
                `apartments[${i}].innerFiles[${j}]`,
                base64String
              );
            }
          }

          if (
            aparts[i].deleteImages != null &&
            aparts[i].deleteImages != undefined
          ) {
            for (let j = 0; j < aparts[i].deleteImages.length; j++) {
              formData.append(
                `apartments[${i}].deleteFiles[${j}]`,
                aparts[i].deleteImages[j]
              );
            }
          }

          formData.append("apartments[" + i + "].name", aparts[i].name);
          formData.append(
            "apartments[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    async addVilla(formData) {
      let aparts = [];
      if (this.itemVilla != null && this.itemVilla.length > 0) {
        aparts = this.itemVilla.map((element) => ({
          id: element.id,
          bathroomsQuantity: element.baths,
          bedroomsQuantity: element.beds,
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          internalCoveredArea: element.internalCoveredArea,
          coveredVerandArea: element.coveredVerandArea,
          uncoveredVerandArea: element.uncoveredVerandArea,
          storeys: element.storeys,
          energyEfficiencyId: element.selectedEnergyEfficiency.id,
          windowsOverlookId: element.selectedWindowsOverlooks.id,
          conditionings: element.selectedConditionings,
          primaryId: element.selectedPrimary.id,
          addImages: element.addImagess,
          deleteImages: element.deleteImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("villa[" + i + "].id", aparts[i].id);
          formData.append("villa[" + i + "].dealTypeId", 1);
          formData.append("villa[" + i + "].realEstateTypeId", 1);
          formData.append(
            "villa[" + i + "].bathroomsQuantity",
            aparts[i].bathroomsQuantity
          );
          formData.append(
            "villa[" + i + "].bedroomsQuantity",
            aparts[i].bedroomsQuantity
          );
          formData.append("villa[" + i + "].price", aparts[i].price);
          formData.append(
            "villa[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append(
            "villa[" + i + "].internalCoveredArea",
            aparts[i].internalCoveredArea
          );
          formData.append(
            "villa[" + i + "].coveredVerandArea",
            aparts[i].coveredVerandArea
          );
          formData.append(
            "villa[" + i + "].uncoveredVerandArea",
            aparts[i].uncoveredVerandArea
          );
          formData.append("villa[" + i + "].storeys", aparts[i].storeys);
          formData.append(
            "villa[" + i + "].energyEfficiencyId",
            aparts[i].energyEfficiencyId
          );
          formData.append(
            "villa[" + i + "].windowsOverlookId",
            aparts[i].windowsOverlookId
          );
          for (let j = 0; j < aparts[i].conditionings.length; j++) {
            formData.append(
              "villa[" + i + "].conditionings[" + j + "]",
              aparts[i].conditionings[j].id
            );
          }
          formData.append("villa[" + i + "].primaryId", aparts[i].primaryId);

          if (aparts[i].addImages != null && aparts[i].addImages != undefined) {
            for (let j = 0; j < aparts[i].addImages.length; j++) {
              const base64String = await this.createBase64Image(
                aparts[i].addImages[j].file
              );
              formData.append(`villa[${i}].innerFiles[${j}]`, base64String);
            }
          }

          if (
            aparts[i].deleteImages != null &&
            aparts[i].deleteImages != undefined
          ) {
            for (let j = 0; j < aparts[i].deleteImages.length; j++) {
              formData.append(
                `villa[${i}].deleteFiles[${j}]`,
                aparts[i].deleteImages[j]
              );
            }
          }

          formData.append("villa[" + i + "].name", aparts[i].name);
          formData.append(
            "villa[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    async addCommercials(formData) {
      let aparts = [];
      if (this.itemCommercials != null && this.itemCommercials.length > 0) {
        aparts = this.itemCommercials.map((element) => ({
          id: element.id,
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          internalCoveredArea: element.internalCoveredArea,
          coveredVerandArea: element.coveredVerandArea,
          uncoveredVerandArea: element.uncoveredVerandArea,
          storeys: element.storeys,
          energyEfficiencyId: element.selectedEnergyEfficiency.id,
          windowsOverlookId: element.selectedWindowsOverlooks.id,
          conditionings: element.selectedConditionings,
          primaryId: element.selectedPrimary.id,
          addImages: element.addImagess,
          deleteImages: element.deleteImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("commercials[" + i + "].id", aparts[i].id);
          formData.append("commercials[" + i + "].dealTypeId", 1);
          formData.append("commercials[" + i + "].realEstateTypeId", 3);
          formData.append("commercials[" + i + "].price", aparts[i].price);
          formData.append(
            "commercials[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append(
            "commercials[" + i + "].internalCoveredArea",
            aparts[i].internalCoveredArea
          );
          formData.append(
            "commercials[" + i + "].coveredVerandArea",
            aparts[i].coveredVerandArea
          );
          formData.append(
            "commercials[" + i + "].uncoveredVerandArea",
            aparts[i].uncoveredVerandArea
          );
          formData.append("commercials[" + i + "].storeys", aparts[i].storeys);
          formData.append(
            "commercials[" + i + "].energyEfficiencyId",
            aparts[i].energyEfficiencyId
          );
          formData.append(
            "commercials[" + i + "].windowsOverlookId",
            aparts[i].windowsOverlookId
          );
          for (let j = 0; j < aparts[i].conditionings.length; j++) {
            formData.append(
              "commercials[" + i + "].conditionings[" + j + "]",
              aparts[i].conditionings[j].id
            );
          }
          formData.append(
            "commercials[" + i + "].primaryId",
            aparts[i].primaryId
          );

          if (aparts[i].addImages != null && aparts[i].addImages != undefined) {
            for (let j = 0; j < aparts[i].addImages.length; j++) {
              const base64String = await this.createBase64Image(
                aparts[i].addImages[j].file
              );
              formData.append(
                `commercials[${i}].innerFiles[${j}]`,
                base64String
              );
            }
          }

          if (
            aparts[i].deleteImages != null &&
            aparts[i].deleteImages != undefined
          ) {
            for (let j = 0; j < aparts[i].deleteImages.length; j++) {
              formData.append(
                `commercials[${i}].deleteFiles[${j}]`,
                aparts[i].deleteImages[j]
              );
            }
          }

          formData.append("commercials[" + i + "].name", aparts[i].name);
          formData.append(
            "commercials[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    async addPlots(formData) {
      let aparts = [];
      if (this.itemPlots != null && this.itemPlots.length > 0) {
        aparts = this.itemPlots.map((element) => ({
          id: element.id,
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          square: element.area,
          storeys: element.storeys,
          addImages: element.addImagess,
          deleteImages: element.deleteImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("plots[" + i + "].id", aparts[i].id);
          formData.append("plots[" + i + "].dealTypeId", 1);
          formData.append("plots[" + i + "].realEstateTypeId", 4);
          formData.append("plots[" + i + "].price", aparts[i].price);
          formData.append(
            "plots[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append("plots[" + i + "].square", aparts[i].square);
          formData.append("plots[" + i + "].storeys", aparts[i].storeys);

          if (aparts[i].addImages != null && aparts[i].addImages != undefined) {
            for (let j = 0; j < aparts[i].addImages.length; j++) {
              const base64String = await this.createBase64Image(
                aparts[i].addImages[j].file
              );
              formData.append(`plots[${i}].innerFiles[${j}]`, base64String);
            }
          }

          if (
            aparts[i].deleteImages != null &&
            aparts[i].deleteImages != undefined
          ) {
            for (let j = 0; j < aparts[i].deleteImages.length; j++) {
              formData.append(
                `plots[${i}].deleteFiles[${j}]`,
                aparts[i].deleteImages[j]
              );
            }
          }

          formData.append("plots[" + i + "].name", aparts[i].name);
          formData.append(
            "plots[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    updateLocation(location) {
      this.location = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    createImage(file, callback) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageUrl = e.target.result;
        callback(imageUrl);
      };

      reader.readAsDataURL(file);
    },
    selectImage(i) {
      this.selectedImage = i;
    },
    deleteImage(id) {
      let ind = this.item.data.images.findIndex((x) => x.id == id);
      if (ind != -1) {
        this.item.data.images.splice(ind, 1);
        this.deleteImages.push(id);
      }
    },
    createBase64Image(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
  },
  watch: {
    item: function () {
      if (this.item.data != null) {
        this.includedProperties.forEach((elem) => {
          elem.selected =
            this.item.data.includeProperties.find((x) => x.id == elem.id) !=
            undefined
              ? true
              : false;
        });

        this.location = {
          lat: this.item.data.latitude,
          lng: this.item.data.longitude,
        };
      }
    },
    images: function () {
      this.images.forEach((element) => {
        this.createImage(element, (imageUrl) => {
          this.item.data.images.push({
            path: imageUrl,
          });

          this.addImages.push({
            src: imageUrl,
            file: element,
          });
        });
      });
    },
  },
};
</script>