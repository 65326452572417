export default {
    SET_ITEM(state, payload) {
        state.item = payload;
    },
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_SELECTED_PARAMS(state, payload) {
        state.selectedParams = payload;
    },
    SET_ACTION_STATUS(state, payload) {
        state.actionStatus = payload;
    },
    SET_INFO(state, payload) {
        state.info = payload;
    },
    SET_DEVELOPERS(state, payload) {
        state.developers = payload;
    },
    SET_PROJECTS(state, payload) {
        state.projects = payload;
    },
    SET_CODES(state, payload) {
        state.codes = payload;
    },
    SET_AGENT_NAMES(state, payload) {
        state.agentNames = payload;
    },
    SET_REAL_ESTATES(state, payload) {
        state.realEstates = payload;
    },
    DISABLE_ITEM(state, payload) {
        state.disabledItems.push(payload);
    },
    ENABLE_ITEM(state, payload) {
        var itemIndex = state.disabledItems.indexOf(payload);
        state.disabledItems.splice(itemIndex, 1);
    },
    SET_DISABLED_ITEMS(state, payload) {
        state.disabledItems = payload;
    },
    SET_ITEM_APARTMENTS(state, payload) {
        state.itemApartments = payload;
    },
    SET_ITEM_VILLA(state, payload) {
        state.itemVilla = payload;
    },
    SET_ITEM_COMMERCIALS(state, payload) {
        state.itemCommercials = payload;
    },
    SET_LISTING_LINK(state, payload) {
        state.listingLink = payload;
    },
    SET_LISTING_PROPERTIES(state, payload) {
        state.listingProperties = payload;
    },
    SET_ITEM_PLOTS(state, payload) {
        state.itemPlots = payload;
    },
    SET_SELECTED_ANALYSIS_CITY(state, payload) {
        state.selectedAnalysisCity = payload;
    },
    SET_RECENT_ITEMS(state, payload) {
        state.recentItems = payload;
    },
}