<template>
  <HeaderTemplate
    :backgroundImage="
      require($vuetify.breakpoint.smAndDown
        ? '@/assets/images/home-header-min.png'
        : '@/assets/images/home-header.png')
    "
    :textColor="'white'"
    :hasText="true"
    :logo="require('@/assets/images/logo.png')"
    :navColor="'white'"
  />
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
export default {
  name: "HomeHeaderComponent",
  components: {
    HeaderTemplate,
  },
  data: () => ({}),
};
</script>