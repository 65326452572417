import { Get } from '../../services/calculatorService';

export default {
    async GET_FROM_API({ commit }, payload) {
        await Get(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEM', data);
                }
            })
            .catch(() => {
                commit('SET_ITEM', null);
            })
    },
}