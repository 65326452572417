<template>
  <div>
    <HeaderTemplate
      :backgroundImage="null"
      :textColor="'blue-grey darken-4'"
      :logo="require('@/assets/images/logo-dark.png')"
      :hasText="false"
      :navColor="'black'"
    />
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="text-h4 font-weight-medium">Post and ad</span>
          </v-col>
        </v-row>

        <!-- Category -->
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-h6 font-weight-regular"
                  >Choose a category</span
                >
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="8" lg="8" xl="8" class="text-center">
                <v-card color="grey lighten-4" elevation="0" class="rounded-xl">
                  <v-row>
                    <v-col cols="12" md="6" lg="6" xl="6">
                      <v-list dense color="grey lighten-4" class="px-4">
                        <v-list-item-group
                          v-model="selectedDealType"
                          active-class="blue white--text rounded-xl"
                        >
                          <v-list-item
                            v-for="dealType in dealTypes"
                            :key="dealType.id"
                            :value="dealType.id"
                          >
                            {{ dealType.text }}
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-divider vertical />
                    <v-col cols="12" md="6" lg="6" xl="6">
                      <v-list dense color="grey lighten-4" class="px-4">
                        <v-list-item-group
                          v-model="selectedRealEstateType"
                          active-class="blue white--text rounded-xl"
                        >
                          <v-list-item
                            v-for="realEstateType in realEstateTypes"
                            :key="realEstateType.id"
                            :value="realEstateType.id"
                          >
                            {{ realEstateType.text }}
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Category end -->

        <!-- Name -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.name.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Name:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Name end -->

        <!-- Developer -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developer.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Developer:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="developer"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer end -->

        <!-- Developer Representative -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developerRepresentative.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Developer Representative:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="developerRepresentative"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer Representative end -->

        <!-- Developer Email -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developerEmail.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Developer Email:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="developerEmail"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer Email end -->

        <!-- Developer Phone -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.developerPhone.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Developer Phone:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="developerPhone"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Developer Phone end -->

        <!-- Bathrooms -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.baths.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Bathrooms:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="baths"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Bathrooms end -->

        <!-- Beds -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.beds.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Beds:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="beds"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Beds end -->

        <!-- Price -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.price.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Price:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="price"
              :min="0"
              @wheel.stop.prevent
            >
              <template slot="append">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      elevation="0"
                      class="blue white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        >{{
                          currencyTypes.find(
                            (x) => x.id == selectedCurrencyType
                          ).icon
                        }}
                      </v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item-group v-model="selectedCurrencyType">
                      <v-list-item
                        v-for="(currencyType, index) in currencyTypes"
                        :key="index"
                        :value="currencyType.id"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ currencyType.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                          currencyType.code
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Price end -->

        <!-- Area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.area.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Area:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="area"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Area end -->

        <!-- Internal covered area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.internalCoveredArea.includes(
              selectedRealEstateType
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Internal covered area:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="internalCoveredArea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Internal covered area end -->

        <!-- Covered verand area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.coveredVerandArea.includes(
              selectedRealEstateType
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Covered verand area:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="coveredVerandArea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Covered verand area end -->

        <!-- Uncovered verand area -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.uncoveredVerandArea.includes(
              selectedRealEstateType
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Uncovered verand area:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="uncoveredVerandArea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Uncovered verand area end -->

        <!-- Storeys -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.storeys.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Storeys:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="storeys"
              :min="1"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Storeys end -->

        <!-- Included -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.included.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Included:</span>
            </v-card>
            <div style="width: 70%">
              <v-checkbox
                v-for="includedProperty in includedProperties"
                :key="includedProperty.id"
                v-model="includedProperty.selected"
                :label="includedProperty.name"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <!-- Included end -->

        <!-- Energy Efficiency -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.energyEff.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Energy Efficiency:</span>
            </v-card>
            <v-select
              :items="energyEfficiencies"
              v-model="selectedEnergyEfficiency"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Energy Efficiency end -->

        <!-- Windows Overlooks -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.windowsOverlook.includes(
              selectedRealEstateType
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Windows overlook:</span>
            </v-card>
            <v-select
              :items="windowsOverlooks"
              v-model="selectedWindowsOverlooks"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Windows Overlooks end -->

        <!-- Construction year -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.constructionYear.includes(
              selectedRealEstateType
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Construction year:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="costructionYear"
              @input="costructionYear = $event !== '' ? $event : null"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Construction year end -->

        <!-- Reconstruction year -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.reconstructionYear.includes(
              selectedRealEstateType
            )
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Reconstruction year/full renovation:</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="reconstructionYear"
              @input="reconstructionYear = $event !== '' ? $event : null"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Reconstruction year end -->

        <!-- Year of delivery -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.yearOfDelivery.includes(selectedRealEstateType)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Year of delivery*:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="yearOfDelivery"
              @input="yearOfDelivery = $event !== '' ? $event : null"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Year of delivery end -->

        <!-- Conditionings -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.conditioning.includes(selectedRealEstateType)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >Air conditioning / Heating:*</span
              >
            </v-card>
            <v-select
              :items="conditionings"
              v-model="selectedConditionings"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              multiple
              return-object
              small-chips
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Conditionings end -->

        <!-- Primary/secondary -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.primary.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">New/Resale:*</span>
            </v-card>
            <v-select
              :items="primaries"
              v-model="selectedPrimary"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Primary/secondary end -->

        <!-- Images -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Images:*</span>
            </v-card>
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
            >
              <v-row v-if="previewImages != null && previewImages.length > 0">
                <v-col cols="12">
                  <v-carousel hide-delimiter-background v-model="selectedImage">
                    <v-carousel-item
                      v-for="(item, i) in previewImages"
                      :key="i"
                      :value="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      class="rounded-lg"
                    ></v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  v-for="(item, i) in previewImages"
                  :key="i"
                  @click="selectImage(i)"
                  style="cursor: pointer"
                >
                  <v-img
                    width="128"
                    height="128"
                    :src="item.src"
                    class="rounded-lg text-right"
                  >
                    <v-btn
                      elevation="0"
                      fab
                      x-small
                      color="white ma-2"
                      @click="deleteImage(i)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-img>
                </v-col>
                <v-col cols="3">
                  <v-card
                    elevation="0"
                    class="rounded-lg blue white--text d-flex flex-column justify-center align-center pa-6"
                    width="128"
                    height="128"
                  >
                    <v-btn
                      x-small
                      fab
                      elevation="0"
                      color="blue lighten-2"
                      @click="$refs.fileInput.$refs.input.click()"
                    >
                      <v-icon color="white">mdi-plus</v-icon>
                    </v-btn>
                    <v-file-input
                      ref="fileInput"
                      v-model="images"
                      class="rounded-xl"
                      dense
                      accept="image/png, image/jpeg"
                      small-chips
                      hide-details
                      filled
                      multiple
                      v-show="false"
                    >
                    </v-file-input>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- Images end -->

        <!-- Countries -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.country.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Country:*</span>
            </v-card>
            <v-select
              :items="countries"
              v-model="selectedCountry"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Countries end -->

        <!-- Cities -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.city.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">City:*</span>
            </v-card>
            <v-select
              :items="cities.filter((x) => x.countryId == selectedCountry.id)"
              v-model="selectedCity"
              item-text="name"
              item-value="id"
              hide-details
              filled
              dense
              class="rounded-xl"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Cities end -->

        <!-- Location -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.location.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Location:*</span>
            </v-card>
            <v-card
              elevation="0"
              class="rounded-lg blue white--text d-flex flex-column justify-center align-center pa-0"
              :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
            >
              <GmapMap
                :center="coords"
                :zoom="14"
                map-type-id="terrain"
                style="width: 100%; height: 40vh"
              >
                <GmapMarker
                  :position="coords"
                  :draggable="true"
                  @drag="updateLocation"
                />
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
        <!-- Location end -->

        <!-- Distance to sea -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="
            propertiesReportType.distanceToSea.includes(selectedRealEstateType)
          "
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Distance to sea:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              type="number"
              v-model="distanceToSea"
              append-icon="mdi-social-distance-2-meters"
              :min="0"
              @wheel.stop.prevent
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Distance to sea end -->

        <!-- Description -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.description.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Description:*</span>
            </v-card>
            <v-textarea
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="description"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <!-- Description end -->

        <!-- DescriptionRu -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.descriptionRu.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Description(Ru):*</span>
            </v-card>
            <v-textarea
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="descriptionRu"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <!-- DescriptionRu end -->

        <!-- Agent Name -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.agent.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Agent name*:</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="agentName"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Agent Name end -->

        <!-- Email -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.email.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Email:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="email"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Email end -->

        <!-- Phone -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="propertiesReportType.phone.includes(selectedRealEstateType)"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Phone:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="phone"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Phone end -->

        <!-- Comission -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
            v-if="
              propertiesReportType.comission.includes(selectedRealEstateType)
            "
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4">Comission:*</span>
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="comission"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Comission end -->

        <!-- Multiple items add -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="selectedRealEstateType == 5"
        >
          <v-col cols="12" md="6" lg="6" xl="8" class="text-center">
            <v-btn
              elevation="0"
              class="orange white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialog = true"
            >
              Add Apartments
            </v-btn>
            <v-btn
              elevation="0"
              class="green white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialogVilla = true"
            >
              Add Villa/Towhnouses
            </v-btn>
            <v-btn
              elevation="0"
              class="grey white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialogCommerials = true"
            >
              Add Commercials
            </v-btn>
            <v-btn
              elevation="0"
              class="teal white--text rounded-xl text-capitalize font-weight-regular mx-1 my-1"
              @click="dialogPlots = true"
            >
              Add Plots
            </v-btn>
          </v-col>
        </v-row>
        <!-- Multiple items add end -->

        <!-- Aparts dialog -->
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialog = false"> Apply </v-btn>
              <v-toolbar-title>Add apartments</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <ApartmentsAddComponent :dialogState="dialog" />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Aparts dialog end -->

        <!-- Villa dialog -->
        <v-dialog
          v-model="dialogVilla"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialogVilla = false">
                Apply
              </v-btn>
              <v-toolbar-title>Add Vila/Townhouses</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <VillaAddComponent :dialogState="dialogVilla" />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Villa dialog end -->

        <!-- Commercials dialog -->
        <v-dialog
          v-model="dialogCommerials"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialogCommerials = false">
                Apply
              </v-btn>
              <v-toolbar-title>Add Commercials</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <CommercialsAddComponent :dialogState="dialogCommerials" />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Commercials dialog end -->

        <!-- Plots dialog -->
        <v-dialog
          v-model="dialogPlots"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn text color="white" @click="dialogPlots = false">
                Apply
              </v-btn>
              <v-toolbar-title>Add Plots</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <PlotsAddComponent :dialogState="dialogPlots" />
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Plots dialog end -->

        <!-- Post button -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-btn
              elevation="0"
              class="blue white--text rounded-xl text-capitalize font-weight-regular"
              @click="add()"
              >Post and ad</v-btn
            >
          </v-col>
        </v-row>
        <!-- Post button -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
import ApartmentsAddComponent from "./ApartmentsAddComponent";
import VillaAddComponent from "./VillaAddComponent.vue";
import CommercialsAddComponent from "./CommercialsAddComponent.vue";
import PlotsAddComponent from "./PlotsAddComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RealEstateItemAddComponent",
  components: {
    HeaderTemplate,
    ApartmentsAddComponent,
    VillaAddComponent,
    CommercialsAddComponent,
    PlotsAddComponent,
  },
  computed: {
    ...mapGetters({
      getActionStatus: "realEstate/GET_ACTION_STATUS",
      getItemApartments: "realEstate/GET_ITEM_APARTMENTS",
      getItemVilla: "realEstate/GET_ITEM_VILLA",
      getItemCommercials: "realEstate/GET_ITEM_COMMERCIALS",
      getItemPlots: "realEstate/GET_ITEM_PLOTS",
    }),
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    itemApartments: {
      get: function () {
        return this.getItemApartments;
      },
    },
    itemVilla: {
      get: function () {
        return this.getItemVilla;
      },
    },
    itemCommercials: {
      get: function () {
        return this.getItemCommercials;
      },
    },
    itemPlots: {
      get: function () {
        return this.getItemPlots;
      },
    },
  },
  data: () => ({
    propertiesReportType: {
      name: [1, 2, 3, 4, 5],
      developer: [1, 2, 3, 4, 5],
      developerRepresentative: [1, 2, 3, 4, 5],
      developerEmail: [1, 2, 3, 4, 5],
      developerPhone: [1, 2, 3, 4, 5],
      comission: [1, 2, 3, 4, 5],
      baths: [1, 2],
      beds: [1, 2],
      price: [1, 2, 3, 4],
      area: [4, 5],
      internalCoveredArea: [1, 2, 3],
      coveredVerandArea: [1, 2, 3],
      uncoveredVerandArea: [1, 2, 3],
      storeys: [1, 2, 3, 4],
      included: [1, 2, 3, 5],
      energyEff: [1, 2, 3],
      windowsOverlook: [1, 2, 3],
      constructionYear: [1, 2, 3, 5],
      reconstructionYear: [1, 2, 3, 5],
      yearOfDelivery: [1, 2, 3, 5],
      conditioning: [1, 2, 3],
      primary: [1, 2, 3],
      country: [1, 2, 3, 4, 5],
      city: [1, 2, 3, 4, 5],
      location: [1, 2, 3, 4, 5],
      distanceToSea: [1, 2, 3, 4, 5],
      description: [1, 2, 3, 4, 5],
      descriptionRu: [1, 2, 3, 4, 5],
      agent: [1, 2, 3, 4, 5],
      email: [1, 2, 3, 4, 5],
      phone: [1, 2, 3, 4, 5],
    },
    dealTypes: [{ id: 1, text: "Sale" }],
    selectedDealType: 1,
    realEstateTypes: [
      { id: 1, text: "Villa/Townhouse" },
      { id: 2, text: "Apartment" },
      { id: 3, text: "Commercial" },
      { id: 4, text: "Plot" },
      { id: 5, text: "Project", selected: false },
    ],
    selectedRealEstateType: 1,
    baths: 1,
    beds: 1,
    price: 0,
    area: 0,
    storeys: 1,
    description: null,
    descriptionRu: null,
    name: null,
    developer: null,
    developerRepresentative: null,
    developerEmail: null,
    developerPhone: null,
    comission: null,
    email: null,
    phone: null,
    currencyTypes: [
      {
        id: 2,
        code: "EUR",
        icon: "mdi-currency-eur",
      },
    ],
    selectedCurrencyType: 2,
    includedProperties: [
      { id: 1, name: "Swimming pool", selected: false },
      { id: 2, name: "Storage", selected: false },
      { id: 3, name: "Parking/Garage", selected: false },
      { id: 4, name: "Gym", selected: false },
      { id: 5, name: "Security 24/7", selected: false },
      { id: 6, name: "Beauty centers", selected: false },
      { id: 7, name: "Food court", selected: false },
      { id: 8, name: "Garden", selected: false },
      { id: 9, name: "Golf field ", selected: false },
    ],
    energyEfficiencies: [
      {
        id: 1,
        name: "Class A",
      },
      {
        id: 2,
        name: "Class B",
      },
      {
        id: 3,
        name: "Class C",
      },
      {
        id: 4,
        name: "Class D",
      },
      {
        id: 5,
        name: "Unspecified",
      },
    ],
    selectedEnergyEfficiency: {
      id: 5,
      name: "Unspecified",
    },
    windowsOverlooks: [
      { id: 1, name: "Sea/river view" },
      { id: 2, name: "Mountain view" },
      { id: 3, name: "City view" },
      { id: 4, name: "Park view" },
      { id: 5, name: "Nature view" },
      { id: 6, name: "Unspecified" },
    ],
    selectedWindowsOverlooks: { id: 6, name: "Unspecified" },
    costructionYear: null,
    reconstructionYear: null,
    yearOfDelivery: null,
    conditionings: [
      { id: 1, name: "A/C" },
      { id: 2, name: "Heating" },
      { id: 3, name: "Underfloor heating" },
      { id: 4, name: "Provision for A/C" },
      { id: 5, name: "Provision for heating systems" },
      { id: 6, name: "Provision for underfloor heating" },
    ],
    selectedConditionings: [
      { id: 1, name: "A/C" },
      { id: 2, name: "Heating" },
    ],
    primaries: [
      { id: 1, name: "New" },
      { id: 2, name: "Resale" },
    ],
    selectedPrimary: { id: 1, name: "New" },
    images: null,
    coords: {
      lat: 34.687014,
      lng: 33.036281,
    },
    location: {
      lat: 34.687014,
      lng: 33.036281,
    },
    agentName: null,
    countries: [{ id: 1, name: "Cyprus" }],
    selectedCountry: { id: 1, name: "Cyprus" },
    cities: [
      { id: 2, name: "Limassol", countryId: 1, lat: 34.687014, lng: 33.036281 },
      {
        id: 3,
        name: "Paraklisi",
        countryId: 1,
        lat: 34.743481,
        lng: 33.160301,
      },
      { id: 4, name: "Ypsonas", countryId: 1, lat: 34.688559, lng: 32.960859 },
      { id: 5, name: "Episkopi", countryId: 1, lat: 34.671618, lng: 32.901161 },
      { id: 6, name: "Paphos", countryId: 1, lat: 34.776681, lng: 32.422063 },
      { id: 7, name: "Polis", countryId: 1, lat: 35.035298, lng: 32.425804 },
      { id: 8, name: "Latchi", countryId: 1, lat: 35.038826, lng: 32.397815 },
      { id: 9, name: "Larnaca", countryId: 1, lat: 34.919188, lng: 33.619918 },
      { id: 10, name: "Zigi", countryId: 1, lat: 34.72918, lng: 33.338354 },
      { id: 11, name: "Mazotos", countryId: 1, lat: 34.804712, lng: 33.488645 },
      { id: 12, name: "Livadia", countryId: 1, lat: 34.951162, lng: 33.632111 },
      {
        id: 13,
        name: "Oroklini",
        countryId: 1,
        lat: 34.981244,
        lng: 33.656656,
      },
      { id: 14, name: "Pila", countryId: 1, lat: 35.013635, lng: 33.692081 },
      {
        id: 15,
        name: "Ayia Napa",
        countryId: 1,
        lat: 34.988939,
        lng: 33.999348,
      },
      {
        id: 16,
        name: "Protaras",
        countryId: 1,
        lat: 35.012158,
        lng: 34.056713,
      },
      {
        id: 17,
        name: "Paralimni",
        countryId: 1,
        lat: 35.038487,
        lng: 33.981577,
      },
      { id: 18, name: "Nicosia", countryId: 1, lat: 35.185917, lng: 33.382194 },
    ],
    selectedCity: {
      id: 2,
      name: "Limassol",
      countryId: 1,
      lat: 34.687014,
      lng: 33.036281,
    },
    distanceToSea: 0,
    previewImages: [],
    selectedImage: null,
    dialog: false,
    dialogVilla: false,
    dialogCommerials: false,
    internalCoveredArea: 0,
    coveredVerandArea: 0,
    uncoveredVerandArea: 0,
    dialogPlots: false,
  }),
  methods: {
    ...mapActions({
      addItemFromApi: "realEstate/ADD_ITEM_FROM_API",
      commitItemApartments: "realEstate/COMMIT_ITEM_APARTMENTS",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      generateSnackbar: "common/GENERATE_SNACKBAR",
    }),
    async add() {
      this.startRequest("addRealEstate");

      let formData = new FormData();
      formData.append("dealTypeId", this.selectedDealType);
      formData.append("realEstateTypeId", this.selectedRealEstateType);
      if (
        this.propertiesReportType.city.includes(this.selectedRealEstateType)
      ) {
        formData.append("regionId", this.selectedCity.id);
      }

      if (
        this.propertiesReportType.name.includes(this.selectedRealEstateType)
      ) {
        if (this.name == null || this.name == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Name field!",
          });
          return;
        }
        formData.append("name", this.name);
      }

      if (
        this.propertiesReportType.baths.includes(this.selectedRealEstateType)
      ) {
        formData.append("bathroomsQuantity", this.baths);
      }

      if (
        this.propertiesReportType.beds.includes(this.selectedRealEstateType)
      ) {
        formData.append("bedroomsQuantity", this.beds);
      }

      if (
        this.propertiesReportType.price.includes(this.selectedRealEstateType)
      ) {
        if (this.price == null || this.price == 0) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Price field!",
          });
          return;
        }
        formData.append("price", this.price);
        formData.append("currencyTypeId", this.selectedCurrencyType);
      }

      if (
        this.propertiesReportType.area.includes(this.selectedRealEstateType)
      ) {
        if (this.area == null || this.area == 0) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Area field!",
          });
          return;
        }
        formData.append("square", this.area);
      }

      if (
        this.propertiesReportType.internalCoveredArea.includes(
          this.selectedRealEstateType
        )
      ) {
        formData.append("internalCoveredArea", this.internalCoveredArea);
      }

      if (
        this.propertiesReportType.coveredVerandArea.includes(
          this.selectedRealEstateType
        )
      ) {
        formData.append("coveredVerandArea", this.coveredVerandArea);
      }

      if (
        this.propertiesReportType.uncoveredVerandArea.includes(
          this.selectedRealEstateType
        )
      ) {
        formData.append("uncoveredVerandArea", this.uncoveredVerandArea);
      }

      if (
        this.propertiesReportType.storeys.includes(this.selectedRealEstateType)
      ) {
        formData.append("storeys", this.storeys);
      }

      if (
        this.propertiesReportType.description.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.description == null || this.description == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Description field!",
          });
          return;
        }
        formData.append("description", this.description);
      }

      if (
        this.propertiesReportType.descriptionRu.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.descriptionRu == null || this.descriptionRu == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Description(Ru) field!",
          });
          return;
        }
        formData.append("descriptionRu", this.descriptionRu);
      }

      if (
        this.propertiesReportType.energyEff.includes(
          this.selectedRealEstateType
        )
      ) {
        formData.append("energyEfficiencyId", this.selectedEnergyEfficiency.id);
      }

      if (
        this.propertiesReportType.windowsOverlook.includes(
          this.selectedRealEstateType
        )
      ) {
        formData.append("windowsOverlookId", this.selectedWindowsOverlooks.id);
      }

      if (
        this.propertiesReportType.primary.includes(this.selectedRealEstateType)
      ) {
        formData.append("primaryId", this.selectedPrimary.id);
      }

      if (
        this.propertiesReportType.constructionYear.includes(
          this.selectedRealEstateType
        ) &&
        this.costructionYear != null
      ) {
        formData.append("costructionYear", this.costructionYear);
      }

      if (
        this.propertiesReportType.reconstructionYear.includes(
          this.selectedRealEstateType
        ) &&
        this.reconstructionYear != null
      ) {
        formData.append("reconstructionYear", this.reconstructionYear);
      }

      if (
        this.propertiesReportType.yearOfDelivery.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.yearOfDelivery == null || this.yearOfDelivery == 0) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Year of Delivery field!",
          });
          return;
        }
        formData.append("yearOfDelivery", this.yearOfDelivery);
      }

      if (
        this.propertiesReportType.agent.includes(this.selectedRealEstateType)
      ) {
        if (this.agentName == null || this.agentName == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Agent Name field!",
          });
          return;
        }
        formData.append("agentName", this.agentName);
      }

      if (
        this.propertiesReportType.email.includes(this.selectedRealEstateType)
      ) {
        if (this.email == null || this.email == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Agent Email field!",
          });
          return;
        }
        formData.append("agentEmail", this.email);
      }

      if (
        this.propertiesReportType.phone.includes(this.selectedRealEstateType)
      ) {
        if (this.phone == null || this.phone == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Agent Phone field!",
          });
          return;
        }
        formData.append("agentPhone", this.phone);
      }

      if (
        this.propertiesReportType.developer.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.developer == null || this.developer == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer field!",
          });
          return;
        }
        formData.append("developerName", this.developer);
      }

      if (
        this.propertiesReportType.developerRepresentative.includes(
          this.selectedRealEstateType
        )
      ) {
        if (
          this.developerRepresentative == null ||
          this.developerRepresentative == ""
        ) {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer Representative field!",
          });
          return;
        }
        formData.append(
          "developerRepresentative",
          this.developerRepresentative
        );
      }

      if (
        this.propertiesReportType.developerEmail.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.developerEmail == null || this.developerEmail == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer Email field!",
          });
          return;
        }
        formData.append("developerEmail", this.developerEmail);
      }

      if (
        this.propertiesReportType.developerPhone.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.developerPhone == null || this.developerPhone == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Developer Phone field!",
          });
          return;
        }
        formData.append("developerPhone", this.developerPhone);
      }

      if (
        this.propertiesReportType.comission.includes(
          this.selectedRealEstateType
        )
      ) {
        if (this.comission == null || this.comission == "") {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Fill Comission field!",
          });
          return;
        }
        formData.append("comission", this.comission);
      }

      if (
        this.propertiesReportType.distanceToSea.includes(
          this.selectedRealEstateType
        )
      ) {
        formData.append("distanceToSea", this.distanceToSea);
      }

      if (
        this.propertiesReportType.location.includes(this.selectedRealEstateType)
      ) {
        formData.append(
          "longitude",
          this.location != null ? this.location.lng.toFixed(10) : null
        );
        formData.append(
          "latitude",
          this.location != null ? this.location.lat.toFixed(10) : null
        );
      }

      if (
        this.propertiesReportType.conditioning.includes(
          this.selectedRealEstateType
        )
      ) {
        for (let item of this.selectedConditionings) {
          formData.append("conditionings", item.id);
        }
      }

      if (
        this.propertiesReportType.included.includes(this.selectedRealEstateType)
      ) {
        for (let item of this.includedProperties.filter((x) => x.selected)) {
          formData.append("includeProperties", item.id);
        }
      }

      if (this.previewImages != null) {
        for (let image of this.previewImages) {
          formData.append("files", image.file, image.file.name);
        }
      }

      await this.addApartments(formData);
      await this.addVilla(formData);
      await this.addCommercials(formData);
      await this.addPlots(formData);

      await this.addItemFromApi(formData);

      this.finishRequest({
        requestName: "addRealEstate",
        success: this.actionStatus != null,
        snackbarShow: true,
      });

      if (this.actionStatus != null) {
        var data = this.$router.resolve({
          path: "/account",
        });
        document.location.href = data.href;
      }
    },
    async addApartments(formData) {
      let aparts = [];
      if (this.itemApartments != null && this.itemApartments.length > 0) {
        aparts = this.itemApartments.map((element) => ({
          bathroomsQuantity: element.baths,
          bedroomsQuantity: element.beds,
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          internalCoveredArea: element.internalCoveredArea,
          coveredVerandArea: element.coveredVerandArea,
          uncoveredVerandArea: element.uncoveredVerandArea,
          storeys: element.storeys,
          energyEfficiencyId: element.selectedEnergyEfficiency.id,
          windowsOverlookId: element.selectedWindowsOverlooks.id,
          conditionings: element.selectedConditionings,
          primaryId: element.selectedPrimary.id,
          previewImages: element.previewImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("apartments[" + i + "].dealTypeId", 1);
          formData.append("apartments[" + i + "].realEstateTypeId", 2);
          formData.append(
            "apartments[" + i + "].bathroomsQuantity",
            aparts[i].bathroomsQuantity
          );
          formData.append(
            "apartments[" + i + "].bedroomsQuantity",
            aparts[i].bedroomsQuantity
          );
          formData.append("apartments[" + i + "].price", aparts[i].price);
          formData.append(
            "apartments[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append(
            "apartments[" + i + "].internalCoveredArea",
            aparts[i].internalCoveredArea
          );
          formData.append(
            "apartments[" + i + "].coveredVerandArea",
            aparts[i].coveredVerandArea
          );
          formData.append(
            "apartments[" + i + "].uncoveredVerandArea",
            aparts[i].uncoveredVerandArea
          );
          formData.append("apartments[" + i + "].storeys", aparts[i].storeys);
          formData.append(
            "apartments[" + i + "].energyEfficiencyId",
            aparts[i].energyEfficiencyId
          );
          formData.append(
            "apartments[" + i + "].windowsOverlookId",
            aparts[i].windowsOverlookId
          );
          for (let j = 0; j < aparts[i].conditionings.length; j++) {
            formData.append(
              "apartments[" + i + "].conditionings[" + j + "]",
              aparts[i].conditionings[j].id
            );
          }
          formData.append(
            "apartments[" + i + "].primaryId",
            aparts[i].primaryId
          );

          for (let j = 0; j < aparts[i].previewImages.length; j++) {
            const base64String = await this.createBase64Image(
              aparts[i].previewImages[j].file
            );
            formData.append(`apartments[${i}].innerFiles[${j}]`, base64String);
          }

          formData.append("apartments[" + i + "].name", aparts[i].name);
          formData.append(
            "apartments[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    async addVilla(formData) {
      let aparts = [];
      if (this.itemVilla != null && this.itemVilla.length > 0) {
        aparts = this.itemVilla.map((element) => ({
          bathroomsQuantity: element.baths,
          bedroomsQuantity: element.beds,
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          internalCoveredArea: element.internalCoveredArea,
          coveredVerandArea: element.coveredVerandArea,
          uncoveredVerandArea: element.uncoveredVerandArea,
          storeys: element.storeys,
          energyEfficiencyId: element.selectedEnergyEfficiency.id,
          windowsOverlookId: element.selectedWindowsOverlooks.id,
          conditionings: element.selectedConditionings,
          primaryId: element.selectedPrimary.id,
          previewImages: element.previewImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("villa[" + i + "].dealTypeId", 1);
          formData.append("villa[" + i + "].realEstateTypeId", 1);
          formData.append(
            "villa[" + i + "].bathroomsQuantity",
            aparts[i].bathroomsQuantity
          );
          formData.append(
            "villa[" + i + "].bedroomsQuantity",
            aparts[i].bedroomsQuantity
          );
          formData.append("villa[" + i + "].price", aparts[i].price);
          formData.append(
            "villa[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append(
            "villa[" + i + "].internalCoveredArea",
            aparts[i].internalCoveredArea
          );
          formData.append(
            "villa[" + i + "].coveredVerandArea",
            aparts[i].coveredVerandArea
          );
          formData.append(
            "villa[" + i + "].uncoveredVerandArea",
            aparts[i].uncoveredVerandArea
          );
          formData.append("villa[" + i + "].storeys", aparts[i].storeys);
          formData.append(
            "villa[" + i + "].energyEfficiencyId",
            aparts[i].energyEfficiencyId
          );
          formData.append(
            "villa[" + i + "].windowsOverlookId",
            aparts[i].windowsOverlookId
          );
          for (let j = 0; j < aparts[i].conditionings.length; j++) {
            formData.append(
              "villa[" + i + "].conditionings[" + j + "]",
              aparts[i].conditionings[j].id
            );
          }
          formData.append("villa[" + i + "].primaryId", aparts[i].primaryId);

          for (let j = 0; j < aparts[i].previewImages.length; j++) {
            const base64String = await this.createBase64Image(
              aparts[i].previewImages[j].file
            );
            formData.append(`villa[${i}].innerFiles[${j}]`, base64String);
          }

          formData.append("villa[" + i + "].name", aparts[i].name);
          formData.append(
            "villa[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    async addCommercials(formData) {
      let aparts = [];
      if (this.itemCommercials != null && this.itemCommercials.length > 0) {
        aparts = this.itemCommercials.map((element) => ({
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          internalCoveredArea: element.internalCoveredArea,
          coveredVerandArea: element.coveredVerandArea,
          uncoveredVerandArea: element.uncoveredVerandArea,
          storeys: element.storeys,
          energyEfficiencyId: element.selectedEnergyEfficiency.id,
          windowsOverlookId: element.selectedWindowsOverlooks.id,
          conditionings: element.selectedConditionings,
          primaryId: element.selectedPrimary.id,
          previewImages: element.previewImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("commercials[" + i + "].dealTypeId", 1);
          formData.append("commercials[" + i + "].realEstateTypeId", 3);
          formData.append("commercials[" + i + "].price", aparts[i].price);
          formData.append(
            "commercials[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append(
            "commercials[" + i + "].internalCoveredArea",
            aparts[i].internalCoveredArea
          );
          formData.append(
            "commercials[" + i + "].coveredVerandArea",
            aparts[i].coveredVerandArea
          );
          formData.append(
            "commercials[" + i + "].uncoveredVerandArea",
            aparts[i].uncoveredVerandArea
          );
          formData.append("commercials[" + i + "].storeys", aparts[i].storeys);
          formData.append(
            "commercials[" + i + "].energyEfficiencyId",
            aparts[i].energyEfficiencyId
          );
          formData.append(
            "commercials[" + i + "].windowsOverlookId",
            aparts[i].windowsOverlookId
          );
          for (let j = 0; j < aparts[i].conditionings.length; j++) {
            formData.append(
              "commercials[" + i + "].conditionings[" + j + "]",
              aparts[i].conditionings[j].id
            );
          }
          formData.append(
            "commercials[" + i + "].primaryId",
            aparts[i].primaryId
          );

          for (let j = 0; j < aparts[i].previewImages.length; j++) {
            const base64String = await this.createBase64Image(
              aparts[i].previewImages[j].file
            );
            formData.append(`commercials[${i}].innerFiles[${j}]`, base64String);
          }

          formData.append("commercials[" + i + "].name", aparts[i].name);
          formData.append(
            "commercials[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    async addPlots(formData) {
      let aparts = [];
      if (this.itemPlots != null && this.itemPlots.length > 0) {
        aparts = this.itemPlots.map((element) => ({
          price: element.price,
          currencyTypeId: element.selectedCurrencyType,
          square: element.area,
          storeys: element.storeys,
          previewImages: element.previewImages,
          name: element.name,
          description: element.description,
        }));

        for (let i = 0; i < aparts.length; i++) {
          formData.append("plots[" + i + "].dealTypeId", 1);
          formData.append("plots[" + i + "].realEstateTypeId", 4);
          formData.append("plots[" + i + "].price", aparts[i].price);
          formData.append(
            "plots[" + i + "].currencyTypeId",
            aparts[i].currencyTypeId
          );
          formData.append("plots[" + i + "].square", aparts[i].square);
          formData.append("plots[" + i + "].storeys", aparts[i].storeys);

          for (let j = 0; j < aparts[i].previewImages.length; j++) {
            const base64String = await this.createBase64Image(
              aparts[i].previewImages[j].file
            );
            formData.append(`plots[${i}].innerFiles[${j}]`, base64String);
          }

          formData.append("plots[" + i + "].name", aparts[i].name);
          formData.append(
            "plots[" + i + "].description",
            aparts[i].description
          );
        }
      }
    },
    updateLocation(location) {
      this.location = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    createImage(file, callback) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageUrl = e.target.result;
        callback(imageUrl);
      };

      reader.readAsDataURL(file);
    },
    selectImage(i) {
      this.selectedImage = i;
    },
    deleteImage(i) {
      this.previewImages.splice(i, 1);
    },
    createBase64Image(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
  },
  watch: {
    images: function () {
      this.images.forEach((element) => {
        this.createImage(element, (imageUrl) => {
          if (
            !this.previewImages
              .map((x) => x.file)
              .some((x) => x.name == element.name)
          ) {
            this.previewImages.push({
              src: imageUrl,
              file: element,
            });
          }
        });
      });
    },
    selectedCity: function () {
      this.coords = {
        lat: this.selectedCity.lat,
        lng: this.selectedCity.lng,
      };
      this.location = {
        lat: this.selectedCity.lat,
        lng: this.selectedCity.lng,
      };
    },
  },
};
</script>