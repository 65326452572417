import { GetAllLanguages } from '../../services/languageService';

export default {
    GENERATE_SNACKBAR({ commit }, payload) {
        commit('SET_SNACKBAR', payload);
    },
    COMMIT_TITLE({ commit }, payload) {
        commit('SET_TITLE', payload);
    },
    async GET_LANGUAGES_FROM_API({ commit }) {
        await GetAllLanguages().then(
            ({
                data, status
            }) => {
                if (status === 200) {
                    commit('SET_LANGUAGES', data);
                }
            }
        )
            .catch(() => {
                commit('SET_LANGUAGES', { succeeded: false });
            })
    },
    COMMIT_SELECTED_LANGUAGE({ commit }, payload) {
        commit('SET_SELECTED_LANGUAGE', payload);
    },
    COMMIT_STRING_VALUES({ commit }, payload) {
        commit('SET_STRING_VALUES', payload);
    },
}