<template>
  <v-app>
    <v-main>
      <router-view :key="$route.fullPath" />
      <Snackbar />
      <PrivacyStatement v-if="!privacyAccepted" />
    </v-main>
    <v-row no-gutters class="mt-8">
      <v-col cols="12">
        <v-img src="./assets/images/footer.png">
          <v-card
            color="transparent"
            elevation="0"
            height="100%"
            class="d-flex justify-center align-end py-6"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="10" lg="8" xl="8">
                <v-row>
                  <v-col cols="12">
                    <v-toolbar elevation="0" color="transparent">
                      <v-toolbar-title>
                        <img height="64" src="./assets/images/logo.png"
                      /></v-toolbar-title>
                      <v-spacer />
                      <v-btn
                        v-for="(item, index) in menuItems"
                        :key="index"
                        text
                        color="white"
                        class="font-weight-regular text-caption text-md-body-1 text-md-button text-xl-button"
                        :to="item.link"
                      >
                        {{
                          selectedLanguage.id == 1 ? item.titleRu : item.titleEn
                        }}
                      </v-btn>
                      <v-spacer />
                      <v-btn text small elevation="0">
                        <v-icon color="white" @click="goToTelegram()"
                          >mdi-send-circle-outline</v-icon
                        >
                      </v-btn>
                      <v-btn text small elevation="0">
                        <v-icon color="white" @click="goToInstagram()"
                          >mdi-instagram</v-icon
                        >
                      </v-btn>
                    </v-toolbar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <span class="white--text"
                      >© 2024 Valstand. All rights reserved</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-img>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Snackbar from "./components/Common/Snackbar.vue";
import PrivacyStatement from "./components/Common/PrivacyStatement.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",

  components: { Snackbar, PrivacyStatement },

  computed: {
    ...mapGetters({
      getToken: "auth/GET_TOKEN",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },

  data: () => ({
    privacyAccepted: false,
    menuItems: [
      {
        titleEn: "News",
        titleRu: "Новости",
        link: "/news",
      },
      {
        titleEn: "Home",
        titleRu: "Главная",
        link: "/home",
      },
      {
        titleEn: "Real Estate",
        titleRu: "Поиск Объектов",
        link: "/real-estate",
      },
      {
        titleEn: "Calculator",
        titleRu: "Калькулятор",
        link: "/calculator",
      },
    ],
  }),
  methods: {
    ...mapActions({
      checkFromApi: "auth/CHECK_FROM_API",
      getRealEstateInfoFromApi: "realEstate/GET_INFO_FROM_API",
      getLanguagesFromApi: "common/GET_LANGUAGES_FROM_API",
    }),
    goToInstagram() {
      window.open("https://www.instagram.com/valstand.cyprus", "_blank");
    },
    goToTelegram() {
      window.open("https://t.me/Sulu_Fazylova", "_blank");
    },
  },
  async created() {
    this.privacyAccepted = localStorage.getItem("privacyAccepted") === "true";
    await this.getLanguagesFromApi();
    await this.getRealEstateInfoFromApi();
  },
};
</script>