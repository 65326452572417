export default {
    GET_ITEM(state) {
        return state.item;
    },
    GET_ITEMS(state) {
        return state.items;
    },
    GET_SELECTED_PARAMS(state) {
        return state.selectedParams;
    },
    GET_ACTION_STATUS(state) {
        return state.actionStatus;
    },
    GET_INFO(state) {
        return state.info;
    },
    GET_DEVELOPERS(state) {
        return state.developers;
    },
    GET_PROJECTS(state) {
        return state.projects;
    },
    GET_CODES(state) {
        return state.codes;
    },
    GET_AGENT_NAMES(state) {
        return state.agentNames;
    },
    GET_REAL_ESTATES(state) {
        return state.realEstates;
    },
    GET_DISABLED_ITEMS(state) {
        return state.disabledItems;
    },
    GET_ITEM_APARTMENTS(state) {
        return state.itemApartments;
    },
    GET_ITEM_VILLA(state) {
        return state.itemVilla;
    },
    GET_ITEM_COMMERCIALS(state) {
        return state.itemCommercials;
    },
    GET_LISTING_LINK(state) {
        return state.listingLink;
    },
    GET_LISTING_PROPERTIES(state) {
        return state.listingProperties;
    },
    GET_ITEM_PLOTS(state) {
        return state.itemPlots;
    },
    GET_SELECTED_ANALYSIS_CITY(state) {
        return state.selectedAnalysisCity;
    },
    GET_RECENT_ITEMS(state) {
        return state.recentItems;
    },
}