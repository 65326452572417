<template>
  <div>
    <Header />
    <List />
    <!-- <v-row v-if="Object.keys(items).length > 0">
      <v-col cols="12">
        <v-card max-width="60%" elevation="0" class="mx-auto">
          <v-pagination
            v-model="pageNumber"
            :length="items.totalPages"
            circle
          ></v-pagination>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
import Header from "../../components/RealEstate/RealEstateHeaderComponent.vue";
import List from "../../components/RealEstate/RealEstateListComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RealEstateView",
  components: {
    Header,
    List,
  },
  computed: {
    ...mapGetters({
      getItems: "realEstate/GET_ITEMS",
      getSelectedSearchParams: "realEstate/GET_SELECTED_PARAMS",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    params: {
      get: function () {
        return this.getSelectedSearchParams;
      },
    },
  },
  data: () => ({
    pageNumber: 1,
  }),
  methods: {
    ...mapActions({
      searchFromApi: "realEstate/SEARCH_FROM_API",
    }),
    getItemsInner(pageNumber = null) {
      if (pageNumber != null) this.params.pageNumber = this.pageNumber;

      this.searchFromApi({
        regionId: this.params.regionId,
        pageNumber:
          pageNumber == null ? this.params.pageNumber : this.pageNumber,
        pageSize: this.params.pageSize,
        minPrice: this.params.minPrice,
        maxPrice: this.params.maxPrice,
      });
    },
  },
  watch: {
    pageNumber: function () {
      this.getItemsInner(this.pageNumber);
    },
  },
  mounted() {
    document.title = "Real Estate - Valstand";
  },
};
</script>