import index from '../api/index'

export const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetById(query))
    })
}

export const Search = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.Search(query))
    })
}

export const GetByAdministratorId = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetByAdministratorId(query))
    })
}

export const Add = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.Add(query))
    })
}

export const Update = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.Update(query))
    })
}

export const Delete = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.Delete(query))
    })
}

export const Listing = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.Listing(query))
    })
}

export const GetInfo = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetInfo())
    })
}

export const GetDevelopers = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetDevelopers())
    })
}

export const GetProjects = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetProjects())
    })
}

export const GetProjectsCodes = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetProjectsCodes())
    })
}

export const GetAgentNames = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetAgentNames())
    })
}

export const GetRealEstates = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetRealEstates())
    })
}

export const AddListingLink = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.AddListingLink(query))
    })
}

export const GetListingProperties = (query) => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetListingProperties(query))
    })
}

export const GetRecent = () => {
    return new Promise((resolve) => {
        resolve(index.realEstate.GetRecent())
    })
}