<template>
  <v-card elevation="0">
    <v-row v-if="isHead || $vuetify.breakpoint.smAndDown">
      <v-col cols="12">
        <v-img :src="logo" height="30vh" class="rounded-xl text-right">
          <v-btn
            elevation="0"
            fab
            x-small
            color="white ma-2"
            v-if="editShow"
            :to="'/news-update/' + id"
          >
            <v-icon color="blue">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            fab
            x-small
            color="white ma-2"
            v-if="editShow"
            @click="deleteItem()"
          >
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :cols="isHead || $vuetify.breakpoint.smAndDown ? 12 : 7">
        <v-row>
          <v-col cols="12">
            <span class="font-weight-medium text-h5 text-capitalize">{{
              title
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-regular text-subtitle-2">{{ body }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-regular text-caption">{{
              new Date(date).toLocaleDateString()
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5" v-if="!isHead && $vuetify.breakpoint.mdAndUp">
        <v-img :src="logo" height="20vh" class="rounded-xl text-right">
          <v-btn
            elevation="0"
            fab
            x-small
            color="white ma-2"
            v-if="editShow"
            :to="'/news-update/' + id"
          >
            <v-icon color="blue">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            fab
            x-small
            color="white ma-2"
            v-if="editShow"
            @click="deleteItem()"
          >
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </v-img>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewsItemTemplate",
  props: {
    logo: String,
    title: String,
    body: String,
    date: String,
    isHead: Boolean,
    editShow: Boolean,
    id: Number,
  },
  components: {},
  computed: {
    ...mapGetters({
      getActionStatus: "news/GET_ACTION_STATUS",
      getItems: "news/GET_ITEMS",
    }),
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    items: {
      get: function () {
        return this.getItems;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      deleteItemFromApi: "news/DELETE_ITEM_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
    }),
    async deleteItem() {
      if (confirm("Are you sure you want to delete the news?")) {
        this.startRequest("deleteNews");

        await this.deleteItemFromApi({
          id: this.id,
        });

        if (this.actionStatus != null) {
          var itemIndex = this.items.data.findIndex((x) => x.id == this.id);
          if (itemIndex != -1) {
            this.items.data.splice(itemIndex, 1);
          }
        }

        this.finishRequest({
          requestName: "deleteNews",
          success: this.actionStatus != null,
          snackbarShow: true,
        });
      }
    },
  },
};
</script>