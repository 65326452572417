<template>
  <div>
    <Header />
    <Filterr />
    <List />
  </div>
</template>
<script>
import Header from "../../components/News/NewsHeaderComponent.vue";
import List from "../../components/RealEstate/RealEstateListComponent.vue";
import Filterr from "../../components/RealEstate/RealEstateListFilterComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RealEstateAdministratorItemsView",
  components: {
    Header,
    List,
    Filterr,
  },
  computed: {
    ...mapGetters({
      getItems: "realEstate/GET_ITEMS",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
  },
  data: () => ({
    pageNumber: 1,
  }),
  methods: {
    ...mapActions({
      getByAdministratorIdFromApi:
        "realEstate/GET_BY_ADMINISTRATOR_ID_FROM_API",
      getDevelopersFromApi: "realEstate/GET_DEVELOPERS_FROM_API",
      getCodesFromApi: "realEstate/GET_CODES_FROM_API",
      getAgentNamesFromApi: "realEstate/GET_AGENT_NAMES_FROM_API",
      getProjectsFromApi: "realEstate/GET_PROJECTS_FROM_API",
    }),
  },
  mounted() {
    document.title = "Real Estate - Valstand";
  },
  async created() {
    await this.getDevelopersFromApi();
    await this.getCodesFromApi();
    await this.getProjectsFromApi();
    await this.getAgentNamesFromApi();
    await this.getByAdministratorIdFromApi({
      codes: [],
      types: [],
      names: [],
      developers: [],
      agentNames: [],
    });
  },
};
</script>