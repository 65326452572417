export default {
    item: {},
    items: [],
    selectedParams: {},
    actionStatus: null,
    info: null,
    developers: [],
    projects: [],
    codes: [],
    agentNames: [],
    realEstates: [],
    disabledItems: [],
    itemApartments: [],
    itemVilla: [],
    itemCommercials: [],
    listingLink: null,
    listingProperties: null,
    itemPlots: [],
    selectedAnalysisCity: null,
    recentItems: []
}