import state from './regionState';
import getters from './regionGetters';
import mutations from './regionMutations';
import actions from './regionActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}