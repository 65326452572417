<template>
  <div>
    <Header />
    <div v-if="items != null && items.data != null">
      <Items />
      <v-row>
        <v-col cols="12">
          <v-card max-width="60%" elevation="0" class="mx-auto">
            <v-pagination
              v-model="pageNumber"
              :length="items.totalPages"
              circle
            ></v-pagination>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="10" lg="8" xl="8">
          <v-row>
            <v-col cols="12" md="5" lg="5" xl="5">
              <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="image, article"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="7" lg="7" xl="7">
              <v-row>
                <v-col cols="12">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="image, article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="image, article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Header from "../../components/News/NewsHeaderComponent.vue";
import Items from "../../components/News/NewsItemsComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewsView",
  components: {
    Header,
    Items,
  },
  computed: {
    ...mapGetters({
      getItems: "news/GET_ITEMS",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
  },
  data: () => ({
    pageNumber: 1,
    pageSize: 3,
  }),
  methods: {
    ...mapActions({
      getItemsFromApi: "news/GET_ALL_FROM_API",
    }),
    async getItemsInner() {
      await this.getItemsFromApi({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
  },
  watch: {
    pageNumber: function () {
      this.getItemsInner();
    },
  },
  mounted() {
    document.title = "News - Valstand";
  },
  async created() {
    await this.getItemsInner();
  },
};
</script>