import state from './commonState';
import getters from './commonGetters';
import mutations from './commonMutations';
import actions from './commonActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}