<template>
  <div>
    <Header />
    <Calculator />
  </div>
</template>
<script>
import Header from "../../components/News/NewsHeaderComponent.vue";
import Calculator from "../../components/Calculator/CalculatorComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CalculatorView",
  components: {
    Header,
    Calculator,
  },
  computed: {
    ...mapGetters({}),
  },
  data: () => ({}),
  methods: {
    ...mapActions({}),
  },
  mounted() {
    document.title = "Calculator - Valstand";
  },
  async created() {},
};
</script>