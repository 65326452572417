import { GetToken, Check } from '../../services/authService';

export default {
    async GET_TOKEN_FROM_API({ commit }, payload) {
        await GetToken(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_TOKEN', data);
            }
        })
            .catch(() => {
                commit('SET_TOKEN', null);
            })
    },
    async CHECK_FROM_API() {
        await Check()
            .then()
            .catch(() => { })
    },
}