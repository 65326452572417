<template>
  <v-row class="d-flex justify-center align-center mt-2">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-start">
            <span
              class="text-h6 text-md-h4 text-lg-h4 text-xl-h4 text-uppercase font-weight-medium pa-4"
              >{{
                selectedLanguage.id == 1 ? "Калькулятор" : "Calculator"
              }}</span
            >
            <v-tooltip bottom max-width="20%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small fab text color="black" v-bind="attrs" v-on="on">
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  selectedLanguage.id == 1
                    ? "Оцените потенциал вашей недвижимости. Введите необходимые данные и получите результат менее чем за секунду."
                    : "Discover the target property's potential. Simply input your data and let us provide you with an accurate estimate inseconds."
                }}
              </span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="7" lg="7" xl="7">
          <v-card
            elevation="0"
            class="grey lighten-4 black--text rounded-xl pa-4 mx-4"
            height="100%"
          >
            <v-row>
              <v-col cols="12" md="12" lg="12" xl="12">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Годовая выручка"
                        : "Gross Annual Income"
                    }}
                  </span>
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "Общегодовой доход от сдачи в аренду до вычета любых расходов."
                          : "This is the total income from property rentals before any expenses are deducted, accumulated over one year."
                      }}</span
                    >
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <v-text-field
                    v-model="gaIncome"
                    dense
                    outlined
                    hide-details
                    class="rounded-lg"
                    type="number"
                    :rules="[decimalValidation]"
                    clearable
                    :min="0"
                    @wheel.stop.prevent
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="6" xl="6">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Услуги по управлению недвижимостью"
                        : "Property Management Fees"
                    }}</span
                  >
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "Сервисный сбор за услуги по управлению недвижимостью. Исчисляется в процентах от общегодового дохода от аренды в обмен на ежедневное управление объектом недвижимости от имени собственника. "
                          : "These are charges levied by service companies as a percentage of the total rental income for overseeing the day-to-day operations of a property."
                      }}
                    </span>
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <v-select
                    v-model="selectedPropertyManagement"
                    :items="propertyManagements"
                    item-text="text"
                    item-value="value"
                    dense
                    outlined
                    hide-details
                    class="rounded-lg"
                    return-object
                  >
                  </v-select>
                </div>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Коммунальные услуги и тех. обслуживание"
                        : "Utilities and Maintenance"
                    }}
                  </span>
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "В эту категорию входят ежегодные расходы на электроэнергию, воду и другие коммунальные услуги. Также учитываются ежегодные расходы на уборку и базовое содержание объекта недвижимости. "
                          : "This category includes the yearly costs for electricity, water, and other communal services, as well as routine cleaning and upkeep expenses."
                      }}
                    </span>
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <v-text-field
                    v-model="waterElectricity"
                    dense
                    outlined
                    hide-details
                    class="rounded-lg"
                    type="number"
                    :min="0"
                    @wheel.stop.prevent
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8" lg="8" xl="8">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Прочие расходы"
                        : "Other Expenses"
                    }}
                  </span>
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "Эта категория включает прочие операционные расходы за год, такие как страховые взносы, налог на имущество и прочее. Эта категрия не включает подоходный налог, платежи по договорам займа, расходы на капитальный ремонт, общий износ и амортизацию недвижимости."
                          : "This covers all additional operational costs incurred throughout the year, such as insurance premiums and property taxes, but does not include income tax, loan principal and interest, capital improvements, or depreciation and amortization."
                      }}
                    </span>
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <v-text-field
                    v-model="other"
                    dense
                    outlined
                    hide-details
                    class="rounded-lg"
                    type="number"
                    :min="0"
                    @wheel.stop.prevent
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-card
            elevation="0"
            class="blue white--text rounded-xl pa-8 mx-4"
            height="100%"
          >
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Чистая операционная прибыль"
                        : "Net Operating Income"
                    }}
                  </span>
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "Операционная прибыль от сдачи в аренду после вычета базовых расходов на эксплуатацию имущества, но без учета налога на прибыль, платежей по договорам займа, расходов на капитальный ремонт, общего износа и амортизации недвижимости."
                          : "This is the profit generated from property operations after deducting expenses for maintenance and similar operational costs, but before accounting for income tax,loan payments, capital investments, depreciation, and amortization"
                      }}

                      .</span
                    >
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <span class="white--text text-h6">
                    {{ (noIncome == null ? 0 : noIncome) | formatNumber }}
                  </span>
                  <v-divider />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Доходность"
                        : "Capitalization Rate"
                    }}
                  </span>
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "Ожидаемый уровень доходности, который отражает меру потенциального дохода от недвижимости по отношению к ее рыночной стоимости. Рассчитывается как отношение чистого операционного дохода за год к текущей рыночной стоимости недвижимости."
                          : "This rate, often referred to as “cap rate”, indicates the expected return or yield necessary to meet your specific investment goals and objectives. It’s a measure of a property’s income potential relative to its market value, calculated by dividing the net operating income by the property’s current market price."
                      }}
                    </span>
                  </v-tooltip>
                </div>
                <div class="my-2 d-flex">
                  <v-text-field
                    class="text-h6 text-white-tf"
                    v-model="minCapRate"
                    hide-details
                    dense
                    type="number"
                    color="white"
                    :rules="[decimalValidation]"
                    :min="0"
                    @wheel.stop.prevent
                  >
                  </v-text-field>
                  <v-icon color="white">mdi-percent-outline</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="font-weight-regular text-body-2">
                    {{
                      selectedLanguage.id == 1
                        ? "Целевая стоимость"
                        : "Target Property Price"
                    }}
                  </span>
                  <v-tooltip bottom max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        text
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{
                        selectedLanguage.id == 1
                          ? "Целевая стоимость недвижимости для достижения заданного уровня доходности."
                          : "This is the property price projection needed to reach your desired profitability."
                      }}</span
                    >
                  </v-tooltip>
                </div>
                <div class="my-2">
                  <span class="white--text text-h6">
                    {{ (ppCap == null ? 0 : ppCap) | formatNumber }}
                  </span>
                  <v-divider />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="ma-4">
            <span class="text-h6" style="font-weight: 400">
              {{
                selectedLanguage.id == 1
                  ? "Если у вас есть какие-либо вопросы к полученным результатам, либо к вводимым данным, вы всегда можете связаться с нами через Instagram или Telegram (см. ссылки в нижнем правом углу страницы). Мы будем рады помочь вам."
                  : "Should you have any questions regarding the results or any of the inputs, please do not hesitate to contact us via Instagram, or Telegram (see the links below to the right). We're here to assist you."
              }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CalculatorComponent",
  components: {},
  computed: {
    ...mapGetters({
      getItem: "calculator/GET_ITEM",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    cities: [
      {
        id: 2,
        name: "Limassol",
      },
    ],
    gaIncome: 0,
    propertyManagements: [
      { text: "0%", value: 0 },
      { text: "5%", value: 5 },
      { text: "10%", value: 10 },
      { text: "15%", value: 15 },
      { text: "20%", value: 20 },
      { text: "25%", value: 25 },
      { text: "30%", value: 30 },
      { text: "35%", value: 35 },
      { text: "40%", value: 40 },
      { text: "45%", value: 45 },
      { text: "50%", value: 50 },
    ],
    selectedPropertyManagement: { text: "0%", value: 0 },
    waterElectricity: 0,
    other: 0,
    noIncome: null,
    ppCap: null,
    minCapRate: 5,
  }),
  methods: {
    ...mapActions({
      getFromApi: "calculator/GET_FROM_API",
    }),
    decimalValidation(value) {
      if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
        return true;
      }
      return false;
    },
    calculate() {
      let pm = this.gaIncome * (this.selectedPropertyManagement.value / 100);
      this.noIncome = (
        this.gaIncome -
        pm -
        this.waterElectricity -
        this.other
      ).toFixed(0);
      this.ppCap = (this.noIncome / (this.minCapRate / 100)).toFixed(0);
    },
    initFields() {
      this.gaIncomeLoaded = false;
      this.gaIncome = 0;
      this.selectedPropertyManagement = { text: "5%", value: 5 };
      this.waterElectricity = 0;
      this.other = 0;
      this.noIncome = null;
      this.ppCap = null;
      this.minCapRate = 5;
    },
  },
  watch: {
    gaIncome: function () {
      this.calculate();
    },
    selectedPropertyManagement: function () {
      this.calculate();
    },
    waterElectricity: function () {
      this.calculate();
    },
    other: function () {
      this.calculate();
    },
    minCapRate: function () {
      this.calculate();
    },
  },
};
</script>
<style>
.text-white-tf input {
  color: white !important;
}
</style>