<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <v-col cols="12">
          <span
            class="text-h6 text-md-h4 text-lg-h4 text-xl-h4 text-uppercase font-weight-medium"
            >{{ selectedLanguage.id == 1 ? "Новости" : "News" }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" lg="5" xl="5">
          <NewsItemTemplate
            v-if="items.data[0] != undefined"
            :logo="items.data[0].imageLink"
            :title="
              selectedLanguage.id == 1
                ? items.data[0].titleRu
                : items.data[0].title
            "
            :body="
              selectedLanguage.id == 1
                ? items.data[0].descriptionRu
                : items.data[0].description
            "
            :date="items.data[0].createdDate"
            :isHead="true"
            :editShow="token != null"
            :id="items.data[0].id"
          />
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7">
          <v-row>
            <v-col cols="12">
              <NewsItemTemplate
                v-if="items.data[1] != undefined"
                :logo="items.data[1].imageLink"
                :title="
                  selectedLanguage.id == 1
                    ? items.data[1].titleRu
                    : items.data[1].title
                "
                :body="
                  selectedLanguage.id == 1
                    ? items.data[1].descriptionRu
                    : items.data[1].description
                "
                :date="items.data[1].createdDate"
                :isHead="false"
                :editShow="token != null"
                :id="items.data[1].id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <NewsItemTemplate
                v-if="items.data[2] != undefined"
                :logo="items.data[2].imageLink"
                :title="
                  selectedLanguage.id == 1
                    ? items.data[2].titleRu
                    : items.data[2].title
                "
                :body="
                  selectedLanguage.id == 1
                    ? items.data[2].descriptionRu
                    : items.data[2].description
                "
                :date="items.data[2].createdDate"
                :isHead="false"
                :editShow="token != null"
                :id="items.data[2].id"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import NewsItemTemplate from "../Templates/NewsItemTemplate.vue";
import { mapGetters } from "vuex";
export default {
  name: "NewsItemsComponent",
  components: {
    NewsItemTemplate,
  },
  computed: {
    ...mapGetters({
      getItems: "news/GET_ITEMS",
      getToken: "auth/GET_TOKEN",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    token: {
      get: function () {
        return this.getToken;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {},
};
</script>