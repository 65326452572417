<template>
  <v-row class="d-flex justify-center align-center mt-2">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
          xl="4"
          v-for="item in items.data"
          :key="item.id"
        >
          <AdItemTemplate
            :logo="item.imageLink"
            :name="item.name"
            :location="item.region.name"
            :price="item.price"
            :beds="item.bedroomsQuantity"
            :baths="item.bathroomsQuantity"
            :to="`/real-estate/${item.id}`"
            :editShow="token != null"
            :id="item.id"
            :codeName="item.code"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import AdItemTemplate from "../Templates/AdItemTemplate.vue";
import { mapGetters } from "vuex";
export default {
  name: "RealEstateListComponent",
  components: { AdItemTemplate },
  computed: {
    ...mapGetters({
      getItems: "realEstate/GET_ITEMS",
      getToken: "auth/GET_TOKEN",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    token: {
      get: function () {
        return this.getToken;
      },
    },
  },
  data: () => ({}),
};
</script>