<template>
  <div>
    <Add />
  </div>
</template>
<script>
import Add from "../../components/RealEstate/RealEstateItemAddComponent.vue";
export default {
  name: "RealEstateItemAddView",
  components: {
    Add,
  },
  data: () => ({}),
  methods: {},
  mounted() {
    document.title = "Real Estate - Valstand";
  },
  async created() {},
};
</script>