export default function (instance) {
    return {
        Add(payload) {
            return instance.post('/News/Add', payload);
        },
        GetAll(query) {
            return instance.get('/News/GetAll', { params: query });
        },
        Update(payload) {
            return instance.put('/News/Update', payload);
        },
        GetById(query) {
            return instance.get('/News/GetById', { params: query });
        },
        Delete(query) {
            return instance.delete('/News/Delete', { params: query });
        },
    }
}
