<template>
  <div>
    <Header />
    <Analysis class="mt-8" />
    <RecentItems class="mt-8" />
    <Find class="mt-8" />
  </div>
</template>
<script>
import Header from "../../components/Home/HomeHeaderComponent.vue";
import Analysis from "../../components/Home/HomeAnalysisComponent.vue";
import RecentItems from "../../components/Home/HomeRecentItemsComponent.vue";
import Find from "../../components/Home/HomeFindComponent.vue";
export default {
  name: "HomeView",
  components: {
    Header,
    Analysis,
    RecentItems,
    Find,
  },
  data: () => ({}),
  mounted() {
    document.title = "Home - Valstand";
  },
};
</script>