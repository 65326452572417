import index from '../api/index'

export const GetAllGrouped = () => {
    return new Promise((resolve) => {
        resolve(index.region.GetAllGrouped())
    })
}

export const Search = (query) => {
    return new Promise((resolve) => {
        resolve(index.region.Search(query))
    })
}