import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import Login from "../views/Auth/Login"

import AccountInfo from "../views/Account/AccountInfo"

import Home from "../views/Home/Home"

import News from "../views/News/News"
import NewsAdd from "../views/News/NewsAdd"
import NewsUpdate from "../views/News/NewsUpdate"

import RealEstate from "../views/RealEstate/RealEstate"
import RealEstateItem from "../views/RealEstate/RealEstateItem"
import RealEstateAdministratorItems from "../views/RealEstate/RealEstateAdministratorItems"
import RealEstateItemAdd from "../views/RealEstate/RealEstateItemAdd"
import RealEstateItemUpdate from "../views/RealEstate/RealEstateItemUpdate"

import Calculator from "../views/Calculator/Calculator"

import Listing from "../views/Listing/Listing"
import ListingClient from "../views/Listing/ListingClient"

import Report from "../views/Report/Report"


var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/account/login',
            component: Login
        },
        {
            path: '/account',
            component: AccountInfo,
            meta: { requiresAuth: true }
        },
        {
            path: '/home',
            component: Home
        },
        {
            path: '/news',
            component: News
        },
        {
            path: '/real-estate',
            component: RealEstate
        },
        {
            path: '/real-estate/:id',
            component: RealEstateItem
        },
        {
            path: '/real-estate-add',
            component: RealEstateItemAdd,
            meta: { requiresAuth: true }
        },
        {
            path: '/real-estate-update/:id',
            component: RealEstateItemUpdate,
            meta: { requiresAuth: true }
        },
        {
            path: '/real-estate-admin-items',
            component: RealEstateAdministratorItems,
            meta: { requiresAuth: true }
        },
        {
            path: '/news-add',
            component: NewsAdd,
            meta: { requiresAuth: true }
        },
        {
            path: '/news-update/:id',
            component: NewsUpdate,
            meta: { requiresAuth: true }
        },
        {
            path: '/calculator',
            component: Calculator
        },
        {
            path: '/listing/:paramObj',
            component: Listing,
            meta: { requiresAuth: true }
        },
        {
            path: '/listing-client/:guid',
            component: ListingClient,
        },
        {
            path: '/report',
            component: Report,
            meta: { requiresAuth: true }
        }
    ]
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('identity');

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn) {
            next({
                path: '/account/login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router