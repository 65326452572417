export default function (instance) {
    return {
        GetToken(payload) {
            return instance.post('/Auth', payload);
        },
        Check() {
            return instance.get('/Auth');
        },
    }
}
