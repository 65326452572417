<template>
  <div>
    <HeaderTemplate
      :backgroundImage="null"
      :textColor="'blue-grey darken-4'"
      :logo="require('@/assets/images/logo-dark.png')"
      :hasText="false"
      :navColor="'black'"
    />
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="text-h4 font-weight-medium">{{
              selectedLanguage.id == 1 ? "Добавить новость" : "Add article"
            }}</span>
          </v-col>
        </v-row>

        <!-- Title -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >{{
                  selectedLanguage.id == 1 ? "Заголовок (EN)" : "Title (EN)"
                }}:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="title"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Title end -->

        <!-- TitleRu -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >{{
                  selectedLanguage.id == 1 ? "Заголовок (RU)" : "Title (RU)"
                }}:*</span
              >
            </v-card>
            <v-text-field
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="titleRu"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- TitleRu end -->

        <!-- Description -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >{{
                  selectedLanguage.id == 1
                    ? "Описание (EN)"
                    : "Description (EN)"
                }}:*</span
              >
            </v-card>
            <v-textarea
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="description"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <!-- Description end -->

        <!-- DescriptionRu -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="8"
            lg="8"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >{{
                  selectedLanguage.id == 1
                    ? "Описание (RU)"
                    : "Description (RU)"
                }}:*</span
              >
            </v-card>
            <v-textarea
              dense
              filled
              class="rounded-xl"
              hide-details
              v-model="descriptionRu"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <!-- DescriptionRU end -->

        <!-- File -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-card elevation="0" width="30%">
              <span class="font-weight-regular mr-4"
                >{{ selectedLanguage.id == 1 ? "Файл" : "File" }}:*</span
              >
            </v-card>
            <v-file-input
              v-model="file"
              class="rounded-xl"
              dense
              accept="image/png, image/jpeg"
              small-chips
              hide-details
              filled
            >
            </v-file-input>
          </v-col>
        </v-row>
        <!-- File end -->

        <!-- Add button -->
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="8"
            class="d-md-flex d-lg-flex d-xl-flex flex-row align-center justify-center"
          >
            <v-btn
              elevation="0"
              class="blue white--text rounded-xl text-capitalize font-weight-regular"
              @click="add()"
              >{{
                selectedLanguage.id == 1 ? "Добавить новость" : "Add article"
              }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- Add button -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewsAddComponent",
  components: {
    HeaderTemplate,
  },
  computed: {
    ...mapGetters({
      getActionStatus: "news/GET_ACTION_STATUS",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    title: null,
    titleRu: null,
    description: null,
    descriptionRu: null,
    file: null,
  }),
  methods: {
    ...mapActions({
      addItemFromApi: "news/ADD_ITEM_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
    }),
    async add() {
      let formData = new FormData();

      formData.append("title", this.title);
      formData.append("titleRu", this.titleRu);
      formData.append("description", this.description);
      formData.append("descriptionRu", this.descriptionRu);
      formData.append("file", this.file, this.file.name);

      this.startRequest("addNews");

      await this.addItemFromApi(formData);

      this.finishRequest({
        requestName: "addNews",
        success: this.actionStatus != null,
        snackbarShow: true,
      });

      if (this.actionStatus != null) {
        var data = this.$router.resolve({
          path: "/account",
        });
        document.location.href = data.href;
      }
    },
  },
};
</script>