<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      right
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-card elevation="0">
        <v-btn
          tile
          elevation="0"
          v-if="$vuetify.breakpoint.smAndDown"
          :to="token == null ? '/account/login' : '/account'"
          width="100%"
          class="pa-6"
        >
          <v-icon left>mdi-account-outline</v-icon>
          <span class="text-capitalize" v-if="token == null"
            >{{ selectedLanguage.id == 1 ? "Войти" : "Log" }}
            <span class="text-lowercase">{{
              selectedLanguage.id == 1 ? "" : "in"
            }}</span>
          </span>
          <span class="text-capitalize" v-else>
            {{ token.userName }}
          </span>
        </v-btn>
      </v-card>
      <v-divider />
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            :to="item.link"
            color="blue"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                selectedLanguage.id == 1 ? item.titleRu : item.titleEn
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-img :src="backgroundImage ?? ''">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" lg="8" xl="8">
          <v-toolbar elevation="0" color="transparent">
            <v-toolbar-title>
              <img :src="logo" height="64" />
            </v-toolbar-title>
            <v-spacer />
            <div
              class="d-flex justify-center align-center"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-btn
                v-for="(item, index) in menuItems"
                :key="index"
                text
                :color="textColor"
                class="font-weight-regular text-caption text-md-body-1 text-md-button text-xl-button"
                :to="item.link"
              >
                {{ selectedLanguage.id == 1 ? item.titleRu : item.titleEn }}
              </v-btn>
            </div>
            <v-spacer />
            <v-menu offset-y style="z-index: 100">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" rounded elevation="0">
                  <v-icon :color="navColor">mdi-web</v-icon>
                  <span :class="'text-button ' + navColor + '--text'">{{
                    selectedLanguage.code
                  }}</span>
                </v-btn>
              </template>
              <v-list v-if="languages != null">
                <v-list-item-group>
                  <v-list-item
                    v-for="item in languages.data"
                    :key="item.id"
                    @click="selectLanguage(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-btn
              rounded
              elevation="0"
              v-if="$vuetify.breakpoint.mdAndUp"
              :to="token == null ? '/account/login' : '/account'"
            >
              <v-icon left>mdi-account-outline</v-icon>
              <span class="text-capitalize" v-if="token == null"
                >{{ selectedLanguage.id == 1 ? "Войти" : "Log" }}
                <span class="text-lowercase">{{
                  selectedLanguage.id == 1 ? "" : "in"
                }}</span></span
              >
              <span class="text-capitalize" v-else>
                {{ token.userName }}
              </span>
            </v-btn>
            <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.smAndDown"
              @click.stop="drawer = !drawer"
            >
              <v-icon :color="navColor">mdi-menu</v-icon>
            </v-app-bar-nav-icon>
          </v-toolbar>
          <div v-if="hasText">
            <div class="mt-0 mt-md-8 mt-lg-16 mt-xl-16">
              <div
                :class="
                  $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
                "
              >
                <span
                  class="text-h6 text-md-h4 text-lg-h3 text-xl-h2 font-weight-regular white--text text-uppercase"
                  >{{
                    selectedLanguage.id == 1
                      ? "Добро пожаловать!"
                      : "Welcome to Valstand"
                  }}</span
                >
              </div>
              <div
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'text-center'
                    : 'text-left' + ' mt-sm-0 mt-md-2 mt-lg-4 mt-xl-4'
                "
              >
                <span
                  class="text-caption text-md-body-2 text-lg-body-1 text-xl-h6 font-weight-light white--text"
                  >{{
                    selectedLanguage.id == 1
                      ? "Онлайн платформа Valstand — ваш ключ к эксклюзивной недвижимости."
                      : "Your Premier Real Estate Marketplace! Explore and discover the best properties."
                  }}
                  <br />
                  {{
                    selectedLanguage.id == 1
                      ? "От уютных квартир до роскошных вилл и высокодоходных коммерческих"
                      : "From cozy apartments to luxury estates and high-yield commercial listings,"
                  }}
                  <br />
                  {{
                    selectedLanguage.id == 1
                      ? "объектов, наш маркетплейс предлагает только лучшее."
                      : "Valstand is your trusted platform for all your real estate needs."
                  }}
                  <br />
                  {{
                    selectedLanguage.id == 1
                      ? "Начните поиск идеального дома с Valstand."
                      : "Start your journey today and find your dream home with ease!"
                  }}
                </span>
              </div>
            </div>
            <div
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'text-center'
                  : 'text-left' + ' mt-sm-0 mt-md-4 mt-lg-6 mt-xl-6'
              "
            ></div>
          </div>

          <!-- RealEstate filters -->
          <div
            v-if="hasRealEstateFilters"
            class="mt-sm-2 mt-md-10 mt-lg-16 md-xl-16"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <span
                  class="white--text text-sm-h6 text-md-h3 text-lg-h2 text-xl-h2 font-weight-medium text-uppercase"
                >
                  {{
                    selectedLanguage.id == 1
                      ? "Ваш компас в мире недвижимости"
                      : "Your Real Estate Compass"
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-sm-2 mt-md-6 mt-lg-8 mt-xl-8">
              <v-col cols="12" class="d-flex flex-row justify-center">
                <v-btn
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
                  :class="'rounded-r-0  white black--text rounded-xl px-8'"
                  elevation="0"
                  :width="$vuetify.breakpoint.smAndDown ? '80%' : '40%'"
                  @click="regionDialog = true"
                >
                  <v-icon left>mdi-map-marker-outline</v-icon>
                  <span class="text-capitalize font-weight-light">{{
                    selectedRegions.length == 0
                      ? selectedLanguage.id == 1
                        ? "Выберите город"
                        : "City, Region, Country"
                      : selectedRegions[0].name +
                        (selectedRegions.length > 1
                          ? (selectedLanguage.id == 1 ? " и " : " and ") +
                            (selectedRegions.length - 1) +
                            (selectedLanguage.id == 1 ? " города " : " cities ")
                          : "")
                  }}</span>
                </v-btn>

                <v-btn
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
                  class="white black--text px-8"
                  tile
                  elevation="0"
                  v-if="$vuetify.breakpoint.mdAndUp"
                  width="15%"
                  @click="typeDialog = true"
                >
                  <span class="text-capitalize font-weight-light">
                    {{
                      selectedTypes.length != types.length &&
                      selectedTypes.length != 0
                        ? selectedTypes.length
                        : selectedLanguage.id == 1
                        ? " Тип "
                        : " Any "
                    }}
                    {{ selectedLanguage.id == 1 ? "объекта" : "Type" }}
                  </span>
                </v-btn>

                <v-btn
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
                  class="white black--text px-8"
                  tile
                  elevation="0"
                  v-if="$vuetify.breakpoint.mdAndUp"
                  width="15%"
                  @click="priceDialog = true"
                >
                  <span class="text-capitalize font-weight-light">
                    {{
                      price.min == realEstateInfo.data.minPrice &&
                      price.max == realEstateInfo.data.maxPrice
                        ? selectedLanguage.id == 1
                          ? "Цена"
                          : " Any Price"
                        : `${price.min} - ${price.max}`
                    }}
                  </span>
                </v-btn>
                <v-btn
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
                  class="white black--text px-8"
                  tile
                  elevation="0"
                  v-if="
                    $vuetify.breakpoint.mdAndUp &&
                    token != null &&
                    token == null
                  "
                  width="15%"
                  @click="bedsDialog = true"
                >
                  <span class="text-capitalize font-weight-light">
                    {{ selectedBed != null ? selectedBed.text : "Any " }} Beds
                  </span>
                </v-btn>
                <v-btn
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
                  class="white black--text px-8"
                  tile
                  elevation="0"
                  v-if="
                    $vuetify.breakpoint.mdAndUp &&
                    token != null &&
                    token == null
                  "
                  width="20%"
                  @click="bathsDialog = true"
                >
                  <span class="text-capitalize font-weight-light"
                    >{{
                      selectedBath != null ? selectedBath.text : "Any "
                    }}
                    Bathrooms</span
                  >
                </v-btn>
                <v-btn
                  height="100%"
                  class="blue white--text rounded-xl rounded-l-0"
                  elevation="0"
                  width="10%"
                  v-if="$vuetify.breakpoint.mdAndUp"
                  @click="searchRealEstates()"
                >
                  <span class="text-capitalize font-weight-light">{{
                    selectedLanguage.id == 1 ? "Поиск" : "Search"
                  }}</span>
                </v-btn>
                <v-btn
                  height="100%"
                  class="blue white--text rounded-xl rounded-l-0"
                  elevation="0"
                  width="10%"
                  v-if="$vuetify.breakpoint.smAndDown"
                  @click="searchRealEstates()"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <!-- RealEstate filters  end-->
        </v-col>
      </v-row>

      <!-- Region Dialog -->
      <v-dialog v-model="regionDialog" max-width="500">
        <v-card elevation="0">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="11">
              <v-text-field
                label="Search"
                outlined
                dense
                class="mt-2"
                v-model="searchQuery"
              />
              <v-card
                tile
                outlined
                v-for="country in filteredCountries"
                :key="country.id"
              >
                <div class="blue white--text pa-2">
                  <span>{{ country.name }}</span>
                </div>
                <div>
                  <v-list dense>
                    <v-list-item-group>
                      <v-list-item
                        v-for="city in country.cities"
                        :key="city.id"
                        @click="city.selected = !city.selected"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ city.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="city.selected"
                            color="blue"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="text-capitalize" @click="regionDialog = false">{{
              selectedLanguage.id == 1 ? "Применить" : "Apply"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Region Dialog End -->

      <!-- Region Analysis Dialog -->
      <v-dialog v-model="regionAnalysisDialog" max-width="500">
        <v-card elevation="0">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="11">
              <v-text-field
                label="Search"
                outlined
                dense
                class="mt-2"
                v-model="searchAnalysisQuery"
              />
              <v-card
                tile
                outlined
                v-for="country in filteredAnalysisCountries"
                :key="country.id"
              >
                <div class="blue white--text pa-2">
                  <span>{{ country.name }}</span>
                </div>
                <div>
                  <v-list dense>
                    <v-list-item-group>
                      <v-list-item
                        v-for="city in country.cities"
                        :key="city.id"
                        @click="selectedAnalysisCityId = city.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ city.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="selectedAnalysisCityId == city.id"
                            color="blue"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              class="text-capitalize"
              @click="regionAnalysisDialog = false"
              >{{ selectedLanguage.id == 1 ? "Применить" : "Apply" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Region Analysis Dialog End -->

      <!-- Price Dialog -->
      <v-dialog v-model="priceDialog" max-width="500">
        <v-card elevation="0">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="11">
              <v-row>
                <v-col
                  cols="12"
                  class="text-center mt-2 font-weight-medium text-h6"
                >
                  {{ selectedLanguage.id == 1 ? "Цена" : "Price" }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="price.min"
                    type="number"
                    :min="0"
                    @wheel.stop.prevent
                  >
                    <template v-slot:prepend>
                      <span>Min</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="price.max"
                    type="number"
                    :min="0"
                    @wheel.stop.prevent
                  >
                    <template v-slot:prepend>
                      <span>Max</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="text-capitalize" @click="priceDialog = false">{{
              selectedLanguage.id == 1 ? "Применить" : "Apply"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Price Dialog End -->

      <!-- Beds Dialog -->
      <v-dialog v-model="bedsDialog" max-width="500">
        <v-card elevation="0">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="11">
              <v-row>
                <v-col
                  cols="12"
                  class="text-center mt-2 font-weight-medium text-h6"
                >
                  Beds
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn-toggle rounded v-model="selectedBed">
                    <v-btn
                      v-for="bedItem in bedsItems"
                      :key="bedItem.min"
                      :value="bedItem"
                      text
                    >
                      <span class="text-capitalize">{{ bedItem.text }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="text-capitalize" @click="bedsDialog = false">{{
              selectedLanguage.id == 1 ? "Применить" : "Apply"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Beds Dialog End -->

      <!-- Baths Dialog -->
      <v-dialog v-model="bathsDialog" max-width="500">
        <v-card elevation="0">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="11">
              <v-row>
                <v-col
                  cols="12"
                  class="text-center mt-2 font-weight-medium text-h6"
                >
                  Baths
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn-toggle rounded v-model="selectedBath">
                    <v-btn
                      v-for="bathItem in bathsItems"
                      :key="bathItem.min"
                      :value="bathItem"
                      text
                    >
                      <span class="text-capitalize">{{ bathItem.text }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="text-capitalize" @click="bathsDialog = false">{{
              selectedLanguage.id == 1 ? "Применить" : "Apply"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Baths Dialog End -->

      <!-- Types Dialog -->
      <v-dialog v-model="typeDialog" max-width="500">
        <v-card elevation="0">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="11">
              <v-row>
                <v-col
                  cols="12"
                  class="text-center mt-2 font-weight-medium text-h6"
                >
                  {{ selectedLanguage.id == 1 ? "Типы объектов" : "Types" }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-list dense>
                    <v-list-item-group v-model="selectedTypes" multiple>
                      <v-list-item
                        v-for="type in types"
                        :key="type.id"
                        :value="type.id"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                selectedLanguage.id == 1
                                  ? type.textRu
                                  : type.text
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="text-capitalize" @click="typeDialog = false">{{
              selectedLanguage.id == 1 ? "Применить" : "Apply"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Types Dialog End -->
    </v-img>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HeaderTemplate",
  props: {
    backgroundImage: String,
    textColor: String,
    hasText: {
      type: Boolean,
      default: false,
    },
    logo: String,
    hasRealEstateFilters: {
      type: Boolean,
      default: false,
    },
    navColor: String,
  },
  components: {},
  computed: {
    ...mapGetters({
      getToken: "auth/GET_TOKEN",
      getRealEstateInfo: "realEstate/GET_INFO",
      getLanguages: "common/GET_LANGUAGES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
    realEstateInfo: {
      get: function () {
        return this.getRealEstateInfo;
      },
    },
    languages: {
      get: function () {
        return this.getLanguages;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    filteredCountries() {
      if (this.searchQuery != null && this.searchQuery.length > 1) {
        const lowerCaseQuery = this.searchQuery.toLowerCase();
        const results = this.countries
          .map((country) => {
            const matchingCities = country.cities.filter((city) =>
              city.name.toLowerCase().includes(lowerCaseQuery)
            );
            return matchingCities.length > 0
              ? { id: country.id, name: country.name, cities: matchingCities }
              : null;
          })
          .filter((result) => result !== null);
        return results;
      } else {
        return this.countries;
      }
    },
    filteredAnalysisCountries() {
      if (
        this.searchAnalysisQuery != null &&
        this.searchAnalysisQuery.length > 1
      ) {
        const lowerCaseQuery = this.searchAnalysisQuery.toLowerCase();
        const results = this.countries
          .map((country) => {
            const matchingCities = country.cities.filter((city) =>
              city.name.toLowerCase().includes(lowerCaseQuery)
            );
            return matchingCities.length > 0
              ? { id: country.id, name: country.name, cities: matchingCities }
              : null;
          })
          .filter((result) => result !== null);
        return results;
      } else {
        return this.countries;
      }
    },
  },
  data: () => ({
    menuItems: [
      {
        titleEn: "News",
        titleRu: "Новости",
        link: "/news",
        icon: "mdi-newspaper",
      },
      {
        titleEn: "Home",
        titleRu: "Главная",
        link: "/home",
        icon: "mdi-home-outline",
      },
      {
        titleEn: "Real Estate",
        titleRu: "Поиск Объектов",
        link: "/real-estate",
        icon: "mdi-sign-real-estate",
      },
      {
        titleEn: "Calculator",
        titleRu: "Калькулятор",
        link: "/calculator",
        icon: "mdi-calculator",
      },
    ],
    regionDialog: false,
    regionAnalysisDialog: false,
    searchQuery: null,
    searchAnalysisQuery: null,
    priceDialog: false,
    price: {
      min: 1,
      max: 350000,
    },
    bedsDialog: false,
    bedsItems: [
      { text: "Any", min: 0 },
      { text: "1+", min: 1 },
      { text: "2+", min: 2 },
      { text: "3+", min: 3 },
      { text: "4+", min: 4 },
      { text: "5+", min: 5 },
    ],
    bathsItems: [
      { text: "Any", min: 0 },
      { text: "1+", min: 1 },
      { text: "2+", min: 2 },
      { text: "3+", min: 3 },
      { text: "4+", min: 4 },
      { text: "5+", min: 5 },
    ],
    selectedBed: null,
    bathsDialog: false,
    selectedBath: null,
    typeDialog: false,
    selectedTypes: [],
    types: [
      { id: 1, text: "Villa/Townhouse", textRu: "Вилла/Таунхаус" },
      { id: 2, text: "Apartment", textRu: "Апартаменты" },
      { id: 3, text: "Commercial", textRu: "Коммерческая недвижимость" },
      { id: 4, text: "Plot", textRu: "Земельный участок" },
    ],
    drawer: false,
    countries: [
      {
        id: 1,
        name: "Cyprus",
        cities: [
          {
            id: 2,
            name: "Limassol",
            selected: false,
            lat: 34.687014,
            lng: 33.036281,
          },
          {
            id: 3,
            name: "Paraklisi",
            selected: false,
            lat: 34.743481,
            lng: 33.160301,
          },
          {
            id: 4,
            name: "Ypsonas",
            selected: false,
            lat: 34.688559,
            lng: 32.960859,
          },
          {
            id: 5,
            name: "Episkopi",
            selected: false,
            lat: 34.671618,
            lng: 32.901161,
          },
          {
            id: 6,
            name: "Paphos",
            selected: false,
            lat: 34.776681,
            lng: 32.422063,
          },
          {
            id: 7,
            name: "Polis",
            selected: false,
            lat: 35.035298,
            lng: 32.425804,
          },
          {
            id: 8,
            name: "Latchi",
            selected: false,
            lat: 35.038826,
            lng: 32.397815,
          },
          {
            id: 9,
            name: "Larnaca",
            selected: false,
            lat: 34.919188,
            lng: 33.619918,
          },
          {
            id: 10,
            name: "Zigi",
            selected: false,
            lat: 34.72918,
            lng: 33.338354,
          },
          {
            id: 11,
            name: "Mazotos",
            selected: false,
            lat: 34.804712,
            lng: 33.488645,
          },
          {
            id: 12,
            name: "Livadia",
            selected: false,
            lat: 34.951162,
            lng: 33.632111,
          },
          {
            id: 13,
            name: "Oroklini",
            selected: false,
            lat: 34.981244,
            lng: 33.656656,
          },
          {
            id: 14,
            name: "Pila",
            selected: false,
            lat: 35.013635,
            lng: 33.692081,
          },
          {
            id: 15,
            name: "Ayia Napa",
            selected: false,
            lat: 34.988939,
            lng: 33.999348,
          },
          {
            id: 16,
            name: "Protaras",
            selected: false,
            lat: 35.012158,
            lng: 34.056713,
          },
          {
            id: 17,
            name: "Paralimni",
            selected: false,
            lat: 35.038487,
            lng: 33.981577,
          },
          {
            id: 18,
            name: "Nicosia",
            selected: false,
            lat: 35.185917,
            lng: 33.382194,
          },
        ],
      },
    ],
    selectedRegions: [],
    selectedAnalysisCityId: 0,
  }),
  methods: {
    ...mapActions({
      searchRealEstatesFromApi: "realEstate/SEARCH_FROM_API",
      saveRealEstateSearchParams: "realEstate/SAVE_SEARCH_PARAMS",
      commitAnalysisCity: "realEstate/COMMIT_SELECTED_ANALYSIS_CITY",
      commitSelectedLanguage: "common/COMMIT_SELECTED_LANGUAGE",
    }),
    async searchRealEstates() {
      var params = {
        pageNumber: 1,
        pageSize: 20,
        minPrice: this.price.min,
        maxPrice: this.price.max,
      };

      if (this.selectedBed != null) {
        params.minBedsQuantity = this.selectedBed.min;
      }

      if (this.selectedBath != null) {
        params.minBathroomsQuantity = this.selectedBath.min;
      }

      if (this.selectedTypes.length > 0) {
        params.types = this.selectedTypes;
      }

      if (this.selectedRegions.length > 0) {
        params.regions = this.selectedRegions.map((x) => x.id);
      }

      this.saveRealEstateSearchParams(params);

      await this.searchRealEstatesFromApi(params);
    },
    initRealEstateInfo() {
      if (this.realEstateInfo != null) {
        if (this.realEstateInfo.data.maxPrice != 0) {
          this.price.min =
            this.realEstateInfo.data.minPrice ==
            this.realEstateInfo.data.maxPrice
              ? 0
              : this.realEstateInfo.data.minPrice;
          this.price.max = this.realEstateInfo.data.maxPrice ?? 0;
        }
      }
    },
    getCityById(cityId) {
      for (let i = 0; i < this.countries.length; i++) {
        const country = this.countries[i];
        for (let j = 0; j < country.cities.length; j++) {
          const city = country.cities[j];
          if (city.id === cityId) {
            return city;
          }
        }
      }
      return null;
    },
    selectLanguage(item) {
      this.commitSelectedLanguage(item);
      document.location.reload();
    },
  },
  watch: {
    countries: {
      handler: function () {
        this.selectedRegions = this.countries.flatMap((country) =>
          country.cities.filter((city) => city.selected)
        );
      },
      deep: true,
    },
    realEstateInfo: function () {
      this.initRealEstateInfo();
    },
    selectedAnalysisCityId: function () {
      if (this.selectedAnalysisCityId != 0) {
        let city = this.getCityById(this.selectedAnalysisCityId);
        this.commitAnalysisCity(city);
      }
    },
  },
  created() {
    this.initRealEstateInfo();
  },
};
</script>