import requestState from "../../enums/RequestState"
export default {
    START_REQUEST({ commit }, requestName) {
        commit('SET_REQUEST_STATE', { requestName, status: requestState.PENDING });
    },
    FINISH_REQUEST({ commit, dispatch }, { requestName, success = true, snackbarShow = false }) {
        commit('SET_REQUEST_STATE', { requestName, status: success ? requestState.SUCCESS : requestState.ERROR });

        let snackbarPayload = {
            show: success ? snackbarShow : true,
            timeout: 3000,
            color: success ? 'green' : "red",
            text: success ? 'ok' : "error",
        }
        if (snackbarPayload.show) {
            dispatch('common/GENERATE_SNACKBAR', snackbarPayload, { root: true })
        }
    },
    RESET_REQUEST({ commit }, requestName) {
        commit('SET_REQUEST_STATE', { requestName, status: requestState.IDLE });
    },
}