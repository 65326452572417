import Vue from 'vue'
import Vuex from 'vuex'
import common from './common/commonStore'
import requestState from './requestState/requestStateStore'
import realEstate from './realEstate/realEstateStore'
import region from './region/regionStore'
import auth from './auth/authStore'
import news from './news/newsStore'
import calculator from './calculator/calculatorStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        common,
        requestState,
        realEstate,
        region,
        auth,
        news,
        calculator
    }
})
