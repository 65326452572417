<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-card class="rounded-xl" elevation="0">
        <v-img src="../../assets/images/find.png" height="40vh">
          <v-container>
            <div class="mt-6">
              <span class="text-h3 font-weight-regular white--text">
                {{ selectedLanguage.id == 1 ? "Наши Контакты" : "Contact Us" }}
              </span>
            </div>
            <div class="mt-4">
              <span class="text-body-1 font-weight-regular white--text">
                {{
                  selectedLanguage.id == 1
                    ? "Готовы к следующему шагу?"
                    : "Ready to elevate your real estate game?"
                }}
                <br v-if="$vuetify.breakpoint.mdAndUp" />
                {{
                  selectedLanguage.id == 1
                    ? "Свяжитесь с нами, чтобы стать нашим партнером, разместить объявление о своей недвижимости,"
                    : "Contact us to become a partner, list your properties, or get answers to any questions."
                }}
                <br v-if="$vuetify.breakpoint.mdAndUp" />
                {{
                  selectedLanguage.id == 1
                    ? "или получить ответы на любые вопросы."
                    : "Let's connect and grow together!"
                }}
              </span>
            </div>
            <div class="mt-sm-0 mt-md-4 mt-lg-4 md-xl-4">
              <v-btn
                rounded
                elevation="0"
                class="blue white--text"
                @click="sendMail()"
              >
                <span class="text-lowercase">info@valstandgroup.com</span>
              </v-btn>
            </div>
          </v-container>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeFindComponent",
  components: {},
  computed: {
    ...mapGetters({
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    sendMail() {
      window.location.href = "mailto:info@valstandgroup.com";
    },
  },
};
</script>