<template>
  <div>
    <Header />
    <v-row style="height: 80vh" class="d-flex align-center justify-center">
      <v-col cols="12" md="4" lg="4" xl="4">
        <div>
          <AuthenticationFormComponent />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AuthenticationFormComponent from "../../components/Account/AuthenticationFormComponent.vue";
import Header from "../../components/News/NewsHeaderComponent.vue";
export default {
  name: "LoginView",
  components: {
    AuthenticationFormComponent,
    Header,
  },
};
</script>