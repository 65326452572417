import state from './authState';
import getters from './authGetters';
import mutations from './authMutations';
import actions from './authActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}