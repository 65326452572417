<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    bottom
    :color="snackbar.color"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "DialogComponent",
  computed: {
    ...mapGetters({
      getSnackbar: "common/GET_SNACKBAR",
    }),
    snackbar: {
      get: function () {
        return this.getSnackbar;
      },
    },
  },
  data: () => ({}),
  methods: {},
};
</script>