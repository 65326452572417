<template>
  <div>
    <HeaderTemplate
      :backgroundImage="null"
      :textColor="'blue-grey darken-4'"
      :logo="require('@/assets/images/logo-dark.png')"
      :hasText="false"
      :navColor="'black'"
    />
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row v-if="item.data.name != null || item.data.region != null">
          <v-col cols="12">
            <v-row v-if="item.data.name != null">
              <v-col cols="12">
                <span v-if="token != null" class="font-weight-medium text-h4">{{
                  item.data.name
                }}</span>
                <span v-else class="font-weight-medium text-h4">{{
                  item.data.code
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="item.data.region != null">
              <v-col cols="12">
                <v-icon left>mdi-map-marker-outline</v-icon>
                <span class="font-weight-regular text-body-1">{{
                  item.data.region.name
                }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <!-- First Column -->
          <v-col cols="12" md="8" lg="8" xl="8" class="px-8">
            <!-- Images -->
            <v-row>
              <v-col cols="12">
                <v-carousel>
                  <v-carousel-item
                    v-for="image in item.data.images"
                    :key="image.id"
                  >
                    <v-img
                      :src="image.path"
                      class="rounded-xl"
                      width="100%"
                      height="100%"
                    />
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
            <!-- Images end-->

            <!-- Description -->
            <v-row v-if="item.data.description != null">
              <v-col cols="12">
                <div v-if="!viewFullDescription">
                  <span
                    class="font-weight-regular text-body-1"
                    v-if="selectedLanguage.id == 1"
                    >{{
                      item.data.descriptionRu != null &&
                      item.data.descriptionRu.length > 250
                        ? item.data.descriptionRu.substring(0, 250) + "..."
                        : item.data.descriptionRu
                    }}</span
                  >
                  <span class="font-weight-regular text-body-1" v-else>{{
                    item.data.description != null &&
                    item.data.description.length > 250
                      ? item.data.description.substring(0, 250) + "..."
                      : item.data.description
                  }}</span>
                  <div>
                    <v-btn
                      x-small
                      text
                      color="blue"
                      class="px-0 text-capitalize"
                      @click="viewFullDescription = !viewFullDescription"
                      >{{
                        selectedLanguage.id == 1
                          ? "Показать полностью..."
                          : "Read More..."
                      }}</v-btn
                    >
                  </div>
                </div>
                <div v-else>
                  <span class="font-weight-regular">
                    {{
                      selectedLanguage.id == 1
                        ? item.data.descriptionRu
                        : item.data.description
                    }}
                  </span>
                  <div>
                    <v-btn
                      x-small
                      text
                      color="blue"
                      class="px-0 text-capitalize"
                      @click="viewFullDescription = !viewFullDescription"
                      >{{
                        selectedLanguage.id == 1
                          ? "Показать частично..."
                          : "Read Less..."
                      }}</v-btn
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
            <!-- Description end -->

            <!-- Features -->
            <v-row
              v-if="
                item.data.realStateTypeId != 5 &&
                item.data.includeProperties != null &&
                item.data.includeProperties.length > 0
              "
            >
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <span class="text-h4">{{
                      selectedLanguage.id == 1 ? "Дополнительно" : "Features"
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card
                      elevation="0"
                      color="grey lighten-4"
                      class="rounded-xl pa-8"
                      height="100%"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="4"
                          lg="4"
                          xl="4"
                          v-for="property in item.data.includeProperties"
                          :key="property.id"
                        >
                          <v-icon left>mdi-check-circle-outline</v-icon>
                          <span class="text-body-1 font-weight-regular">{{
                            selectedLanguage.id == 1
                              ? property.nameRu
                              : property.nameEn
                          }}</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          lg="4"
                          xl="4"
                          v-if="
                            item.data.realStateTypeId != 5 &&
                            item.data.parentId == null
                          "
                        >
                          <v-icon left>mdi-lightning-bolt-circle</v-icon>
                          <span class="text-body-1 font-weight-regular">{{
                            selectedLanguage.id == 1
                              ? item.data.energyEfficiency.nameRu
                              : item.data.energyEfficiency.nameEn
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4"> </v-col>
            </v-row>
            <!-- Features end -->

            <!-- Map -->
            <v-row
              v-if="item.data.latitude != null && item.data.longitude != null"
            >
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <span class="text-h4">
                      {{
                        selectedLanguage.id == 1
                          ? "Расположение на карте"
                          : "Map Location"
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card
                      elevation="0"
                      color="grey lighten-4"
                      class="rounded-xl"
                      height="100%"
                    >
                      <GmapMap
                        :center="{
                          lat: item.data.latitude,
                          lng: item.data.longitude,
                        }"
                        :zoom="13"
                        map-type-id="terrain"
                        style="
                          width: 100%;
                          height: 40vh;
                          -webkit-transform: translate3d(0px, 0px, 0px);
                          -webkit-mask-image: -webkit-radial-gradient(
                            white,
                            black
                          );
                        "
                      >
                        <GmapCircle
                          :center="{
                            lat: item.data.latitude,
                            lng: item.data.longitude,
                          }"
                          :radius="500"
                          :visible="true"
                          :options="{
                            fillColor: 'blue',
                            strokeWeight: 1,
                            fillOpacity: 0.1,
                          }"
                          v-if="token == null"
                        ></GmapCircle>
                        <GmapMarker
                          :position="{
                            lat: item.data.latitude,
                            lng: item.data.longitude,
                          }"
                          v-else
                        />
                      </GmapMap>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4"> </v-col>
            </v-row>
            <!-- Map End -->
          </v-col>
          <!-- First Column End-->

          <!-- Second Column -->
          <v-col cols="12" md="4" lg="4" xl="4">
            <!-- Properties -->
            <v-row>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  color="grey lighten-4"
                  class="rounded-xl pa-8"
                >
                  <div v-if="item.data.realStateTypeId != 5">
                    <v-row class="my-4" v-if="item.data.yearOfDelivery != null">
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-calendar-range</v-icon>
                          {{ item.data.yearOfDelivery }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.price != null && item.data.price != 0"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-cash</v-icon>
                          {{ item.data.price | formatNumber }} €
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.bathroomsQuantity != null"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-shower</v-icon>
                          {{ item.data.bathroomsQuantity }}
                          {{
                            selectedLanguage.id == 1
                              ? "Ванные комнаты"
                              : "Bathrooms"
                          }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.bedroomsQuantity != null"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-bed-king-outline</v-icon>
                          {{ item.data.bedroomsQuantity }}
                          {{ selectedLanguage.id == 1 ? "Спальни" : "Beds" }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="my-4" v-if="item.data.square != null">
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-square-circle-outline</v-icon>
                          {{ item.data.square | formatNumber }}
                          m2
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.internalCoveredArea != null"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-square-circle-outline</v-icon>
                          {{ item.data.internalCoveredArea | formatNumber }}
                          m2
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.uncoveredVerandArea != null"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-bus-stop-uncovered</v-icon>
                          {{ item.data.uncoveredVerandArea | formatNumber }}
                          m2
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.coveredVerandArea != null"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-bus-stop-covered</v-icon>
                          {{ item.data.coveredVerandArea | formatNumber }}
                          m2
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="my-4"
                      v-if="item.data.price != null && item.data.square != null"
                    >
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-cash-100</v-icon>
                          {{
                            (item.data.price / item.data.square) | formatNumber
                          }}
                          / m2
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="my-4" v-if="item.data.storeys != null">
                      <v-col cols="12">
                        <span>
                          <v-icon left>mdi-arrow-top-right</v-icon>
                          {{ item.data.storeys }}
                          {{
                            selectedLanguage.id == 1 ? "Этажность" : "Storeys"
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row class="my-4" v-if="item.data.yearOfDelivery != null">
                      <v-col cols="12">
                        <v-icon left>mdi-calendar-range</v-icon>
                        <span class="text-body-1 font-weight-regular">{{
                          item.data.yearOfDelivery
                        }}</span>
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        item.data.includeProperties != null &&
                        item.data.includeProperties.length > 0
                      "
                    >
                      <v-row
                        class="my-4"
                        v-for="property in item.data.includeProperties"
                        :key="property.id"
                      >
                        <v-col cols="12">
                          <v-icon left>mdi-check-circle-outline</v-icon>
                          <span class="text-body-1 font-weight-regular">{{
                            selectedLanguage.id == 1
                              ? property.nameRu
                              : property.nameEn
                          }}</span></v-col
                        >
                      </v-row>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <!-- Properties end -->

            <!-- Contacts -->
            <v-row>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  color="grey lighten-4"
                  class="rounded-xl pa-4"
                  height="100%"
                  v-if="
                    item.data.agentEmail != null ||
                    item.data.agentPhone != null ||
                    item.data.agentName != null
                  "
                >
                  <v-row>
                    <v-col
                      cols="3"
                      v-if="
                        item.data.agentEmail != null ||
                        item.data.agentPhone != null ||
                        item.data.agentName != null
                      "
                    >
                      <v-avatar>
                        <v-img
                          contain
                          src="../../assets/images/no-avatar.png"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="8" v-if="item.data.agentName != null">
                      <span class="font-weight-medium">{{
                        item.data.agentName
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      item.data.agentEmail != null ||
                      item.data.agentPhone != null
                    "
                  >
                    <v-col
                      cols="4"
                      class="text-center"
                      v-if="item.data.agentPhone != null"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="blue"
                        @click="openTelegram()"
                        ><v-icon color="white">mdi-send-outline</v-icon></v-btn
                      >
                    </v-col>
                    <v-col
                      cols="4"
                      class="text-center"
                      v-if="item.data.agentEmail != null"
                    >
                      <v-btn small fab outlined class="red" @click="openEmail()"
                        ><v-icon color="white">mdi-gmail</v-icon></v-btn
                      >
                    </v-col>
                    <v-col
                      cols="4"
                      class="text-center"
                      v-if="item.data.agentPhone != null"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="white"
                        @click="openWhatsapp()"
                        ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <!-- Contacts end -->
          </v-col>
          <!-- Second Column end -->
        </v-row>

        <!-- Project Apartments -->
        <v-row
          v-if="item.data.apartments != null && item.data.apartments.length > 0"
        >
          <v-col cols="12" class="px-8">
            <v-row>
              <v-col cols="12">
                <span class="text-h4">{{
                  selectedLanguage.id == 1 ? "Апартаменты" : "Apartments"
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="rounded-lg">
                  <template v-slot:default>
                    <thead>
                      <tr class="blue">
                        <th class="text-left white--text rounded-tl-lg">
                          {{
                            selectedLanguage.id == 1 ? "Спальни" : "Bedrooms"
                          }}
                        </th>
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Площадь" : "Area" }}
                        </th>
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Цена" : "Price" }}
                        </th>
                        <th class="text-left white--text">
                          {{
                            selectedLanguage.id == 1
                              ? "Вид из окна"
                              : "Windows overlooks"
                          }}
                        </th>
                        <th class="text-left white--text">
                          {{
                            selectedLanguage.id == 1
                              ? "Климат-контроль"
                              : "Cooling/heating"
                          }}
                        </th>
                        <th class="text-left white--text rounded-tr-lg">
                          {{
                            selectedLanguage.id == 1
                              ? "Первичная/Вторичная"
                              : "New/Resale"
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="apart in item.data.apartments" :key="apart.id">
                        <td class="text-left">{{ apart.bedroomsQuantity }}</td>
                        <td class="text-left">
                          <div>
                            <v-icon left small
                              >mdi-square-circle-outline</v-icon
                            >
                            {{ apart.internalCoveredArea | formatNumber }}m2
                          </div>
                          <div>
                            <v-icon left small>mdi-bus-stop-uncovered</v-icon>
                            {{ apart.coveredVerandArea | formatNumber }}m2
                          </div>
                          <div>
                            <v-icon left small>mdi-bus-stop-covered</v-icon>
                            {{ apart.uncoveredVerandArea | formatNumber }}m2
                          </div>
                        </td>
                        <td class="text-left">
                          {{ apart.price | formatNumber }} €
                        </td>
                        <td class="text-left">
                          {{
                            windowsOverlooks.find(
                              (z) => z.id == apart.windowsOverlookId
                            )[selectedLanguage.id == 1 ? "nameRu" : "name"]
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            conditionings
                              .filter((z) =>
                                apart.condigitionings.includes(z.id)
                              )
                              .map(
                                (z) =>
                                  z[
                                    selectedLanguage.id == 1 ? "nameRu" : "name"
                                  ]
                              )
                              .join()
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            primaries.find((z) => z.id == apart.primaryId)[
                              selectedLanguage.id == 1 ? "nameRu" : "name"
                            ]
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Project Apartments End -->

        <!-- Project Villas -->
        <v-row v-if="item.data.villa != null && item.data.villa.length > 0">
          <v-col cols="12" class="px-8">
            <v-row>
              <v-col cols="12">
                <span class="text-h4">{{
                  selectedLanguage.id == 1
                    ? "Виллы/Таунхаусы"
                    : "Villa/Townhouses"
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="rounded-lg">
                  <template v-slot:default>
                    <thead>
                      <tr class="blue">
                        <th class="text-left white--text rounded-tl-lg">
                          {{
                            selectedLanguage.id == 1 ? "Спальни" : "Bedrooms"
                          }}
                        </th>
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Площадь" : "Area" }}
                        </th>
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Цена" : "Price" }}
                        </th>
                        <th class="text-left white--text">
                          {{
                            selectedLanguage.id == 1
                              ? "Вид из окна"
                              : "Windows overlooks"
                          }}
                        </th>
                        <th class="text-left white--text">
                          {{
                            selectedLanguage.id == 1
                              ? "Климат-контроль"
                              : "Cooling/heating"
                          }}
                        </th>
                        <th class="text-left white--text rounded-tr-lg">
                          {{
                            selectedLanguage.id == 1
                              ? "Первичная/Вторичная"
                              : "New/Resale"
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="apart in item.data.villa" :key="apart.id">
                        <td class="text-left">{{ apart.bedroomsQuantity }}</td>
                        <td class="text-left">
                          <div>
                            <v-icon left small
                              >mdi-square-circle-outline</v-icon
                            >
                            {{ apart.internalCoveredArea | formatNumber }}m2
                          </div>
                          <div>
                            <v-icon left small>mdi-bus-stop-uncovered</v-icon>
                            {{ apart.coveredVerandArea | formatNumber }}m2
                          </div>
                          <div>
                            <v-icon left small>mdi-bus-stop-covered</v-icon>
                            {{ apart.uncoveredVerandArea | formatNumber }}m2
                          </div>
                        </td>
                        <td class="text-left">
                          {{ apart.price | formatNumber }} €
                        </td>
                        <td class="text-left">
                          {{
                            windowsOverlooks.find(
                              (z) => z.id == apart.windowsOverlookId
                            )[selectedLanguage.id == 1 ? "nameRu" : "name"]
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            conditionings
                              .filter((z) =>
                                apart.condigitionings.includes(z.id)
                              )
                              .map(
                                (z) =>
                                  z[
                                    selectedLanguage.id == 1 ? "nameRu" : "name"
                                  ]
                              )
                              .join()
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            primaries.find((z) => z.id == apart.primaryId)[
                              selectedLanguage.id == 1 ? "nameRu" : "name"
                            ]
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Project Villas end -->

        <!-- Project Commercials -->
        <v-row
          v-if="
            item.data.commercials != null && item.data.commercials.length > 0
          "
        >
          <v-col cols="12" class="px-8">
            <v-row>
              <v-col cols="12">
                <span class="text-h4">{{
                  selectedLanguage.id == 1
                    ? "Коммерческие недвижимости"
                    : "Commercials"
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="rounded-lg">
                  <template v-slot:default>
                    <thead>
                      <tr class="blue">
                        <th class="text-left white--text rounded-tl-lg">
                          {{ selectedLanguage.id == 1 ? "Площадь" : "Area" }}
                        </th>
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Цена" : "Price" }}
                        </th>
                        <th class="text-left white--text">
                          {{
                            selectedLanguage.id == 1
                              ? "Вид из окна"
                              : "Windows overlooks"
                          }}
                        </th>
                        <th class="text-left white--text">
                          {{
                            selectedLanguage.id == 1
                              ? "Климат-контроль"
                              : "Cooling/heating"
                          }}
                        </th>
                        <th class="text-left white--text rounded-tr-lg">
                          {{
                            selectedLanguage.id == 1
                              ? "Первичная/Вторичная"
                              : "New/Resale"
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="apart in item.data.commercials"
                        :key="apart.id"
                      >
                        <td class="text-left">
                          <div>
                            <v-icon left small
                              >mdi-square-circle-outline</v-icon
                            >
                            {{ apart.internalCoveredArea | formatNumber }}m2
                          </div>
                          <div>
                            <v-icon left small>mdi-bus-stop-uncovered</v-icon>
                            {{ apart.coveredVerandArea | formatNumber }}m2
                          </div>
                          <div>
                            <v-icon left small>mdi-bus-stop-covered</v-icon>
                            {{ apart.uncoveredVerandArea | formatNumber }}m2
                          </div>
                        </td>
                        <td class="text-left">
                          {{ apart.price | formatNumber }} €
                        </td>
                        <td class="text-left">
                          {{
                            windowsOverlooks.find(
                              (z) => z.id == apart.windowsOverlookId
                            )[selectedLanguage.id == 1 ? "nameRu" : "name"]
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            conditionings
                              .filter((z) =>
                                apart.condigitionings.includes(z.id)
                              )
                              .map(
                                (z) =>
                                  z[
                                    selectedLanguage.id == 1 ? "nameRu" : "name"
                                  ]
                              )
                              .join()
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            primaries.find((z) => z.id == apart.primaryId)[
                              selectedLanguage.id == 1 ? "nameRu" : "name"
                            ]
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Project Commercials End -->

        <!-- Project Plots -->
        <v-row v-if="item.data.plots != null && item.data.plots.length > 0">
          <v-col cols="12" class="px-8">
            <v-row>
              <v-col cols="12">
                <span class="text-h4">{{
                  selectedLanguage.id == 1 ? "Земельные участки" : "Plots"
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="rounded-lg">
                  <template v-slot:default>
                    <thead>
                      <tr class="blue">
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Площадь" : "Area" }}
                        </th>
                        <th class="text-left white--text">
                          {{ selectedLanguage.id == 1 ? "Цена" : "Price" }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="apart in item.data.plots" :key="apart.id">
                        <td class="text-left">
                          {{ apart.square | formatNumber }}m2
                        </td>
                        <td class="text-left">
                          {{ apart.price | formatNumber }} €
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Project Plots end -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
import { mapGetters } from "vuex";
export default {
  name: "RealEstateItemComponent",
  components: {
    HeaderTemplate,
  },
  computed: {
    ...mapGetters({
      getItem: "realEstate/GET_ITEM",
      getToken: "auth/GET_TOKEN",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    item: {
      get: function () {
        return this.getItem;
      },
    },
    token: {
      get: function () {
        return this.getToken;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    viewFullDescription: false,
    energyEfficiencies: [
      {
        id: 1,
        name: "Class A",
        nameRu: "Класс A",
      },
      {
        id: 2,
        name: "Class B",
        nameRu: "Класс B",
      },
      {
        id: 3,
        name: "Class C",
        nameRu: "Класс C",
      },
      {
        id: 4,
        name: "Class D",
        nameRu: "Класс D",
      },
      {
        id: 5,
        name: "Unspecified",
        nameRu: "Уточнить",
      },
    ],
    windowsOverlooks: [
      { id: 1, name: "Sea/river view", nameRu: "Вид на море" },
      { id: 2, name: "Mountain view", nameRu: "Вид на горы" },
      { id: 3, name: "City view", nameRu: "Вид на город" },
      { id: 4, name: "Park view", nameRu: "Парк" },
      { id: 5, name: "Nature view", nameRu: "Природа" },
      { id: 6, name: "Unspecified", nameRu: "Уточнить" },
    ],

    conditionings: [
      { id: 1, name: "A/C", nameRu: "Кондиционер" },
      { id: 2, name: "Heating", nameRu: "Отопление" },
      { id: 3, name: "Underfloor heating", nameRu: "Теплые полы" },
      {
        id: 4,
        name: "Provision for A/C",
        nameRu: "Провизии  для кондиционера",
      },
      {
        id: 5,
        name: "Provision for heating systems",
        nameRu: "Провизии для отопления",
      },
      {
        id: 6,
        name: "Provision for underfloor heating",
        nameRu: "Провизии для теплых полов",
      },
    ],

    primaries: [
      { id: 1, name: "New", nameRu: "Первичная" },
      { id: 2, name: "Resale", nameRu: "Вторичная" },
    ],
  }),
  methods: {
    openTelegram() {
      window.open("https://t.me/" + this.item.data.agentPhone, "_blank");
    },
    openWhatsapp() {
      window.open("https://wa.me/" + this.item.data.agentPhone, "_blank");
    },
    openEmail() {
      window.open("mailto:" + this.item.data.agentEmail, "_blank");
    },
  },
};
</script>