<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-card elevation="0" height="70vh" class="rounded-xl">
            <v-img src="../../assets/images/analysis-build.png" height="70vh">
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7">
          <v-card
            elevation="0"
            height="70vh"
            class="grey lighten-4 rounded-xl d-flex flex-column align-center justify-center"
          >
            <div>
              <v-row>
                <v-col cols="12" class="text-center">
                  <span class="text-h3 text-uppercase font-weight-regular">
                    {{
                      selectedLanguage.id == 1
                        ? "Обретите свой идеальный уголок с Valstand"
                        : "Find Your Place in the World"
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <span class="text-light font-weight-regular text-h6">
                    {{
                      selectedLanguage.id == 1
                        ? "Откройте для себя дом вашей мечты!"
                        : "Discover your dream home today! "
                    }}
                    <br />
                    {{
                      selectedLanguage.id == 1
                        ? "Исследуйте лучшие предложения от надежных партнеров,"
                        : "Explore top real estate listings from trusted providers,"
                    }}
                    <br />
                    {{
                      selectedLanguage.id == 1
                        ? "где каждый объект отличается идеальным сочетанием"
                        : "offering the best in location, style, and value."
                    }}
                    <br />
                    {{
                      selectedLanguage.id == 1
                        ? "местоположения, стиля и цены."
                        : "Your perfect home is just a click away — start your journey now!"
                    }}
                    <br />
                    {{
                      selectedLanguage.id == 1
                        ? "От идеального предложения вас отделяет всего один клик."
                        : ""
                    }}
                    <br />
                    {{
                      selectedLanguage.id == 1
                        ? "Начните путь к заветной мечте уже сегодня! "
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    class="blue white--text"
                    to="/real-estate"
                  >
                    <span class="text-capitalize">{{
                      selectedLanguage.id == 1 ? "Поиск" : "Listings"
                    }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeAnalysisComponent",
  components: {},
  computed: {
    ...mapGetters({
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
};
</script>