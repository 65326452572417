<template>
  <v-card
    class="rounded-xl ma-2"
    elevation="2"
    @click="openItem()"
    height="100%"
  >
    <v-img :src="logo" height="20vh" class="text-right">
      <v-btn
        elevation="0"
        fab
        x-small
        color="white ma-2"
        v-if="editShow"
        @click="updateItem()"
      >
        <v-icon color="blue">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        elevation="0"
        fab
        x-small
        color="white ma-2"
        v-if="editShow"
        @click="deleteItem()"
      >
        <v-icon color="red">mdi-delete</v-icon>
      </v-btn>
    </v-img>
    <v-container>
      <v-row no-gutters>
        <v-col cols="7">
          <v-row no-gutters>
            <v-col cols="12">&nbsp;</v-col>
          </v-row>
          <v-row no-gutters v-if="name != null">
            <v-col cols="12">
              <span v-if="editShow" class="text-body-1 font-weight-regular">{{
                name
              }}</span>
              <span v-else class="text-body-1 font-weight-regular">{{
                codeName
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="location != null">
            <v-col cols="12"
              ><span class="text-body-1 font-weight-regular">{{
                location
              }}</span></v-col
            >
          </v-row>
        </v-col>

        <v-col cols="5">
          <v-row no-gutters v-if="price != null">
            <v-col cols="12" class="text-right">
              <span class="text-body-1 font-weight-regular"
                >{{ price | formatNumber }} €</span
              >
            </v-col>
          </v-row>
          <v-row no-gutters v-if="beds != null || baths != null">
            <v-col cols="12" class="text-right">
              <span class="text-caption font-weight-regular">
                <v-icon small v-if="beds != null">mdi-bed-double-outline</v-icon
                >{{ beds }}
                <v-icon small v-if="baths != null"
                  >mdi-countertop-outline</v-icon
                >{{ baths }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AdItemTemplate",
  props: {
    logo: String,
    name: String,
    location: String,
    price: Number,
    beds: Number,
    baths: Number,
    to: String,
    editShow: Boolean,
    id: Number,
    codeName: String,
  },
  components: {},
  computed: {
    ...mapGetters({
      getActionStatus: "realEstate/GET_ACTION_STATUS",
      getItems: "realEstate/GET_ITEMS",
    }),
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    items: {
      get: function () {
        return this.getItems;
      },
    },
  },
  data: () => ({
    clicked: false,
  }),
  methods: {
    ...mapActions({
      deleteItemFromApi: "realEstate/DELETE_ITEM_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
    }),
    openItem() {
      if (this.clicked) return;
      this.$router.push(this.to);
    },
    updateItem() {
      if (this.clicked) return;

      this.$router.push("/real-estate-update/" + this.id);
    },
    async deleteItem() {
      if (this.clicked) return;

      this.clicked = true;
      if (confirm("Are you sure you want to delete the real estate?")) {
        this.startRequest("deleteRealEstate");

        await this.deleteItemFromApi({
          id: this.id,
        });

        if (this.actionStatus != null) {
          var itemIndex = this.items.data.findIndex((x) => x.id == this.id);
          if (itemIndex != -1) {
            this.items.data.splice(itemIndex, 1);
          }
        }

        this.finishRequest({
          requestName: "deleteRealEstate",
          success: this.actionStatus != null,
          snackbarShow: true,
        });
      }

      this.clicked = false;
    },
  },
};
</script>