<template>
  <v-row class="d-flex justify-center align-center mt-2">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <!-- Codes -->
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-autocomplete
            v-model="selectedCodes"
            :items="codes"
            label="Codes"
            dense
            outlined
            hide-details
            small-chips
            class="rounded-lg"
            return-object
            multiple
          >
          </v-autocomplete>
        </v-col>
        <!-- Codes -->

        <!-- Main type -->
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-select
            v-model="selectedTypes"
            :items="types"
            label="Object Types"
            item-text="name"
            item-value="id"
            dense
            outlined
            hide-details
            small-chips
            class="rounded-lg"
            return-object
            multiple
          >
          </v-select>
        </v-col>
        <!-- Main type -->

        <!-- Names -->
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-autocomplete
            v-model="selectedNames"
            :items="names"
            label="Names"
            dense
            outlined
            hide-details
            small-chips
            class="rounded-lg"
            return-object
            multiple
          >
          </v-autocomplete>
        </v-col>
        <!-- Names -->

        <!-- Developers -->
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-autocomplete
            v-model="selectedDevelopers"
            :items="developers"
            label="Developers"
            dense
            outlined
            hide-details
            small-chips
            class="rounded-lg"
            return-object
            multiple
          >
          </v-autocomplete>
        </v-col>
        <!-- Developers -->

        <!-- AgentNames -->
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-autocomplete
            v-model="selectedAgentNames"
            :items="agentNames"
            label="Agent Names"
            dense
            outlined
            hide-details
            small-chips
            class="rounded-lg"
            return-object
            multiple
          >
          </v-autocomplete>
        </v-col>
        <!-- AgentNames -->
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            elevation="0"
            class="blue white--text rounded-xl text-capitalize font-weight-regular"
            @click="search()"
            >Search items</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
  <script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RealEstateListFilterComponent",
  components: {},
  computed: {
    ...mapGetters({
      getToken: "auth/GET_TOKEN",
      getDevelopers: "realEstate/GET_DEVELOPERS",
      getCodes: "realEstate/GET_CODES",
      getNames: "realEstate/GET_PROJECTS",
      getAgentNames: "realEstate/GET_AGENT_NAMES",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
    developers: {
      get: function () {
        return this.getDevelopers;
      },
    },
    codes: {
      get: function () {
        return this.getCodes;
      },
    },
    names: {
      get: function () {
        return this.getNames;
      },
    },
    agentNames: {
      get: function () {
        return this.getAgentNames;
      },
    },
  },
  data: () => ({
    types: [
      { id: 1, name: "Villa/Townhouse" },
      { id: 2, name: "Apartment" },
      { id: 3, name: "Commercial" },
      { id: 4, name: "Plot" },
      { id: 5, name: "Project" },
    ],
    selectedTypes: [],
    selectedCodes: [],
    selectedDevelopers: [],
    selectedNames: [],
    selectedAgentNames: [],
  }),
  methods: {
    ...mapActions({
      getByAdministratorIdFromApi:
        "realEstate/GET_BY_ADMINISTRATOR_ID_FROM_API",
    }),
    async search() {
      await this.getByAdministratorIdFromApi({
        codes: this.selectedCodes,
        types: this.selectedTypes.map((x) => x.id),
        names: this.selectedNames,
        developers: this.selectedDevelopers,
        agentNames: this.selectedAgentNames,
      });
    },
  },
};
</script>