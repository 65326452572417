export default {
    GET_SNACKBAR(state) {
        return state.snackbar;
    },
    GET_TITLE(state) {
        return state.title;
    },
    GET_LANGUAGES(state) {
        return state.languages;
    },
    GET_SELECTED_LANGUAGE(state) {
        if (localStorage.getItem('language') != null) {
            var languageObj = JSON.parse(localStorage.getItem('language'))
            languageObj = { data: languageObj };
            state.selectedLanguage = languageObj.data;
        }
        else {
            localStorage.setItem('language', JSON.stringify({ code: 'en', id: 2, name: 'English' }));
            state.selectedLanguage = { code: 'en', id: 2, name: 'English' };
        }
        return state.selectedLanguage;
    },
}