export default function (instance) {
    return {
        GetById(query) {
            return instance.get('/RealEstate/GetById', { params: query });
        },
        Search(payload) {
            return instance.post(`/RealEstate/Search`, payload);
        },
        GetByAdministratorId(payload) {
            return instance.post('/RealEstate/GetByAdministratorId', payload);
        },
        Add(payload) {
            return instance.post('/RealEstate/Add', payload);
        },
        Update(payload) {
            return instance.put('/RealEstate/Update', payload);
        },
        Delete(query) {
            return instance.delete('/RealEstate/Delete', { params: query });
        },
        Listing(payload) {
            return instance.post(`/RealEstate/Listing`, payload);
        },
        GetInfo() {
            return instance.get('/RealEstate/GetInfo');
        },
        GetDevelopers() {
            return instance.get('/RealEstate/GetDevelopers');
        },
        GetProjects() {
            return instance.get('/RealEstate/GetProjects');
        },
        GetProjectsCodes() {
            return instance.get('/RealEstate/GetProjectsCodes');
        },
        GetAgentNames() {
            return instance.get('/RealEstate/GetAgentNames');
        },
        GetRealEstates() {
            return instance.get('/RealEstate/GetRealEstates');
        },
        AddListingLink(payload) {
            return instance.post('/RealEstate/AddListingLink', payload);
        },
        GetListingProperties(query) {
            return instance.get('/RealEstate/GetListingProperties', { params: query });
        },
        GetRecent() {
            return instance.get(`/RealEstate/GetRecent`);
        },
    }
}
