import state from './newsState';
import getters from './newsGetters';
import mutations from './newsMutations';
import actions from './newsActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}