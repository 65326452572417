<template>
  <div>
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              elevation="0"
              class="orange white--text rounded-xl text-capitalize font-weight-regular mx-2"
              @click="reset()"
              >Reset</v-btn
            >
            <v-btn
              elevation="0"
              class="grey white--text rounded-xl text-capitalize font-weight-regular mx-2"
              @click="copyLink()"
              >Copy Link</v-btn
            >
            <v-btn
              elevation="0"
              class="blue white--text rounded-xl text-capitalize font-weight-regular"
              @click="search()"
              >Search items</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-text-field
              v-model="copyLinkStr"
              label="Link for copy"
              dense
              filled
              class="rounded-xl"
              hide-details
              readonly
            >
              <template v-slot:append>
                <v-btn
                  elevation="0"
                  class="green white--text rounded-xl text-capitalize font-weight-regular mx-2"
                  @click="copyResultLink()"
                  >Copy</v-btn
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- Price -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl" height="100%">
              <v-card-title class="blue white--text">
                <span>Price</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="price.min"
                      type="number"
                      :min="0"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Min</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="price.max"
                      type="number"
                      :min="0"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Max</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Price end -->

          <!-- Bedrooms -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl" height="100%">
              <v-card-title class="blue white--text">
                <span>Bedrooms</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="bedroom in bedrooms"
                      :key="bedroom.num"
                      :value="bedroom.selected"
                      :label="bedroom.text"
                      v-model="bedroom.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Bedrooms end -->

          <!-- Bathrooms -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl" height="100%">
              <v-card-title class="blue white--text">
                <span>Bathrooms</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="bathroom in bathrooms"
                      :key="bathroom.num"
                      :value="bathroom.selected"
                      :label="bathroom.text"
                      v-model="bathroom.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Bathrooms end -->

          <!-- Total area -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl" height="100%">
              <v-card-title class="blue white--text">
                <span>Total area</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="totalArea.min"
                      type="number"
                      :min="0"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Min</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="totalArea.max"
                      type="number"
                      :min="0"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Max</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Total area end -->

          <!-- Distance to sea -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="50vh">
              <v-card-title class="blue white--text">
                <span>Distance to sea</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="distanceToSea.min"
                      type="number"
                      :min="0"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Min</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="distanceToSea.max"
                      type="number"
                      :min="0"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Max</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Distance to sea end -->

          <!-- Included properties -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="50vh">
              <v-card-title class="blue white--text">
                <span>Included Properties</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="ip in includedProperties"
                      :key="ip.name"
                      :value="ip.selected"
                      :label="ip.name"
                      v-model="ip.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Included properties end -->

          <!-- Main type -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="50vh">
              <v-card-title class="blue white--text">
                <span>Main type</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="mainType in mainTypes"
                      :key="mainType.text"
                      :value="mainType.selected"
                      :label="mainType.text"
                      v-model="mainType.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Main type -->

          <!-- Region -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="50vh">
              <v-card-title class="blue white--text">
                <span>Region</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="region in regions"
                      :key="region.id"
                      :value="region.selected"
                      :label="region.name"
                      v-model="region.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Region end -->

          <!-- Developers -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="40vh">
              <v-card-title class="blue white--text">
                <span>Developers</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="(developer, index) in developers"
                      :key="index"
                      :value="developer.selected"
                      :label="developer.name"
                      v-model="developer.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Developers end -->

          <!-- Projects -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="40vh">
              <v-card-title class="blue white--text">
                <span>Projects</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="(project, index) in projects"
                      :key="index"
                      :value="project.selected"
                      :label="project.name"
                      v-model="project.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Projects end -->

          <!-- Codes -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="40vh">
              <v-card-title class="blue white--text">
                <span>Codes</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-for="(code, index) in codes"
                      :key="index"
                      :value="code.selected"
                      :label="code.name"
                      v-model="code.selected"
                      color="blue"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Codes end -->

          <!-- Date -->
          <v-col cols="12" md="3" lg="3" xl="3">
            <v-card outlined class="rounded-xl overflow-auto" height="40vh">
              <v-card-title class="blue white--text">
                <span>Delivery date</span>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      filled
                      class="rounded-xl"
                      hide-details
                      v-model="dateReportFrom"
                      type="number"
                      :min="0"
                      @input="dateReportFrom = $event !== '' ? $event : null"
                      @wheel.stop.prevent
                    >
                      <template v-slot:prepend>
                        <span>Year</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Date end -->
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              small
              elevation="0"
              class="blue white--text rounded-xl text-capitalize font-weight-regular"
              @click="resetMap()"
              >Reset map</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card
              elevation="0"
              color="grey lighten-4"
              class="rounded-xl"
              height="100%"
            >
              <div id="map" style="height: 80vh; width: 100%"></div>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="my-2" />
        <v-row v-if="(items != null && items.length > 0) || items.data">
          <v-col
            cols="12"
            v-for="groupedItem in groupedItems.filter(
              (x) => x.parentId != null
            )"
            :key="'parent_h_' + groupedItem.parentId"
          >
            <!-- Has ParentId -->
            <v-card outlined class="rounded-lg grey lighten-2">
              <ListingItemTemplate
                v-for="item in groupedItem.items"
                :key="'parent_c_' + item.id"
                :logo="item.imageLink"
                :name="item.name"
                :location="item.region.name"
                :to="`/real-estate/${item.id}`"
                :id="item.id"
                :cardColor="
                  disabledItems.some((x) => x == item.id)
                    ? '#E0E0E0'
                    : '#E3F2FD'
                "
                :code="item.code"
                :developer="item.developerName"
                :distanceToSea="item.distanceToSea"
                :constructionYear="item.constructionYear"
                :reconstructionYear="item.reconstructionYear"
                :yearOfDelivery="item.yearOfDelivery"
                :apartments="item.apartments"
                :isClient="false"
                :price="item.price"
                :representative="item.developerRepresentative"
                :phone="item.developerPhone"
                :email="item.developerEmail"
                :comission="item.comission"
              />
            </v-card>
          </v-col>
          <!-- Has ParentId  end-->

          <!-- Not ParentId -->
          <v-col
            cols="12"
            v-for="groupedItem in groupedItems.filter(
              (x) => x.parentId == null
            )"
            :key="'not_parent_h_' + groupedItem.parentId"
          >
            <v-card
              elevation="0"
              v-for="item in groupedItem.items"
              :key="'not_parent_c_' + item.id"
            >
              <ListingItemTemplate
                :logo="item.imageLink"
                :name="item.name"
                :location="item.region.name"
                :to="`/real-estate/${item.id}`"
                :id="item.id"
                :cardColor="
                  disabledItems.some((x) => x == item.id)
                    ? '#E0E0E0'
                    : '#E3F2FD'
                "
                :code="item.code"
                :developer="item.developerName"
                :distanceToSea="item.distanceToSea"
                :constructionYear="item.constructionYear"
                :reconstructionYear="item.reconstructionYear"
                :yearOfDelivery="item.yearOfDelivery"
                :apartments="item.apartments"
                :isClient="false"
                :price="item.price"
                :representative="item.developerRepresentative"
                :phone="item.developerPhone"
                :email="item.developerEmail"
                :comission="item.comission"
              />
            </v-card>
          </v-col>
          <!-- Not ParentId end -->
        </v-row>
        <!-- List end -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ListingItemTemplate from "../Templates/ListingItemTemplate.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ListingFilterComponent",
  components: { ListingItemTemplate },
  computed: {
    ...mapGetters({
      getItems: "realEstate/GET_ITEMS",
      getToken: "auth/GET_TOKEN",
      getRealEstateInfo: "realEstate/GET_INFO",
      getDevelopers: "realEstate/GET_DEVELOPERS",
      getProjects: "realEstate/GET_PROJECTS",
      getCodes: "realEstate/GET_CODES",
      getDisabledItems: "realEstate/GET_DISABLED_ITEMS",
      getListingLink: "realEstate/GET_LISTING_LINK",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    token: {
      get: function () {
        return this.getToken;
      },
    },
    realEstateInfo: {
      get: function () {
        return this.getRealEstateInfo;
      },
    },
    realEstateDevelopers: {
      get: function () {
        return this.getDevelopers;
      },
    },
    realEstateProjects: {
      get: function () {
        return this.getProjects;
      },
    },
    realEstateCodes: {
      get: function () {
        return this.getCodes;
      },
    },
    disabledItems: {
      get: function () {
        return this.getDisabledItems;
      },
      set: function (newVal) {
        this.setDisabledItems(newVal);
      },
    },
    listingLink: {
      get: function () {
        return this.getListingLink;
      },
    },
  },
  data: () => ({
    copyLinkStr: null,
    polygon: null,
    coordinates: null,
    markers: [],
    price: {
      min: 1,
      max: 350000,
    },
    bedrooms: [
      { num: 1, text: "1", selected: false },
      { num: 2, text: "2", selected: false },
      { num: 3, text: "3", selected: false },
      { num: 4, text: "4", selected: false },
      { num: 5, text: "5", selected: false },
      { num: 6, text: "6", selected: false },
      { num: 7, text: "7", selected: false },
      { num: 8, text: "8", selected: false },
    ],
    bathrooms: [
      { num: 1, text: "1", selected: false },
      { num: 2, text: "2", selected: false },
      { num: 3, text: "3", selected: false },
      { num: 4, text: "4", selected: false },
      { num: 5, text: "5", selected: false },
      { num: 6, text: "6", selected: false },
      { num: 7, text: "7", selected: false },
      { num: 8, text: "8", selected: false },
    ],
    totalArea: {
      min: 0,
      max: 62000,
    },
    mainTypes: [
      { id: 1, text: "Villa/Townhouse", selected: false },
      { id: 2, text: "Apartment", selected: false },
      { id: 3, text: "Commercial", selected: false },
      { id: 4, text: "Plot", selected: false },
      { id: 5, text: "Project", selected: false },
    ],
    regions: [
      { id: 2, name: "Limassol", selected: false },
      { id: 3, name: "Paraklisi", selected: false },
      { id: 4, name: "Ypsonas", selected: false },
      { id: 5, name: "Episkopi", selected: false },
      { id: 6, name: "Paphos", selected: false },
      { id: 7, name: "Polis", selected: false },
      { id: 8, name: "Latchi", selected: false },
      { id: 9, name: "Larnaca", selected: false },
      { id: 10, name: "Zigi", selected: false },
      { id: 11, name: "Mazotos", selected: false },
      { id: 12, name: "Livadia", selected: false },
      { id: 13, name: "Oroklini", selected: false },
      { id: 14, name: "Pila", selected: false },
      { id: 15, name: "Ayia Napa", selected: false },
      { id: 16, name: "Protaras", selected: false },
      { id: 17, name: "Paralimni", selected: false },
      { id: 18, name: "Nicosia", selected: false },
    ],
    distanceToSea: {
      min: 0,
      max: 62000,
    },
    projects: [],
    developers: [],
    codes: [],
    dateReportFrom: null,
    paramObj: null,
    includedProperties: [
      { id: 1, name: "Swimming pool", selected: false },
      { id: 2, name: "Storage", selected: false },
      { id: 3, name: "Parking/Garage", selected: false },
      { id: 4, name: "Gym", selected: false },
      { id: 5, name: "Security 24/7", selected: false },
      { id: 6, name: "Beauty centers", selected: false },
      { id: 7, name: "Food court", selected: false },
      { id: 8, name: "Garden", selected: false },
      { id: 9, name: "Golf field ", selected: false },
    ],
    groupedItems: [],
  }),
  methods: {
    ...mapActions({
      listingFromApi: "realEstate/LISTING_FROM_API",
      generateSnackbar: "common/GENERATE_SNACKBAR",
      commitDisabledItems: "realEstate/COMMIT_DISABLED_ITEMS",
      addListingLinkFromApi: "realEstate/ADD_LISTING_LINK_FROM_API",
    }),
    ...mapMutations({
      setItems: "realEstate/SET_ITEMS",
      setDisabledItems: "realEstate/SET_DISABLED_ITEMS",
    }),
    async search() {
      await this.listingFromApi({
        minPrice: this.price.min,
        maxPrice: this.price.max,
        minTotalArea: this.totalArea.min,
        maxTotalArea: this.totalArea.max,
        minDistanceToSea: this.distanceToSea.min,
        maxDistanceToSea: this.distanceToSea.max,
        bedrooms: this.bedrooms.filter((x) => x.selected).map((x) => x.num),
        bathrooms: this.bathrooms.filter((x) => x.selected).map((x) => x.num),
        includedProperties: this.includedProperties
          .filter((x) => x.selected)
          .map((x) => x.id),
        types: this.mainTypes.filter((x) => x.selected).map((x) => x.id),
        regions: this.regions.filter((x) => x.selected).map((x) => x.id),
        projects: this.projects.filter((x) => x.selected).map((x) => x.name),
        developers: this.developers
          .filter((x) => x.selected)
          .map((x) => x.name),
        codes: this.codes.filter((x) => x.selected).map((x) => x.name),
        projectDeliveryDate:
          this.dateReportFrom != null ? this.dateReportFrom : null,
        disabledItems: this.disabledItems,
      });

      if (this.items != null) {
        this.markers = this.items.data.map((x) => ({
          position: {
            lat: x.latitude,
            lng: x.longitude,
          },
          text: x.code,
          id: x.id,
        }));

        this.initMap();
      }
    },
    initRealEstateInfo() {
      if (this.realEstateInfo != null && this.paramObj == null) {
        if (this.realEstateInfo.data.maxPrice != 0) {
          this.price.min =
            this.realEstateInfo.data.minPrice ==
            this.realEstateInfo.data.maxPrice
              ? 0
              : this.realEstateInfo.data.minPrice;
          this.price.max = this.realEstateInfo.data.maxPrice ?? 0;
        }

        if (this.realEstateInfo.data.maxTotalArea != 0) {
          this.totalArea.min =
            this.realEstateInfo.data.minTotalArea ==
            this.realEstateInfo.data.maxTotalArea
              ? 0
              : this.realEstateInfo.data.minTotalArea;
          this.totalArea.max = this.realEstateInfo.data.maxTotalArea ?? 0;
        }

        if (this.realEstateInfo.data.maxDistanceToSea != 0) {
          this.distanceToSea.min =
            this.realEstateInfo.data.minDistanceToSea ==
            this.realEstateInfo.data.maxDistanceToSea
              ? 0
              : this.realEstateInfo.data.minDistanceToSea;
          this.distanceToSea.max = this.realEstateInfo.data.maxDistanceToSea;
        }
      }
    },
    loadGoogleMapsScript() {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAi75SJfWsjxATOtqrjPLpK4DNkLlFJ_CQ&libraries=drawing";
      script.async = true;
      script.onload = this.initMap;
      document.head.appendChild(script);
    },
    initMap() {
      let map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 35.055702, lng: 33.212659 },
        zoom: 9,
      });

      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
          fillColor: "#2196F3",
          strokeColor: "#263238",
        },
      });

      drawingManager.setMap(map);

      var currentState = this;

      window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function (event) {
          if (currentState.polygon) {
            currentState.polygon.setMap(null);
          }
          if (event.type == "polygon") {
            currentState.polygon = event.overlay;
            const polygon = event.overlay;
            const path = polygon.getPath().getArray();
            const coordinates = path.map((x) => ({
              lat: x.lat(),
              lng: x.lng(),
            }));
            currentState.coordinates = coordinates;
          }
        }
      );

      if (this.coordinates != null) {
        this.polygon = new window.google.maps.Polygon({
          paths: this.coordinates,
          map: map,
          editable: true,
          draggable: true,
          fillColor: "#2196F3",
          strokeColor: "#263238",
        });
      }

      const infoWindow = new window.google.maps.InfoWindow();

      for (let i = 0; i < this.markers.length; i++) {
        let marker = new window.google.maps.Marker({
          position: this.markers[i].position,
          map: map,
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 30,
            fillColor: "#2196F3",
            fillOpacity: 1.0,
            strokeWeight: 2,
            strokeOpacity: 1,
            strokeColor: "white",
          },
          label: {
            text: this.markers[i].text,
            color: "white",
            fontSize: "12px",
          },
        });

        marker.addListener("click", () => {
          const position = marker.getPosition();
          const lat = position.lat().toFixed(6);
          const lng = position.lng().toFixed(6);
          const content = `
        <div>
          <button style="background-color: #2196F3; color: white; padding: 5px" onclick="window.open('https://www.google.com/maps?q=${lat},${lng}', '_blank')">Открыть в Google</button>
        </div>
      `;
          infoWindow.setContent(content);
          infoWindow.open(map, marker);
        });

        if (this.polygon != null) {
          if (
            window.google.maps.geometry.poly.containsLocation(
              marker.getPosition(),
              this.polygon
            )
          ) {
            marker.icon.fillColor = "#2196F3";
          } else {
            marker.icon.fillColor = "#9E9E9E";
            this.disabledItems.push(this.markers[i].id);
          }
        }
      }
    },
    async resetMap() {
      this.coordinates = null;
      this.polygon = null;
      this.commitDisabledItems([]);

      await this.search();

      this.initMap();
    },
    async copyLink() {
      let obj = {
        minPrice: this.price.min,
        maxPrice: this.price.max,
        minTotalArea: this.totalArea.min,
        maxTotalArea: this.totalArea.max,
        minDistanceToSea: this.distanceToSea.min,
        maxDistanceToSea: this.distanceToSea.max,
        bedrooms: this.bedrooms.filter((x) => x.selected).map((x) => x.num),
        bathrooms: this.bathrooms.filter((x) => x.selected).map((x) => x.num),
        includedProperties: this.includedProperties
          .filter((x) => x.selected)
          .map((x) => x.id),
        types: this.mainTypes.filter((x) => x.selected).map((x) => x.id),
        regions: this.regions.filter((x) => x.selected).map((x) => x.id),
        projects: this.projects.filter((x) => x.selected).map((x) => x.name),
        developers: this.developers
          .filter((x) => x.selected)
          .map((x) => x.name),
        codes: this.codes.filter((x) => x.selected).map((x) => x.name),
        projectDeliveryDate:
          this.dateReportFrom != null ? this.dateReportFrom : null,
        disabledItems: this.disabledItems,
      };

      await this.addListingLinkFromApi({
        objectJson: JSON.stringify(obj),
      });
      if (this.listingLink != null) {
        const currentUrl = window.location.href;
        const urlObject = new URL(currentUrl);
        const host = urlObject.host;
        const protocol = urlObject.protocol;
        const resultLink =
          protocol + "//" + host + "/listing-client/" + this.listingLink.data;

        this.copyLinkStr = resultLink;
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Error!",
        });
      }
    },
    async copyResultLink() {
      const type = "text/plain";
      const blob = new Blob([this.copyLinkStr], { type });
      const clipboardItem = [new ClipboardItem({ [type]: blob })];

      await navigator.clipboard.write(clipboardItem).then(() => {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Link in copied",
        });
      });
    },
    initParams() {
      try {
        this.paramObj = JSON.parse(this.$route.params.paramObj);
      } catch {
        this.paramObj = null;
      }

      if (this.paramObj != null) {
        this.price = {
          min: this.paramObj.minPrice,
          max: this.paramObj.maxPrice,
        };

        this.bedrooms.forEach((elem) => {
          if (this.paramObj.bedrooms.includes(elem.num)) {
            elem.selected = true;
          }
        });

        this.bathrooms.forEach((elem) => {
          if (this.paramObj.bathrooms.includes(elem.num)) {
            elem.selected = true;
          }
        });

        this.totalArea = {
          min: this.paramObj.minTotalArea,
          max: this.paramObj.maxTotalArea,
        };

        this.includedProperties.forEach((elem) => {
          if (this.paramObj.includedProperties.includes(elem.id)) {
            elem.selected = true;
          }
        });

        this.mainTypes.forEach((elem) => {
          if (this.paramObj.types.includes(elem.id)) {
            elem.selected = true;
          }
        });

        this.regions.forEach((elem) => {
          if (this.paramObj.regions.includes(elem.id)) {
            elem.selected = true;
          }
        });

        if (this.paramObj.projectDeliveryDate != null) {
          this.dateReportFrom = this.paramObj.projectDeliveryDate;
        }

        if (
          this.paramObj.disabledItems != null &&
          this.paramObj.disabledItems.length > 0
        ) {
          this.commitDisabledItems(this.paramObj.disabledItems);
        }
      }
    },
    reset() {
      location.reload();
    },
  },
  watch: {
    copyLinkStr: function () {
      navigator.clipboard.writeText(this.copyLinkStr).then(() => {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Link in copied",
        });
      });
    },
    realEstateInfo: function () {
      this.initRealEstateInfo();
    },
    realEstateDevelopers: function () {
      if (this.realEstateDevelopers != null) {
        this.developers = this.realEstateDevelopers.map((x) => ({
          name: x,
          selected: false,
        }));

        if (this.paramObj != null) {
          this.developers.forEach((elem) => {
            if (this.paramObj.developers.includes(elem.name)) {
              elem.selected = true;
            }
          });
        }
      }
    },
    realEstateCodes: function () {
      if (this.realEstateCodes != null) {
        this.codes = this.realEstateCodes.map((x) => ({
          name: x,
          selected: false,
        }));

        if (this.paramObj != null) {
          this.codes.forEach((elem) => {
            if (this.paramObj.codes.includes(elem.name)) {
              elem.selected = true;
            }
          });
        }
      }
    },
    realEstateProjects: function () {
      if (this.realEstateProjects != null) {
        this.projects = this.realEstateProjects.map((x) => ({
          name: x,
          selected: false,
        }));

        if (this.paramObj != null) {
          this.projects.forEach((elem) => {
            if (this.paramObj.projects.includes(elem.name)) {
              elem.selected = true;
            }
          });
        }
      }
    },
    items: function () {
      const groupedByParentId = Object.values(
        this.items.data.reduce((acc, obj) => {
          const key = obj.parentId;
          if (!acc[key]) {
            acc[key] = { parentId: key, items: [] };
          }
          acc[key].items.push(obj);
          return acc;
        }, {})
      );

      this.groupedItems = groupedByParentId;
    },
  },
  async mounted() {
    this.initRealEstateInfo();
    this.loadGoogleMapsScript();

    if (this.realEstateDevelopers != null) {
      this.developers = this.realEstateDevelopers.map((x) => ({
        name: x,
        selected: false,
      }));

      if (this.paramObj != null) {
        this.developers.forEach((elem) => {
          if (this.paramObj.developers.includes(elem.name)) {
            elem.selected = true;
          }
        });
      }
    }

    if (this.realEstateCodes != null) {
      this.codes = this.realEstateCodes.map((x) => ({
        name: x,
        selected: false,
      }));
    }

    if (this.realEstateProjects != null) {
      this.projects = this.realEstateProjects.map((x) => ({
        name: x,
        selected: false,
      }));
    }

    this.initParams();

    await this.search();
  },
};
</script>