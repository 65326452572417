export function formatNumber(value) {
  if (value === undefined || value === null) return '0';
  let num = parseFloat(value).toFixed(2);  // Округление до двух десятичных знаков
  let parts = num.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // Удаление десятичной части, если она равна "00"
  if (parts[1] === "00") {
    return parts[0];
  }
  return parts.join(',');
}