export default {
    snackbar: {
        show: false,
        timeout: 3000,
        color: 'blue-grey darken-3',
        text: ''
    },
    title: 'Valstand',
    languages: null,
    selectedLanguage: null,
}