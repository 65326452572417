import index from '../api/index'

export const Add = (query) => {
    return new Promise((resolve) => {
        resolve(index.news.Add(query))
    })
}

export const GetAll = (query) => {
    return new Promise((resolve) => {
        resolve(index.news.GetAll(query))
    })
}

export const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(index.news.GetById(query))
    })
}

export const Update = (query) => {
    return new Promise((resolve) => {
        resolve(index.news.Update(query))
    })
}

export const Delete = (query) => {
    return new Promise((resolve) => {
        resolve(index.news.Delete(query))
    })
}