<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-container class="pa-4">
        <v-row>
          <v-col cols="12">
            <span class="text-h6">Welcome to Valstand!</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >We value your privacy and transparency. Please note:
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >1)
              <span class="font-weight-bold">No Personal Data Collection:</span
              >We do not collect any cookies or personal data.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >2) <span class="font-weight-bold">Third-Party Links:</span> Our
              website includes links to third-party services, which have their
              own privacy policies.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >3) <span class="font-weight-bold">Your Consent:</span> By
              continuing to use our site, you consent to our Privacy Policy.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >For more details, please review our full Privacy Policy below.
            </span>
          </v-col>
        </v-row>

        <v-divider class="my-2" />

        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold"
              >Privacy Policy for Valstand
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2">Last updated: 17.07.2024 </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >At Valstand, accessible from
              <a href="https://valstandgroup.com/home"
                >https://valstandgroup.com/home</a
              >, one of our main priorities is the privacy of our visitors. This
              Privacy Policy document contains types of information that is not
              collected and recorded by Valstand and how we use it.</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >If you have additional questions or require more information
              about our Privacy Policy, do not hesitate to contact us.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold">Consent </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >By using our website, you hereby consent to our Privacy Policy
              and agree to its terms.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold"
              >Information we do not collect</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >Valstand is committed to protecting your privacy. We do not
              collect any personal information, cookies, or tracking data of any
              kind. As a visitor, you can browse our site anonymously without
              providing any personal details.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold"
              >Third-Party Privacy Policies
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >Valstand's Privacy Policy does not apply to other advertisers or
              websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >Our website contains links to these third-party services, and we
              want to make it clear that once you use these links to leave our
              site, any information you provide to these third parties is not
              covered by our Privacy Policy. We do not have control over and
              assume no responsibility for the content, privacy policies, or
              practices of any third-party sites or services.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold"
              >Online Privacy Policy Only
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they did not share and/or is not collected by Valstand. This
              policy is not applicable to any information collected offline or
              via channels other than this website.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold"
              >Changes to This Privacy Policy
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >We may update our Privacy Policy from time to time. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="text-body-2 font-weight-bold">Contact Us </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-body-2"
              >If you have any questions about this Privacy Policy, you can
              contact us by email:
              <a href="mailto:info@valstandgroup.com">info@valstandgroup.com</a>
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="acceptPrivacyStatement">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    acceptPrivacyStatement() {
      localStorage.setItem("privacyAccepted", "true");
      this.dialog = false;
    },
  },
};
</script>