import state from './realEstateState';
import getters from './realEstateGetters';
import mutations from './realEstateMutations';
import actions from './realEstateActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}