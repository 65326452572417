export default function (instance) {
    return {
        GetAllGrouped() {
            return instance.get('/Region/GetAllGrouped');
        },
        Search(query) {
            return instance.get('/Region/Search', { params: query });
        },
    }
}
