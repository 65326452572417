<template>
  <HeaderTemplate
    :backgroundImage="
      require($vuetify.breakpoint.smAndDown
        ? '@/assets/images/re-header-min.png'
        : '@/assets/images/re-header.png')
    "
    :textColor="'white'"
    :logo="require('@/assets/images/logo.png')"
    :hasText="false"
    :hasRealEstateFilters="true"
    :navColor="'white'"
  />
</template>
<script>
import HeaderTemplate from "../Templates/HeaderTemplate.vue";
export default {
  name: "RealEstateHeaderComponent",
  components: {
    HeaderTemplate,
  },
  data: () => ({}),
};
</script>