<template>
  <v-card class="rounded-xl ma-2" outlined :color="cardColor">
    <v-row>
      <v-col cols="4" @click="openItem()">
        <v-img :src="logo" height="100%" class="text-right rounded-l-xl" />
      </v-col>
      <v-col cols="8">
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row no-gutters @click="openItem()">
                <v-col cols="12">&nbsp;</v-col>
              </v-row>
              <v-row no-gutters @click="openItem()">
                <v-col cols="12">
                  <v-btn
                    small
                    outlined
                    class="mr-2 font-weight-medium"
                    color="blue"
                    >{{ code }}</v-btn
                  >
                  <span class="text-h5 font-weight-regular" v-if="!isClient">{{
                    name
                  }}</span></v-col
                >
              </v-row>
              <v-row @click="openItem()">
                <v-col cols="12">
                  <span class="text-body-1 font-weight-medium blue--text"
                    >{{
                      selectedLanguage.id == 1 ? "Статус" : "Property status"
                    }}:
                  </span>
                  <span class="text-body-1 font-weight-regular">{{
                    reconstructionYear != null
                      ? (selectedLanguage.id == 1
                          ? "Реконструкция - "
                          : "Reconstruction - ") + reconstructionYear
                      : constructionYear != null
                      ? (selectedLanguage.id == 1
                          ? "Строительство - "
                          : "Construction - ") + constructionYear
                      : (selectedLanguage.id == 1
                          ? "Завершение - "
                          : "Delivery - ") + yearOfDelivery
                  }}</span>
                </v-col>
              </v-row>
              <v-row v-if="!isClient">
                <v-col cols="12">
                  <span class="text-body-1 font-weight-medium blue--text"
                    >{{
                      selectedLanguage.id == 1 ? "Застройщик" : "Developer"
                    }}:
                  </span>
                  <!-- Developer dialog -->
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text-body-1 font-weight-regular text-decoration-underline"
                        v-bind="attrs"
                        v-on="on"
                        >{{ developer }}</span
                      >
                    </template>
                    <v-card class="pa-6">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <span
                              class="text-body-1 font-weight-medium blue--text"
                              >{{
                                selectedLanguage.id == 1
                                  ? "Застройщик"
                                  : "Developer"
                              }}:
                            </span>
                            <span class="text-body-1 font-weight-regular">{{
                              developer
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <span
                              class="text-body-1 font-weight-medium blue--text"
                              >{{
                                selectedLanguage.id == 1
                                  ? "Представитель"
                                  : "Representative"
                              }}:
                            </span>
                            <span class="text-body-1 font-weight-regular">{{
                              representative
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <span
                              class="text-body-1 font-weight-medium blue--text"
                              >E-Mail:
                            </span>
                            <span class="text-body-1 font-weight-regular">{{
                              email
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <span
                              class="text-body-1 font-weight-medium blue--text"
                              >{{
                                selectedLanguage.id == 1 ? "Телефон" : "Phone"
                              }}:
                            </span>
                            <span class="text-body-1 font-weight-regular">{{
                              phone
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <span
                              class="text-body-1 font-weight-medium blue--text"
                              >{{
                                selectedLanguage.id == 1
                                  ? "Комиссия"
                                  : "Comission"
                              }}:
                            </span>
                            <span class="text-body-1 font-weight-regular">{{
                              comission
                            }}</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <!-- Developer dialog end -->
                </v-col>
              </v-row>
              <v-row @click="openItem()">
                <v-col cols="12">
                  <span class="text-body-1 font-weight-medium blue--text"
                    >{{
                      selectedLanguage.id == 1 ? "Местоположение" : "Location"
                    }}: </span
                  ><span class="text-body-1 font-weight-regular">{{
                    location
                  }}</span></v-col
                >
              </v-row>
              <v-row @click="openItem()" v-if="price != null && price != 0">
                <v-col cols="12">
                  <span class="text-body-1 font-weight-medium blue--text"
                    >{{ selectedLanguage.id == 1 ? "Цена" : "Price" }}: </span
                  ><span class="text-body-1 font-weight-regular"
                    >{{ price | formatNumber }} €</span
                  ></v-col
                >
              </v-row>
              <v-row no-gutters v-if="!isClient">
                <v-col cols="12" class="text-right">
                  <v-btn
                    elevation="0"
                    x-small
                    class="red lighten-1 white--text text-capitalize"
                    @click="disableItem()"
                    v-if="!disabledItems.some((x) => x == this.id)"
                  >
                    {{ selectedLanguage.id == 1 ? "Отключить" : "Disable" }}:
                  </v-btn>
                  <v-btn
                    elevation="0"
                    x-small
                    class="green lighten-1 white--text text-capitalize"
                    @click="enableItem()"
                    v-if="disabledItems.some((x) => x == this.id)"
                  >
                    {{ selectedLanguage.id == 1 ? "Включить" : "Enable" }}:
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      v-if="apartments != null && apartments.length > 0"
      class="ma-4 overflow-x-auto"
    >
      <v-col cols="12">
        <v-simple-table class="rounded-lg">
          <template v-slot:default>
            <thead>
              <tr class="blue">
                <th class="text-left white--text rounded-tl-lg">
                  {{ selectedLanguage.id == 1 ? "Тип" : "Type" }}:
                </th>
                <th class="text-left white--text">
                  {{ selectedLanguage.id == 1 ? "Спальни" : "Bedrooms" }}
                </th>
                <th class="text-left white--text">
                  {{ selectedLanguage.id == 1 ? "Площадь" : "Area" }}
                </th>
                <th class="text-left white--text">
                  {{ selectedLanguage.id == 1 ? "Цена" : "Price" }}
                </th>
                <th class="text-left white--text">
                  {{
                    selectedLanguage.id == 1
                      ? "Вид из окна"
                      : "Windows overlooks"
                  }}
                </th>
                <th class="text-left white--text">
                  {{
                    selectedLanguage.id == 1
                      ? "Климат-контроль"
                      : "Cooling/heating"
                  }}
                </th>
                <th class="text-left white--text rounded-tr-lg">
                  {{
                    selectedLanguage.id == 1
                      ? "Первичная/Вторчиная"
                      : "New/Resale"
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="apart in apartments" :key="apart.id">
                <td class="text-left">
                  {{
                    mainTypes.find((x) => x.id == apart.realEstateTypeId)[
                      selectedLanguage.id == 1 ? "textRu" : "text"
                    ]
                  }}
                </td>
                <td class="text-left">
                  {{ apart.bedroomsQuantity }}
                </td>
                <td class="text-left">
                  <div v-if="apart.realEstateTypeId == 4">
                    {{ apart.square | formatNumber }}m2
                  </div>
                  <div v-else>
                    <div>
                      <v-icon left small>mdi-square-circle-outline</v-icon>
                      {{ apart.internalCoveredArea | formatNumber }}m2
                    </div>
                    <div>
                      <v-icon left small>mdi-bus-stop-uncovered</v-icon>
                      {{ apart.coveredVerandArea | formatNumber }}m2
                    </div>
                    <div>
                      <v-icon left small>mdi-bus-stop-covered</v-icon>
                      {{ apart.uncoveredVerandArea | formatNumber }}m2
                    </div>
                  </div>
                </td>
                <td class="text-left">{{ apart.price | formatNumber }} €</td>
                <td class="text-left">
                  {{
                    apart.realEstateTypeId == 4
                      ? ""
                      : windowsOverlooks.find(
                          (z) => z.id == apart.windowsOverlookId
                        )[selectedLanguage.id == 1 ? "nameRu" : "name"]
                  }}
                </td>
                <td class="text-left">
                  {{
                    apart.realEstateTypeId == 4
                      ? ""
                      : conditionings
                          .filter((z) => apart.condigitionings.includes(z.id))
                          .map(
                            (z) =>
                              z[selectedLanguage.id == 1 ? "nameRu" : "name"]
                          )
                          .join()
                  }}
                </td>
                <td class="text-left">
                  {{
                    apart.realEstateTypeId == 4
                      ? ""
                      : primaries.find((z) => z.id == apart.primaryId)[
                          selectedLanguage.id == 1 ? "nameRu" : "name"
                        ]
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListingItemTemplate",
  props: {
    logo: String,
    name: String,
    location: String,
    to: String,
    id: Number,
    cardColor: String,
    code: String,
    developer: String,
    distanceToSea: Number,
    constructionYear: Number,
    reconstructionYear: Number,
    yearOfDelivery: Number,
    apartments: Array,
    isClient: Boolean,
    price: Number,
    representative: String,
    email: String,
    phone: String,
    comission: String,
  },
  components: {},
  computed: {
    ...mapGetters({
      getDisabledItems: "realEstate/GET_DISABLED_ITEMS",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    disabledItems: {
      get: function () {
        return this.getDisabledItems;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    energyEfficiencies: [
      {
        id: 1,
        name: "Class A",
        nameRu: "Класс A",
      },
      {
        id: 2,
        name: "Class B",
        nameRu: "Класс B",
      },
      {
        id: 3,
        name: "Class C",
        nameRu: "Класс C",
      },
      {
        id: 4,
        name: "Class D",
        nameRu: "Класс D",
      },
      {
        id: 5,
        name: "Unspecified",
        nameRu: "Уточнить",
      },
    ],
    windowsOverlooks: [
      { id: 1, name: "Sea/river view", nameRu: "Вид на море" },
      { id: 2, name: "Mountain view", nameRu: "Вид на горы" },
      { id: 3, name: "City view", nameRu: "Вид на город" },
      { id: 4, name: "Park view", nameRu: "Парк" },
      { id: 5, name: "Nature view", nameRu: "Природа" },
      { id: 6, name: "Unspecified", nameRu: "Уточнить" },
    ],

    conditionings: [
      { id: 1, name: "A/C", nameRu: "Кондиционер" },
      { id: 2, name: "Heating", nameRu: "Отопление" },
      { id: 3, name: "Underfloor heating", nameRu: "Теплые полы" },
      {
        id: 4,
        name: "Provision for A/C",
        nameRu: "Провизии  для кондиционера",
      },
      {
        id: 5,
        name: "Provision for heating systems",
        nameRu: "Провизии для отопления",
      },
      {
        id: 6,
        name: "Provision for underfloor heating",
        nameRu: "Провизии для теплых полов",
      },
    ],

    primaries: [
      { id: 1, name: "New", nameRu: "Первчиная" },
      { id: 2, name: "Resale", nameRu: "Вторичная" },
    ],
    mainTypes: [
      {
        id: 1,
        text: "Villa/Townhouse",
        textRu: "Вилла/Таунхаус",
        selected: false,
      },
      { id: 2, text: "Apartment", textRu: "Апартаменты", selected: false },
      {
        id: 3,
        text: "Commercial",
        textRu: "Коммерческая недвижимость",
        selected: false,
      },
      { id: 4, text: "Plot", textRu: "Земельный участок", selected: false },
      {
        id: 5,
        text: "Project",
        textRu: "Многоквартирный объект",
        selected: false,
      },
    ],
  }),
  methods: {
    ...mapActions({
      disableItemFromApi: "realEstate/DISABLE_ITEM_FROM_API",
      enableItemFromApi: "realEstate/ENABLE_ITEM_FROM_API",
    }),
    openItem() {
      this.$router.push(this.to);
    },
    disableItem() {
      this.disableItemFromApi(this.id);
    },
    enableItem() {
      this.enableItemFromApi(this.id);
    },
  },
};
</script>