<template>
  <div>
    <Header />
    <Filter1 />
  </div>
</template>
<script>
import Header from "../../components/News/NewsHeaderComponent.vue";
import Filter1 from "../../components/Listing/FilterComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListingView",
  components: {
    Header,
    Filter1,
  },
  computed: {
    ...mapGetters({}),
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getProjectsFromApi: "realEstate/GET_PROJECTS_FROM_API",
      getDevelopersFromApi: "realEstate/GET_DEVELOPERS_FROM_API",
      getCodesFromApi: "realEstate/GET_CODES_FROM_API",
    }),
  },
  mounted() {
    document.title = "Listing - Valstand";
  },
  async created() {
    await this.getProjectsFromApi();
    await this.getDevelopersFromApi();
    await this.getCodesFromApi();
  },
};
</script>