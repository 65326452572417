<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="11" md="10" lg="8" xl="8">
      <v-row>
        <v-col cols="12">
          <span class="text-h4">Posts</span>
        </v-col>
      </v-row>
      <v-row>
        <!-- Post an ad -->
        <v-col cols="12" md="4" lg="3" xl="3">
          <v-card
            elevation="0"
            color="blue"
            class="rounded-xl pa-2"
            to="/real-estate-add"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <v-icon size="48" color="white">mdi-home-plus</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="font-weight-regular text-h6 white--text"
                  >Post an ad</span
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- Post an ad end -->

        <!-- My ads -->
        <v-col cols="12" md="4" lg="3" xl="3">
          <v-card
            elevation="0"
            color="blue"
            class="rounded-xl pa-2"
            to="/real-estate-admin-items"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <v-icon size="48" color="white">mdi-home-city</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="font-weight-regular text-h6 white--text"
                  >My ads</span
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- My ads end -->
      </v-row>

      <v-row>
        <v-col cols="12">
          <span class="text-h4">News</span>
        </v-col>
      </v-row>
      <v-row>
        <!-- Add news -->
        <v-col cols="12" md="4" lg="3" xl="3">
          <v-card
            elevation="0"
            color="blue"
            class="rounded-xl pa-2"
            to="/news-add"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <v-icon size="48" color="white">mdi-newspaper-plus</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="font-weight-regular text-h6 white--text"
                  >Add news</span
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- Add news end -->
      </v-row>

      <v-row>
        <v-col cols="12">
          <span class="text-h4">Listing</span>
        </v-col>
      </v-row>
      <v-row>
        <!-- Log Out -->
        <v-col cols="12" md="4" lg="3" xl="3">
          <v-card
            elevation="0"
            color="blue"
            class="rounded-xl pa-2"
            to="listing/null"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <v-icon size="48" color="white"
                  >mdi-format-list-bulleted-type</v-icon
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="font-weight-regular text-h6 white--text"
                  >Listing</span
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- Log Out end -->
      </v-row>

      <v-row>
        <v-col cols="12">
          <span class="text-h4">Account</span>
        </v-col>
      </v-row>
      <v-row>
        <!-- Log Out -->
        <v-col cols="12" md="4" lg="3" xl="3">
          <v-card
            elevation="0"
            color="blue"
            class="rounded-xl pa-2"
            @click="logOut()"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <v-icon size="48" color="white">mdi-logout</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="font-weight-regular text-h6 white--text"
                  >Log Out</span
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- Log Out end -->
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "AccountInfoComponent",
  components: {},
  computed: {},
  data: () => ({}),
  methods: {
    logOut() {
      localStorage.removeItem("identity");
      var data = this.$router.resolve({
        path: "/account/login",
      });
      document.location.href = data.href;
    },
  },
  watch: {},
};
</script>