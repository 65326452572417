var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"d-flex justify-center align-center mt-2"},[_c('v-col',{attrs:{"cols":"11","md":"10","lg":"8","xl":"8"}},[((_vm.items != null && _vm.items.length > 0) || _vm.items.data)?_c('v-row',[_vm._l((_vm.groupedItems.filter(
            (x) => x.parentId != null
          )),function(groupedItem){return _c('v-col',{key:'parent_h_' + groupedItem.parentId,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg grey lighten-2",attrs:{"outlined":""}},_vm._l((groupedItem.items),function(item){return _c('ListingItemTemplate',{key:'parent_c_' + item.id,attrs:{"logo":item.imageLink,"name":item.name,"location":item.region.name,"to":`/real-estate/${item.id}`,"id":item.id,"cardColor":_vm.disabledItems.some((x) => x == item.id)
                  ? '#E0E0E0'
                  : '#E3F2FD',"code":item.code,"developer":item.developerName,"distanceToSea":item.distanceToSea,"constructionYear":item.constructionYear,"reconstructionYear":item.reconstructionYear,"yearOfDelivery":item.yearOfDelivery,"apartments":item.apartments,"isClient":true,"price":item.price}})}),1)],1)}),_vm._l((_vm.groupedItems.filter(
            (x) => x.parentId == null
          )),function(groupedItem){return _c('v-col',{key:'not_parent_h_' + groupedItem.parentId,attrs:{"cols":"12"}},_vm._l((groupedItem.items),function(item){return _c('v-card',{key:'not_parent_c_' + item.id,attrs:{"elevation":"0"}},[_c('ListingItemTemplate',{attrs:{"logo":item.imageLink,"name":item.name,"location":item.region.name,"to":`/real-estate/${item.id}`,"id":item.id,"cardColor":_vm.disabledItems.some((x) => x == item.id)
                  ? '#E0E0E0'
                  : '#E3F2FD',"code":item.code,"developer":item.developerName,"distanceToSea":item.distanceToSea,"constructionYear":item.constructionYear,"reconstructionYear":item.reconstructionYear,"yearOfDelivery":item.yearOfDelivery,"apartments":item.apartments,"isClient":true,"price":item.price}})],1)}),1)})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }