import { GetById, Search, GetByAdministratorId, Add, Update, Delete, Listing, GetInfo, GetDevelopers, GetProjects, GetProjectsCodes, GetRealEstates, AddListingLink, GetListingProperties, GetAgentNames, GetRecent } from '../../services/realEstateService';

export default {
    async GET_BY_ID_FROM_API({ commit }, payload) {
        await GetById(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEM', data);
                }
            })
            .catch(() => {
                commit('SET_ITEM', null);
            })
    },
    async SEARCH_FROM_API({ commit }, payload) {
        await Search(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEMS', data);
                }
            })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    SAVE_SEARCH_PARAMS({ commit }, payload) {
        commit('SET_SELECTED_PARAMS', payload);
    },
    async GET_BY_ADMINISTRATOR_ID_FROM_API({ commit }, payload) {
        await GetByAdministratorId(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEMS', data);
                }
            })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    async ADD_ITEM_FROM_API({ commit }, payload) {
        await Add(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async UPDATE_ITEM_FROM_API({ commit }, payload) {
        await Update(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async DELETE_ITEM_FROM_API({ commit }, payload) {
        await Delete(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async LISTING_FROM_API({ commit }, payload) {
        await Listing(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEMS', data);
                }
            })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    async GET_INFO_FROM_API({ commit }, payload) {
        await GetInfo(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_INFO', data);
                }
            })
            .catch(() => {
                commit('SET_INFO', null);
            })
    },
    async GET_DEVELOPERS_FROM_API({ commit }) {
        await GetDevelopers()
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_DEVELOPERS', data);
                }
            })
            .catch(() => {
                commit('SET_DEVELOPERS', null);
            })
    },
    async GET_PROJECTS_FROM_API({ commit }) {
        await GetProjects()
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_PROJECTS', data);
                }
            })
            .catch(() => {
                commit('SET_PROJECTS', null);
            })
    },
    async GET_CODES_FROM_API({ commit }) {
        await GetProjectsCodes()
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_CODES', data);
                }
            })
            .catch(() => {
                commit('SET_CODES', null);
            })
    },
    async GET_AGENT_NAMES_FROM_API({ commit }) {
        await GetAgentNames()
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_AGENT_NAMES', data);
                }
            })
            .catch(() => {
                commit('SET_AGENT_NAMES', null);
            })
    },
    async GET_REAL_ESTATES_FROM_API({ commit }) {
        await GetRealEstates()
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_REAL_ESTATES', data);
                }
            })
            .catch(() => {
                commit('SET_REAL_ESTATES', null);
            })
    },
    ENABLE_ITEM_FROM_API({ commit }, payload) {
        commit('ENABLE_ITEM', payload);
    },
    DISABLE_ITEM_FROM_API({ commit }, payload) {
        commit('DISABLE_ITEM', payload);
    },
    COMMIT_DISABLED_ITEMS({ commit }, payload) {
        commit('SET_DISABLED_ITEMS', payload);
    },
    COMMIT_ITEM_APARTMENTS({ commit }, payload) {
        commit('SET_ITEM_APARTMENTS', payload);
    },
    COMMIT_ITEM_VILLA({ commit }, payload) {
        commit('SET_ITEM_VILLA', payload);
    },
    COMMIT_ITEM_COMMERCIALS({ commit }, payload) {
        commit('SET_ITEM_COMMERCIALS', payload);
    },
    async ADD_LISTING_LINK_FROM_API({ commit }, payload) {
        await AddListingLink(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_LISTING_LINK', data);
            }
        })
            .catch(() => {
                commit('SET_LISTING_LINK', null);
            })
    },
    async GET_LISTING_PROPERTIES_FROM_API({ commit }, payload) {
        await GetListingProperties(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_LISTING_PROPERTIES', data);
            }
        })
            .catch(() => {
                commit('SET_LISTING_PROPERTIES', null);
            })
    },
    COMMIT_ITEM_PLOTS({ commit }, payload) {
        commit('SET_ITEM_PLOTS', payload);
    },
    COMMIT_SELECTED_ANALYSIS_CITY({ commit }, payload) {
        commit('SET_SELECTED_ANALYSIS_CITY', payload);
    },
    async GET_RECENT_ITEMS_FROM_API({ commit }) {
        await GetRecent()
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_RECENT_ITEMS', data);
                }
            })
            .catch(() => {
                commit('SET_RECENT_ITEMS', null);
            })
    },
}