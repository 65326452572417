import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import store from './store/index'
import { formatNumber  } from './plugins/filters'
Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAi75SJfWsjxATOtqrjPLpK4DNkLlFJ_CQ',
    libraries: 'places,drawing'
  }
});

Vue.filter('formatNumber', formatNumber);

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store
}).$mount('#app')
