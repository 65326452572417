export default {
    GET_ITEMS(state) {
        return state.items;
    },
    GET_ITEM(state) {
        return state.item;
    },
    GET_ACTION_STATUS(state) {
        return state.actionStatus;
    },
}