<template>
  <div>
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row v-if="(items != null && items.length > 0) || items.data">
          <v-col
            cols="12"
            v-for="groupedItem in groupedItems.filter(
              (x) => x.parentId != null
            )"
            :key="'parent_h_' + groupedItem.parentId"
          >
            <!-- Has ParentId -->
            <v-card outlined class="rounded-lg grey lighten-2">
              <ListingItemTemplate
                v-for="item in groupedItem.items"
                :key="'parent_c_' + item.id"
                :logo="item.imageLink"
                :name="item.name"
                :location="item.region.name"
                :to="`/real-estate/${item.id}`"
                :id="item.id"
                :cardColor="
                  disabledItems.some((x) => x == item.id)
                    ? '#E0E0E0'
                    : '#E3F2FD'
                "
                :code="item.code"
                :developer="item.developerName"
                :distanceToSea="item.distanceToSea"
                :constructionYear="item.constructionYear"
                :reconstructionYear="item.reconstructionYear"
                :yearOfDelivery="item.yearOfDelivery"
                :apartments="item.apartments"
                :isClient="true"
                :price="item.price"
              />
            </v-card>
          </v-col>
          <!-- Has ParentId  end-->

          <!-- Not ParentId -->
          <v-col
            cols="12"
            v-for="groupedItem in groupedItems.filter(
              (x) => x.parentId == null
            )"
            :key="'not_parent_h_' + groupedItem.parentId"
          >
            <v-card
              elevation="0"
              v-for="item in groupedItem.items"
              :key="'not_parent_c_' + item.id"
            >
              <ListingItemTemplate
                :logo="item.imageLink"
                :name="item.name"
                :location="item.region.name"
                :to="`/real-estate/${item.id}`"
                :id="item.id"
                :cardColor="
                  disabledItems.some((x) => x == item.id)
                    ? '#E0E0E0'
                    : '#E3F2FD'
                "
                :code="item.code"
                :developer="item.developerName"
                :distanceToSea="item.distanceToSea"
                :constructionYear="item.constructionYear"
                :reconstructionYear="item.reconstructionYear"
                :yearOfDelivery="item.yearOfDelivery"
                :apartments="item.apartments"
                :isClient="true"
                :price="item.price"
              />
            </v-card>
          </v-col>
          <!-- Not ParentId end -->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  <script>
import ListingItemTemplate from "../Templates/ListingItemTemplate.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ListingClientComponent",
  components: { ListingItemTemplate },
  computed: {
    ...mapGetters({
      getItems: "realEstate/GET_ITEMS",
      getDevelopers: "realEstate/GET_DEVELOPERS",
      getProjects: "realEstate/GET_PROJECTS",
      getCodes: "realEstate/GET_CODES",
      getDisabledItems: "realEstate/GET_DISABLED_ITEMS",
      getListingProperties: "realEstate/GET_LISTING_PROPERTIES",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    realEstateDevelopers: {
      get: function () {
        return this.getDevelopers;
      },
    },
    realEstateProjects: {
      get: function () {
        return this.getProjects;
      },
    },
    realEstateCodes: {
      get: function () {
        return this.getCodes;
      },
    },
    disabledItems: {
      get: function () {
        return this.getDisabledItems;
      },
    },
    listingProperties: {
      get: function () {
        return this.getListingProperties;
      },
    },
  },
  data: () => ({
    price: {
      min: 1,
      max: 350000,
    },
    bedrooms: [
      { num: 1, text: "1", selected: false },
      { num: 2, text: "2", selected: false },
      { num: 3, text: "3", selected: false },
      { num: 4, text: "4", selected: false },
      { num: 5, text: "5", selected: false },
      { num: 6, text: "6", selected: false },
      { num: 7, text: "7", selected: false },
      { num: 8, text: "8", selected: false },
    ],
    bathrooms: [
      { num: 1, text: "1", selected: false },
      { num: 2, text: "2", selected: false },
      { num: 3, text: "3", selected: false },
      { num: 4, text: "4", selected: false },
      { num: 5, text: "5", selected: false },
      { num: 6, text: "6", selected: false },
      { num: 7, text: "7", selected: false },
      { num: 8, text: "8", selected: false },
    ],
    totalArea: {
      min: 0,
      max: 62000,
    },
    swimmingPool: true,
    storage: true,
    parking: true,
    gym: true,
    mainTypes: [
      { id: 1, text: "Villa/Townhouse", selected: false },
      { id: 2, text: "Apartment", selected: false },
      { id: 3, text: "Commercial", selected: false },
      { id: 4, text: "Plot", selected: false },
      { id: 5, text: "Project", selected: false },
    ],
    regions: [
      { id: 2, name: "Limassol", selected: false },
      { id: 3, name: "Paraklisi", selected: false },
      { id: 4, name: "Ypsonas", selected: false },
      { id: 5, name: "Episkopi", selected: false },
      { id: 6, name: "Paphos", selected: false },
      { id: 7, name: "Polis", selected: false },
      { id: 8, name: "Latchi", selected: false },
      { id: 9, name: "Larnaca", selected: false },
      { id: 10, name: "Zigi", selected: false },
      { id: 11, name: "Mazotos", selected: false },
      { id: 12, name: "Livadia", selected: false },
      { id: 13, name: "Oroklini", selected: false },
      { id: 14, name: "Pila", selected: false },
      { id: 15, name: "Ayia Napa", selected: false },
      { id: 16, name: "Protaras", selected: false },
      { id: 17, name: "Paralimni", selected: false },
      { id: 18, name: "Nicosia", selected: false },
    ],
    distanceToSea: {
      min: 0,
      max: 62000,
    },
    projects: [],
    developers: [],
    codes: [],
    dateReportFrom: null,
    menuReportFrom: false,
    paramObj: null,
    groupedItems: [],
  }),
  methods: {
    ...mapActions({
      listingFromApi: "realEstate/LISTING_FROM_API",
      commitDisabledItems: "realEstate/COMMIT_DISABLED_ITEMS",
      getListingPropertiesFromApi: "realEstate/GET_LISTING_PROPERTIES_FROM_API",
    }),
    ...mapMutations({}),
    async search() {
      await this.listingFromApi({
        minPrice: this.price.min,
        maxPrice: this.price.max,
        minTotalArea: this.totalArea.min,
        maxTotalArea: this.totalArea.max,
        minDistanceToSea: this.distanceToSea.min,
        maxDistanceToSea: this.distanceToSea.max,
        bedrooms: this.bedrooms.filter((x) => x.selected).map((x) => x.num),
        bathrooms: this.bathrooms.filter((x) => x.selected).map((x) => x.num),
        includedProperties: null,
        types: this.mainTypes.filter((x) => x.selected).map((x) => x.id),
        regions: this.regions.filter((x) => x.selected).map((x) => x.id),
        projects: this.projects.filter((x) => x.selected).map((x) => x.name),
        developers: this.developers
          .filter((x) => x.selected)
          .map((x) => x.name),
        codes: this.codes.filter((x) => x.selected).map((x) => x.name),
        projectDeliveryDate:
          this.dateReportFrom != null ? this.dateReportFrom : null,
        disabledItems: this.disabledItems,
      });
    },
    async initParams() {
      try {
        await this.getListingPropertiesFromApi({
          guid: this.$route.params.guid,
        });
        if (this.listingProperties != null) {
          this.paramObj = JSON.parse(this.listingProperties.data);
        }
      } catch {
        this.paramObj = null;
      }

      if (this.paramObj != null) {
        this.price = {
          min: this.paramObj.minPrice,
          max: this.paramObj.maxPrice,
        };

        this.bedrooms.forEach((elem) => {
          if (this.paramObj.bedrooms.includes(elem.num)) {
            elem.selected = true;
          }
        });

        this.bathrooms.forEach((elem) => {
          if (this.paramObj.bathrooms.includes(elem.num)) {
            elem.selected = true;
          }
        });

        this.totalArea = {
          min: this.paramObj.minTotalArea,
          max: this.paramObj.maxTotalArea,
        };

        this.distanceToSea = {
          min: this.paramObj.minDistanceToSea,
          max: this.paramObj.maxDistanceToSea,
        };

        this.mainTypes.forEach((elem) => {
          if (this.paramObj.types.includes(elem.id)) {
            elem.selected = true;
          }
        });

        this.regions.forEach((elem) => {
          if (this.paramObj.regions.includes(elem.id)) {
            elem.selected = true;
          }
        });

        if (this.paramObj.projectDeliveryDate != null) {
          this.dateReportFrom = this.paramObj.projectDeliveryDate;
        }

        if (
          this.paramObj.disabledItems != null &&
          this.paramObj.disabledItems.length > 0
        ) {
          this.commitDisabledItems(this.paramObj.disabledItems);
        }
      }
    },
  },
  watch: {
    realEstateDevelopers: function () {
      if (this.realEstateDevelopers != null) {
        this.developers = this.realEstateDevelopers.map((x) => ({
          name: x,
          selected: false,
        }));

        if (this.paramObj != null) {
          this.developers.forEach((elem) => {
            if (this.paramObj.developers.includes(elem.name)) {
              elem.selected = true;
            }
          });
        }
      }
    },
    realEstateCodes: function () {
      if (this.realEstateCodes != null) {
        this.codes = this.realEstateCodes.map((x) => ({
          name: x,
          selected: false,
        }));

        if (this.paramObj != null) {
          this.codes.forEach((elem) => {
            if (this.paramObj.codes.includes(elem.name)) {
              elem.selected = true;
            }
          });
        }
      }
    },
    realEstateProjects: function () {
      if (this.realEstateProjects != null) {
        this.projects = this.realEstateProjects.map((x) => ({
          name: x,
          selected: false,
        }));

        if (this.paramObj != null) {
          this.projects.forEach((elem) => {
            if (this.paramObj.projects.includes(elem.name)) {
              elem.selected = true;
            }
          });
        }
      }
    },
    items: function () {
      const groupedByParentId = Object.values(
        this.items.data.reduce((acc, obj) => {
          const key = obj.parentId;
          if (!acc[key]) {
            acc[key] = { parentId: key, items: [] };
          }
          acc[key].items.push(obj);
          return acc;
        }, {})
      );

      this.groupedItems = groupedByParentId;
    },
  },
  async mounted() {
    if (this.realEstateDevelopers != null) {
      this.developers = this.realEstateDevelopers.map((x) => ({
        name: x,
        selected: false,
      }));

      if (this.paramObj != null) {
        this.developers.forEach((elem) => {
          if (this.paramObj.developers.includes(elem.name)) {
            elem.selected = true;
          }
        });
      }
    }

    if (this.realEstateCodes != null) {
      this.codes = this.realEstateCodes.map((x) => ({
        name: x,
        selected: false,
      }));
    }

    if (this.realEstateProjects != null) {
      this.projects = this.realEstateProjects.map((x) => ({
        name: x,
        selected: false,
      }));
    }

    await this.initParams();

    await this.search();
  },
};
</script>