<template>
  <div>
    <Update />
  </div>
</template>
<script>
import Update from "../../components/News/NewsUpdateComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "NewsUpdateView",
  components: {
    Update,
  },
  data: () => ({
    newsId: 0,
  }),
  methods: {
    ...mapActions({
      getByIdFromApi: "news/GET_BY_ID_FROM_API",
    }),
  },
  mounted() {
    document.title = "News - Valstand";
  },
  async created() {
    this.newsId = parseInt(this.$route.params.id, 10);
    await this.getByIdFromApi({
      id: this.newsId,
    });
  },
};
</script>