import { Search } from '../../services/regionService';

export default {
    async SEARCH_FROM_API({ commit }, payload) {
        await Search(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEMS', data);
                }
            })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    async SELECT_ITEM({ commit }, payload) {
        commit('SET_SELECTED_ITEM', payload);
    },
}