export default {
    SET_SNACKBAR(state, payload) {
        state.snackbar = payload;
    },
    SET_TITLE(state, payload) {
        state.title = payload;
    },
    SET_LANGUAGES(state, payload) {
        state.languages = payload;
    },
    SET_SELECTED_LANGUAGE(state, payload) {
        localStorage.setItem('language', JSON.stringify(payload));
        state.selectedLanguage = payload;
    },
}