import { GetAll, Add, Update, GetById, Delete } from '../../services/newsService';

export default {
    async GET_ALL_FROM_API({ commit }, payload) {
        await GetAll(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEMS', data);
                }
            })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    async ADD_ITEM_FROM_API({ commit }, payload) {
        await Add(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async UPDATE_ITEM_FROM_API({ commit }, payload) {
        await Update(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async GET_BY_ID_FROM_API({ commit }, payload) {
        await GetById(payload)
            .then(({ data, status }) => {
                if (status === 200) {
                    commit('SET_ITEM', data);
                }
            })
            .catch(() => {
                commit('SET_ITEM', null);
            })
    },
    async DELETE_ITEM_FROM_API({ commit }, payload) {
        await Delete(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
}