var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"11","md":"10","lg":"8","xl":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6 text-md-h4 text-lg-h4 text-xl-h4 text-uppercase font-weight-medium"},[_vm._v(_vm._s(_vm.selectedLanguage.id == 1 ? "Новости" : "News"))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","lg":"5","xl":"5"}},[(_vm.items.data[0] != undefined)?_c('NewsItemTemplate',{attrs:{"logo":_vm.items.data[0].imageLink,"title":_vm.selectedLanguage.id == 1
              ? _vm.items.data[0].titleRu
              : _vm.items.data[0].title,"body":_vm.selectedLanguage.id == 1
              ? _vm.items.data[0].descriptionRu
              : _vm.items.data[0].description,"date":_vm.items.data[0].createdDate,"isHead":true,"editShow":_vm.token != null,"id":_vm.items.data[0].id}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"7","lg":"7","xl":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.items.data[1] != undefined)?_c('NewsItemTemplate',{attrs:{"logo":_vm.items.data[1].imageLink,"title":_vm.selectedLanguage.id == 1
                  ? _vm.items.data[1].titleRu
                  : _vm.items.data[1].title,"body":_vm.selectedLanguage.id == 1
                  ? _vm.items.data[1].descriptionRu
                  : _vm.items.data[1].description,"date":_vm.items.data[1].createdDate,"isHead":false,"editShow":_vm.token != null,"id":_vm.items.data[1].id}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.items.data[2] != undefined)?_c('NewsItemTemplate',{attrs:{"logo":_vm.items.data[2].imageLink,"title":_vm.selectedLanguage.id == 1
                  ? _vm.items.data[2].titleRu
                  : _vm.items.data[2].title,"body":_vm.selectedLanguage.id == 1
                  ? _vm.items.data[2].descriptionRu
                  : _vm.items.data[2].description,"date":_vm.items.data[2].createdDate,"isHead":false,"editShow":_vm.token != null,"id":_vm.items.data[2].id}}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }