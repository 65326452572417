<template>
  <div>
    <Item />
  </div>
</template>
<script>
import Item from "../../components/RealEstate/RealEstateItemComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "RealEstateItemView",
  components: {
    Item,
  },
  data: () => ({
    realEstateId: 0,
  }),
  methods: {
    ...mapActions({
      getByIdFromApi: "realEstate/GET_BY_ID_FROM_API",
    }),
  },
  mounted() {
    document.title = "Real Estate - Valstand";
  },
  async created() {
    this.realEstateId = parseInt(this.$route.params.id, 10);
    await this.getByIdFromApi({
      id: this.realEstateId,
    });
  },
};
</script>