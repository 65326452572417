<template>
  <div>
    <Update />
  </div>
</template>
<script>
import Update from "../../components/RealEstate/RealEstateItemUpdateComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "RealEstateItemUpdateView",
  components: {
    Update,
  },
  data: () => ({
    realEstateId: 0,
  }),
  methods: {
    ...mapActions({
      getByIdFromApi: "realEstate/GET_BY_ID_FROM_API",
    }),
  },
  mounted() {
    document.title = "Real Estate - Valstand";
  },
  async created() {
    this.realEstateId = parseInt(this.$route.params.id, 10);
    await this.getByIdFromApi({
      id: this.realEstateId,
    });
  },
};
</script>