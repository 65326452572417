<template>
  <div>
    <Header />
    <Info />
  </div>
</template>
<script>
import Header from "../../components/News/NewsHeaderComponent.vue";
import Info from "../../components/Account/AccountInfoComponent.vue";
export default {
  name: "AccountInfoView",
  components: {
    Header,
    Info,
  },
  computed: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
    document.title = "Account - Valstand";
  },
};
</script>