<template>
  <div class="prevent-select">
    <v-row class="d-flex justify-center align-center mt-2">
      <v-col cols="11" md="10" lg="8" xl="8">
        <v-row>
          <v-col cols="12">
            <span class="text-h4">
              Limassol: Cyprus’s Vibrant Coastal Gem!
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div>
              <v-card
                elevation="0"
                width="100%"
                height="200"
                class="blue rounded-xl d-flex align-center justify-center"
              >
                <div>
                  <span class="text-h4 white--text">Photo of Limassol</span>
                </div>
              </v-card>
            </div>
            <div class="watermark-wrapper mt-4">
              <span>
                Discover Limassol: Where History Meets Modern Luxury on Cyprus's
                Southern Shore. Immerse yourself in the vibrant heartbeat of
                Cyprus's second-largest city, where ancient whispers blend
                seamlessly with contemporary allure. Stroll through the
                cobblestone streets of Limassol's lively old town, shadowed by
                modern skyscrapers, and experience a rich tapestry of culture,
                from historic castles to bustling portside energy. Indulge in
                the city's renowned wine producFon amidst scenic vineyards, and
                bask in the golden glow of its sunny beaches and crystal-clear
                waters.
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="watermark-wrapper">
              <span>
                As a booming economic powerhouse, Limassol has emerged as a
                prime real estate investment locaFon. Its unique blend of
                historical charm and modern vibrancy, coupled with its strategic
                coastal posiFon, makes it an irresisFble locale for short-term
                rental investments.
              </span>
            </div>
            <div class="mt-4">
              <span>
                Potential annual short-term rental earnings in Limassol can soar
                to
              </span>
            </div>
            <div class="mt-4">
              <span class="text-h4 blue--text">EUR 300 thousand.</span>
            </div>
            <div class="mt-4 watermark-wrapper">
              <span
                >To tap into this lucraFve market, focus your investment
                strategy on high-demand areas, ensuring higher occupancy and
                compeFFve pricing. Embrace dynamic rental pricing to capitalize
                on market trends and seasonal demand fluctuaFons, striking the
                perfect balance between peak profitability and consistent
                occupancy.</span
              >
            </div>
            <div class="mt-4 watermark-wrapper">
              <span
                >Ready for a deep dive into real estate success? Turn the page
                for an exclusive guide to Limassol's most profitable short-term
                rental spots, complete with seasonal analysis – your journey to
                investment excellence begins here.</span
              >
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-h4">
              Top short-term rental locations (Daily Rates)
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card
              elevation="0"
              color="grey lighten-4"
              class="rounded-xl"
              height="100%"
            >
              <GmapMap
                :center="coords"
                :zoom="13"
                map-type-id="terrain"
                style="
                  width: 100%;
                  height: 50vh;
                  -webkit-transform: translate3d(0px, 0px, 0px);
                  -webkit-mask-image: -webkit-radial-gradient(white, black);
                "
              >
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-h4"> What’s next? </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div>
              <v-card
                elevation="0"
                width="100%"
                height="200"
                class="blue rounded-xl d-flex align-center justify-center"
              >
                <div>
                  <span class="text-h4 white--text"
                    >Market analytics themed picture</span
                  >
                </div>
              </v-card>
            </div>
            <div class="mt-4 watermark-wrapper">
              <span>
                Armed with insights about your target market, it's Fme to leap
                into acFon! Click on the image above to access our free,
                user-friendly real estate calculator. It's your first step in
                budgeFng effecFvely, connecFng with the perfect team of lawyers,
                real estate agents, and property managers, and crucially, seUng
                the right price for your dream investment. This is more than
                just planning; it's about turning your vision into reality.
                Start now and shape the future of your real estate journey!
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="watermark-wrapper">
              <span>
                Ready for a virtual adventure in Limassol? Click on the image
                below and step into our YouTube channel, where we exclusively
                showcase Limassol's top-performing real estate properFes. Dive
                deep into what sets these properFes apart, unraveling the
                secrets of their success and allure. Our channel provides a
                unique lens to high-performing real estate investments and
                offers inspiraFon for what you can achieve in your own real
                estate journey. Witness the excepFonal, learn from the best, and
                start craYing your path to real estate excellence!
              </span>
            </div>
            <div class="mt-4">
              <v-card
                elevation="0"
                width="100%"
                height="200"
                class="blue rounded-xl d-flex align-center justify-center"
              >
                <div>
                  <span class="text-h4 white--text"
                    >Market analytics themed picture</span
                  >
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ReportComponent",
  components: {},
  data: () => ({
    coords: {
      lat: 34.687014,
      lng: 33.036281,
    },
  }),
  methods: {},
  mounted() {
    document.title = "Report - Valstand";
  },
  async created() {
    document.addEventListener("copy", function (event) {
      event.preventDefault();

      var textToCopy = "NOT ALLOWED";

      if (event.clipboardData) {
        event.clipboardData.setData("text/plain", textToCopy);
      } else if (window.clipboardData) {
        window.clipboardData.setData("Text", textToCopy);
      }
    });
  },
};
</script>
<style scoped>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.watermark-wrapper {
  position: relative;
}

.watermark-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url("../../assets/images/logo-dark.png");
  opacity: 0.5;
  pointer-events: none;
  background-position: center center;
  background-size: contain;
}
</style>