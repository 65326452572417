import instance from './instances/instance'
import realEstateModule from './modules/realEstate'
import regionModule from './modules/region'
import authModule from './modules/auth'
import newsModule from './modules/news'
import calculatorModule from './modules/calculator'
import languageModule from './modules/language'

export default {
    realEstate: realEstateModule(instance),
    region: regionModule(instance),
    auth: authModule(instance),
    news: newsModule(instance),
    calculator: calculatorModule(instance),
    language: languageModule(instance)
}