export default {
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_ITEM(state, payload) {
        state.item = payload;
    },
    SET_ACTION_STATUS(state, payload) {
        state.actionStatus = payload;
    },
}