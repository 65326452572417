import index from '../api/index'

export const GetToken = (query) => {
    return new Promise((resolve) => {
        resolve(index.auth.GetToken(query))
    })
}

export const Check = () => {
    return new Promise((resolve) => {
        resolve(index.auth.Check())
    })
}